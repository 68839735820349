<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <!-- <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3> -->
      <div class="card-toolbar d-flex justify-content-between justify-content-sm-end w-100">
        <div class="d-flex align-items-center mr-sm-12 my-2">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="show_history" @click="show_history = !show_history" />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.PREVIOUS_EVENTS')}}</span>
        </div>

        <a href="#" class="btn btn-primary font-weight-bolder font-size" @click="createEventClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.EVENTS.NEW_EVENT')}}</a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3 table-reponsive">


      <b-table
        id="event-table"
        :fields="headers"
        :items="items"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >
        <template #cell(event_id)="row">
          <div class='justify-content-end d-flex'>

             <a
              :href="event_url(row.item.event_id)"
              target="_blank"
              class="btn btn-icon btn-light btn-sm mx-3"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Directions.svg"></inline-svg>
              </span>
            </a>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="selectEventClicked(row.item.event_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="deleteEventClicked(row.item.event_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="copy_event_clicked(row.item.event_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <i class="far fa-copy" style="color:#3699FF"></i>
              </span>
            </a>
            
          </div>
        </template>
      </b-table>

    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

.b-table-sticky-header{
  overflow-y: hidden;
  max-height: unset;
}

</style>
<script>
import axios from 'axios';
import dayjs from 'dayjs';
import EventsTableRow from '@/view/pages/ml/events/EventsTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'events-table',
  mixins: [ toasts ],
  props: ['items', 'currentCompanyId'],
  emits: ['showHistoryToggled', 'createEventClicked', 'selectEventClicked', 'deleteEventClicked', 'publishEventClicked'],
  components: {
    EventsTableRow
  },
  watch: {
    show_history(newcheck, oldcheck) {
      this.$emit('showHistoryToggled', newcheck);
    }
  },
  methods: {
    createEventClicked() {
      this.$emit('createEventClicked');
    },
    selectEventClicked(event_id) {
      this.$emit('selectEventClicked', event_id);
    },
    deleteEventClicked(event_id) {
      this.$emit('deleteEventClicked', event_id);
    },
    copy_event_clicked(event_id) {
      this.$emit('copy_event_clicked', event_id);
    },
    publishEventClicked(event_id){
      this.$emit('publishEventClicked', event_id)
    },
    gotoEvent(event_id){
      this.$router.push(`/event/${event_id}`)
    },
    event_url(event_id) {
      return '/event/' + event_id;
    }
  },
  data() {
    return {
      textFilter: null,
      show_history: false,
      headers: [
        {
          key: 'name',
          label: this.$t('PAGES.EVENTS.EVENTS'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'members_only',
          label: this.$t('PAGES.EVENTS.STATUS'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return item.to_datetime && dayjs(item.to_datetime).isValid() && dayjs(item.to_datetime).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
            ? this.$t('PAGES.EVENTS.IS_CLOSED') : this.$t('PAGES.EVENTS.IS_OPEN');
          },
        },
        {
          key: 'from_datetime',
          label: this.$t('PAGES.EVENTS.START_TIME'),
          sortable: true,
          thClass: 'w-140px align-middle',
          tdClass: 'w-140px',
        },
        {
          key: 'signups',
          label: this.$t('PAGES.EVENTS.NUM_SIGNUPS'),
          formatter: (_, __, item) => {
            return item.num_tickets ? item.num_tickets : 0;
          },
          sortByFormatted: true,
          sortable: true,
          thClass: 'w-100px align-middle',
          tdClass: 'w-100px',
        },
        {
          key: 'num_seats',
          label: this.$t('PAGES.EVENTS.NUM_SEATS'),
          sortable: true,
          thClass: 'w-150px align-middle',
          tdClass: 'w-150px',
        },
        {
          key: 'event_id',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-22 w-110px align-middle',
        },
      ],
      list: []
    };
  }
};
</script>
