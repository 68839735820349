<template>
  <div>
    
    <b-card v-if="!prospect">
      <div class="mx-auto d-flex justify-content-center mb-3">
        <b-spinner small type="grow"></b-spinner>
      </div>
    </b-card>

    <div :class="['ml-card', status_class]" header-border-variant="white" no-body>
      <div class="prospect-card-content">
        
        <!-- add a round heart button/favorit -->

        <h4 @click="clicked_prospect">{{ prospect.name.toUpperCase() }}</h4>

        <div @click="clicked_prospect" class="flex-container" style="overflow: hidden;">
          <div class="flex-col ml-1">
            <span style="color: #444444;">{{ prospect.org_number || '-' }}</span>
          </div>
          <div class="flex-col ml-2">
            <span style="white-space: pre;">{{ prospect.website1 || '-' }}</span>
          </div>
          
        </div>
        
        <!-- Description -->
        <div @click="clicked_prospect" class="description-container">
          <p class="description">{{ prospect.description }}</p>

          <b-spinner v-if="prospect.openai_status === 'STARTED'" style="margin-top: 64px;" medium type="grow"></b-spinner>
          <div v-if="prospect.openai_status === 'FAILED'" style="margin: auto; margin-top: 64px; color: #ff4444;"><i class="mdi mdi-emoticon-sad-outline" style="font-size: 64px; color: rgb(255, 68, 68);" aria-hidden="true"></i> </div>

        </div>
        
        <div class="stats-row">
          
          <!-- when mousing over the contact i want to see a list of contact persons, name with phone number -->
          <a
            href="#"
            :style="style_websites"
            class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="find_websites"
            ><i class="mdi mdi-web" aria-hidden="true"></i> {{ prospect.websites ? prospect.websites.length : 0  }} {{$t('SALES.SEARCH_WEBSITES')}}</a
          >

          <a
            href="#"
            :style="style_contacts"
            class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="find_contacts"
            ><i class="mdi mdi-account" aria-hidden="true"></i> {{ prospect.contacts ? prospect.contacts.length : 0 }} {{$t('SALES.SEARCH_OPENAI')}}</a
          >

<!--          <div class="stat-item" v-b-tooltip.hover title="Websites">
            <i class="mdi mdi-web" aria-hidden="true"></i>
            <span v-if="prospect.websites">{{ prospect.websites.length }}</span>
          </div>

          <div class="stat-item" v-b-tooltip.hover title="Contacts">
            <i class="mdi mdi-account" aria-hidden="true"></i>
            <span v-if="prospect.contacts">{{ prospect.contacts.length }}</span>
          </div>-->
          
        </div>

      </div>
    </div>
  </div>
    
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import {
  SET_USER_PREFS
} from '@/core/services/store/common.module';
import store from '@/core/services/store';


export default {
  name: 'SalesProspectSelectBox',
  components: {

  },
  props: ['prospect'],
  data() {
    return {
      refresh: 0,
      interval_id: null,
      showSearchPopup: false,
      showContactPopup: false,
      searchResults: [
        // Example data, replace with dynamic results
        { url: 'https://example.com', description: 'Example website' },
        { url: 'https://another.com', description: 'Another website' },
      ],
      contactResults: [
        // Example data, replace with dynamic results
        { title: 'Manager', name: 'John Doe', phone: '123-456-7890', email: 'john.doe@example.com' },
        { title: 'Assistant', name: 'Jane Smith', phone: '987-654-3210', email: 'jane.smith@example.com' },
      ],
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId','isAuthenticated','initComplete','user_prefs']),
    
    style_contacts() {
      this.refresh;

      switch (this.prospect.openai_status) {
        case 'NONE':
        case 'STARTED':
          return {
            'border-color': 'gray',
            'color': 'gray'
          }
        case 'FAILED':
          return {
            'border-color': 'rgb(255, 68, 68)',
            'color': 'rgb(255, 68, 68)'
          }
        case 'ENDED': {
          return {}
        }
      }

      return {
        'border-color': 'white'
      }
    },

    style_websites() {
      this.refresh;

      switch (this.prospect.search_status) {
        case 'NONE':
        case 'STARTED':
          return {
            'border-color': 'gray',
            'color': 'gray',
          }
        case 'FAILED':
          return {
            'border-color': 'red',
            'color': 'red'
          }
        case 'ENDED': {
          return {}
        }
      }

      return {
        'border-color': 'white'
      }
    },

    status_class() {
      return '';
    },
    
  },
  mounted() {

    if (this.interval_id) {
      return;
    }

    this.interval_id = setInterval(() => {
      this.refresh++;
    }, 1000);

  },
  watch: {
    currentCompanyId() {
    
    },
    currentPeriodId(newValue, oldValue) {
    
    },
    prospect: {
      handler(newVal) {

        if (this.prospect.id) {
          if (!this.prospect.websites || this.prospect.websites.length === 0) {
            this.find_websites();
          }
        }
        
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

    show_websites() {},

    show_contacts() {},

    async find_websites() {

      if (this.prospect.search_status !== 'NONE') {
        return;
      }

      const res = await axios.post(`/sales/${this.prospect.id}/search`, {});

      if (res.status === 201) {
        
        const res2 = await axios.get(`/sales/${this.prospect.id}`);

        this.$emit('updated', res2.data);
      }
    },

    async find_contacts() {
      if (this.prospect.openai_status !== 'NONE') {
        return;
      }

      this.$emit('updated', { ...this.prospect, openai_status: 'STARTED' });

      const res = await axios.post(`/sales/${this.prospect.id}/openai`, {});

      if (res.status === 201) {
        const res2 = await axios.get(`/sales/${this.prospect.id}`);

        this.$emit('updated', res2.data);
      }
    },

    clicked_prospect() {
      this.$emit('clicked', this.prospect);
    },
    calculate_seconds_since() {
      
      if (!this.prospect || !dayjs(this.prospect.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.prospect.last_polled_at);
      return now.diff(created, 'seconds');
    },
    calculate_minutes_since() {
      if (!this.prospect || !dayjs(this.prospect.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.prospect.last_polled_at);
      return now.diff(created, 'minutes');
    },
  }
};
</script>

<style lang="scss" scoped>

.ml-card {
  color: rgb(34, 34, 34);
  border: none;
  border-radius: 16px;
  padding: 8px;
  background-color: #f6f6f6;
}

.ml-card:hover {
  border: 1px solid #dedede;
}

h4 {
  text-align: left;
  margin-top: 8px;
  margin-left: 3px;
  margin-bottom: 8px;
}

.description-container {
  margin-top: 8px;
  height: 236px;
}

.colored-info {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(32, 174, 243);
  line-height: 1.25;
}

span {
  text-align: left;
}

.description {
  max-height: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  margin-left: 4px;
}

.flex-container {
  display: flex;
  align-items: flex-start;
}

.flex-child {
  flex: 1;
  max-width: 40px;
}

.flex-col {
  flex: 1;
  text-align: left;
}

.ml-card h2 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Inter, sans-serif;
  line-height: 1.235;
}

.ml-card.dark {
  background-color: rgb(23, 24, 33);
  color: white;
}

.ml-card.light {
  background-color: rgb(237, 238, 245);
  color: black;
}

.ml-card {
  height: 360px;
}

.ml-card.dark:hover {
  background-color: #333;
}

.ml-card:hover {
  background-color: #dddddd;
}

.prospect-card-content h4 {

  font-size: 1.25rem;
  margin-bottom: 2px;

  /* Add these styles to apply ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-row p {
  margin: 0;               /* Remove default margins from <p> */
}


.prospect-card-content:hover {
  cursor: pointer;
}

.prospect-card-content {
  
  padding: 4px;
  text-align: center;
  height: 90px;
}

.stats-row {
  display: flex;
  justify-content: space-around;
  margin-top: 2px;
}

.stat-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stat-item i {
  font-size: 24px;
  margin-right: 5px;
  color: #4A628A;
}


.info-box {
  position: relative;
  margin-bottom: 20px;
}

.status-box {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
