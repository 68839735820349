<template>
  <div class="d-flex align-items-center mb-4">
    
    <memlist-checkbox 
      :text="local_requirement.name"
      v-model="local_requirement.checked"
    />
      
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'requirement-row',
  props: ['requirement'],
  emits: ['change'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      local_requirement: {},
      name: ''
    };
  },
  mounted() {
    if (this.requirement) {
      this.local_requirement = { ...this.requirement };
    }
  },
  watch: {
    'local_requirement.checked'() {
      this.$emit('change', this.local_requirement);
    },
    requirement: {
      handler: function(val) {
        this.local_requirement = { ...val };
      },
      deep: true
    }
  },
  methods: {
    
  }
};
</script>
