require('dotenv').config({path: __dirname + '/.env'})

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGoogleMap from 'vuejs-google-maps'
import VueTables2 from 'vue-tables-2';

Vue.use(VueAxios, axios);

if (process.env.NODE_ENV === 'production') {

  Vue.axios.defaults.baseURL = '/api/v3';
}
else if (process.env.NODE_ENV === 'development') {
  //Vue.axios.defaults.baseURL = 'https://test.memlist.se/api/v3';
  Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost/api/v3';
  window.domain = process.env.VUE_APP_API_DOMAIN;
}

// Vue.axios.defaults.headers.common = {'X-Requested-With': 'XMLHttpRequest'}
Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//Vue.axios.defaults.withCredentials = true;

import '@/core/services/axios.service';

Vue.config.productionTip = false;

// Global 3rd party plugins
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
//import '@/core/plugins/stripe-vue';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/vuelidate';

// import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
// import '@/core/plugins/apexcharts';
// import '@/core/plugins/treeselect';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';

import '@/core/plugins/memlist.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

// import 'swagger-ui/dist/swagger-ui.css';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

import fullscreen from 'vue-fullscreen';
Vue.use(fullscreen);

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas);
library.add(far);

Vue.component('fa-icon', FontAwesomeIcon);

import { func } from '@/core/services/func.service.js';

Vue.prototype.$func = func;

Vue.use(VueTables2.ClientTable);

Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'AIzaSyA6TAPuhK96gq9vMpeF27m6icCgqkqn1rM',
    libraries: []
  }
});


import MemlistSelectInput from '@/view/components/form/MemlistSelectInput.vue';
import MemlistSelectInput2 from '@/view/components/form/MemlistSelectInput2.vue';
import MemlistTextInput from '@/view/components/form/MemlistTextInput.vue';
import MemlistDatePicker from '@/view/components/form/MemlistDatePicker.vue';
import MemlistTimePicker from '@/view/components/form/MemlistTimePicker.vue';
import MemlistDateTimePicker from '@/view/components/form/MemlistDateTimePicker.vue';
import MemlistDropdown from '@/view/components/form/MemlistDropdown.vue';
import MemlistButton from '@/view/components/form/MemlistButton.vue';
import MemlistCheckbox from '@/view/components/form/MemlistCheckbox.vue';
import MemlistContrastButton from '@/view/components/form/MemlistContrastButton.vue';
import MemlistCircularButton from '@/view/components/form/MemlistCircularButton.vue';
import MemlistCircularSelectButton from '@/view/components/form/MemlistCircularSelectButton.vue';
import MemlistCircularInputSelectButton from '@/view/components/form/MemlistCircularInputSelectButton.vue';
import MemlistSegmentedSelectButton from '@/view/components/form/MemlistSegmentedSelectButton.vue';
import MemlistModal from '@/view/components/form/MemlistModal.vue';
import MemlistCodeBox from '@/view/components/form/MemlistCodeBox.vue';
import MemlistTextarea from '@/view/components/form/MemlistTextarea.vue';

import vuelidate from 'vuelidate';

Vue.use(vuelidate);

Vue.component('memlist-select-input', MemlistSelectInput);
Vue.component('memlist-select-input2', MemlistSelectInput2);
Vue.component('memlist-text-input', MemlistTextInput);
Vue.component('memlist-date-picker', MemlistDatePicker);
Vue.component('memlist-time-picker', MemlistTimePicker);
Vue.component('memlist-datetime-picker', MemlistDateTimePicker);
Vue.component('memlist-dropdown', MemlistDropdown);
Vue.component('memlist-button', MemlistButton);
Vue.component('memlist-checkbox', MemlistCheckbox);
Vue.component('memlist-contrast-button', MemlistContrastButton);
Vue.component('memlist-circular-button', MemlistCircularButton);
Vue.component('memlist-circular-select-button', MemlistCircularSelectButton);
Vue.component('memlist-circular-input-select-button', MemlistCircularInputSelectButton);
Vue.component('memlist-segmented-select-button', MemlistSegmentedSelectButton);
Vue.component('memlist-modal', MemlistModal);
Vue.component('memlist-code-box', MemlistCodeBox);
Vue.component('memlist-textarea', MemlistTextarea);


import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})


new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');
