import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';


export function getDeviceId() {
  const deviceId = getKeyValue('deviceId');
  if (deviceId) {
    return deviceId;
  }

  const newDeviceId = randomstr(32);

  setKeyValue('deviceId', newDeviceId);

  return newDeviceId;
}


export function randomstr(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}