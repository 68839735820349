<template>
  <div class="checkbox-property-with-text">
    <div class="d-flex align-items-center mb-4">
      <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
        <input
          type="checkbox"
          :name="group.group_id"
          v-model="selected"
          :value="prop.prop_id"
        />
        <span></span>
      </label>
      <span class="ml-3 cursor-pointer">{{ prop.name }}</span>
    </div>

    <div v-if="selected && prop.is_free_text">
      <b-form-input
        v-model="free_text"
        @change="free_text_changed"
        :state="validate_text"
        >
      </b-form-input>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'PropertyPageGroupCheckboxPropertyWithText',
  mixins: [ toasts ],
  props: ['group', 'prop'],
  emits: ['change'],
  data() {
    return {
      selected: null,
      free_text: null
    };
  },
  mounted() {

  },

  watch: {
    selected(newValue) {
      console.log('emitting change')
      this.emit_change();
    }
  },

  methods: {
    validate_text() {
      if (!this.free_text) {
        return null;
      }

      if (this.free_text.length > 31) {
        return false;
      }

      return null;
    },
    free_text_changed() {
      this.emit_change();
    },

    emit_change() {

      this.$emit('change', this.prop.prop_id, this.selected, this.free_text ? this.free_text.substring(0, 31) : null);

    }
  }
};
</script>
