<template>
  <div class="code-box ml-code-box">
    <button @click.prevent="copyCode" class="copy-button" :title="copyLabel">
      <span :class="buttonIcon"></span>
    </button>
    <pre><code>{{ code }}</code></pre>
  </div>
</template>

<script>
export default {
  name: 'CodeBox',
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      originalLabel: 'Copy Code',
      copiedLabel: 'Copied!',
      isCopied: false
    }
  },
  computed: {
    copyLabel() {
      return this.isCopied ? this.copiedLabel : this.originalLabel;
    },
    buttonIcon() {
      return this.isCopied ? 'mdi mdi-check' : 'mdi mdi-content-copy';
    }
  },
  methods: {
    async copyCode() {
      try {
        await navigator.clipboard.writeText(this.code);
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 2000);
      } catch (e) {
        console.error('Failed to copy:', e);
      }
    }
  }
}
</script>

<style scoped>
.code-box {
  position: relative;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: monospace;
  padding: 1em;
  padding-top: 0.8em; 
}

.code-box pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}

.copy-button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: #007bff;
  border: none;
  padding: 0.5em 0.75em;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.copy-button:hover {
  background: #0056b3;
}

.copy-button .mdi {
  font-size: 1.2em;
  vertical-align: middle;
}
</style>
