<template>
  <div>
    <ShopItemsTable
      :items="shop_items"
      @create_shop_item_clicked="createShopItem"
      @select_shop_item_clicked="selectShopItem"
      @delete_shop_item_clicked="delete_shop_item_clicked"
    />
    

    <ShopItemModal
      
      ref="shopItemModal"

      :is_ticket="false"

      :item="selected_item"

      :templates="templates"
      :memberTypeOptions="memberTypeOptions"
      :shop_items="shop_items"
      
      @created="on_shop_item_created"
      @updated="on_shop_item_updated"

    />

  </div>
</template>
<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import ShopItemsTable from '@/view/pages/ml/shopitem/ShopItemsTable.vue';
import ShopItemModal from '@/view/pages/ml/shopitem/ShopItemModal.vue';

import Confirm from '@/view/components/Confirm.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'shop_items',
  components: {
    ShopItemsTable,
    Confirm,
    ShopItemModal
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
  },
  mounted() {
    const loader = this.$loading.show();

    this.checkAPISetup();
    this.loadTemplates();
    this.loadShopItems();
    this.loadMemberTypes();

    loader && loader.hide();
  },
  data() {
    return {
      selected_item: null,
      memberTypeOptions: [],
      apiKeyNotFound: false,
      templates: [],
      templateOptions: [],
      shop_items: [],
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadShopItems();
      }
    },
  },
  methods: {
    delete_shop_item_clicked(shop_item_id) {
      
      axios 
        .delete(`/shop_item/${shop_item_id}`)
        .then(res => {
          if (res.status === 406) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DELETE_ON_ORDERS'));  
          }
          else if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.DELETED'));
            this.shop_items = this.shop_items.filter(item => item.shop_item_id !== shop_item_id);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DELETE'));
        });
    },
    checkAPISetup() {
      axios
        .get(`/api_key/service?service=dintero&company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 404) {
            this.apiKeyNotFound = true;
          } else {
            this.apiKeyNotFound = false;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.APIKEYS.UNABLE_GET'));
        });
    },

    async loadTemplates() {
      if (!this.currentCompanyId) {
        return;
      }

      this.templates = [];
      this.templateOptions = [];
      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.templates = res.data;

            this.templates.forEach(t => {
              this.templateOptions.push({ text: t.name, value: t.template_id });
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista artiklar');
        });
    },
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }
      if (!this.titles) {
        return;
      }

      this.loadShopItems();
    },
    loadMemberTypes() {
      axios
        .get('/membertype')
        .then(res => {
          this.memberTypeOptions = [];

          for (const mt of res.data) {
            this.memberTypeOptions.push({ value: mt.id, text: mt.name });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta medlemstyper');
        });
    },
    onDeleteShopItem(shop_item_id) {
      this.shop_items = this.shop_items.filter(item => item.shop_item_id !== shop_item_id);
    },
    on_shop_item_updated(shop_item) {
      
      const index = this.shop_items.findIndex(item => shop_item.shop_item_id === item.shop_item_id);

      if (shop_item.next_shop_item_id) {
        const next_shop_item = this.shop_items.find(item => item.shop_item_id === shop_item.next_shop_item_id);
        shop_item.next_shop_item = next_shop_item;
      }
      
      if (index < 0) {
        console.error('unable to find shop item', index);
      }
      else {
        this.shop_items[index] = shop_item;
        this.shop_items = [ ...this.shop_items ];
      }
    },

    on_shop_item_created(shop_item) {
      console.log('shop item created', shop_item)
      this.shop_items.push(shop_item);

      this.shop_items = [ ...this.shop_items ];
    },
    createShopItem() {
      this.selected_item = {};

      this.$nextTick(()=>{
        this.$refs['shopItemModal'].show();
      });
      
    },
    selectShopItem(shop_item_id) {
      this.selected_item = this.shop_items.find(item => item.shop_item_id === shop_item_id);
      
      this.$nextTick(()=>{
        this.$refs['shopItemModal'].show();
      });
      
    },
    loadShopItems() {
      this.shop_items = [];

      axios
        .get(`/shop_item/admin/${this.currentCompanyId}?period_id=${this.currentPeriodId}`)
        .then(res => {
          if (res.status === 200) {
            this.shop_items = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista artiklar');
        });
    }
  }
};
</script>
