<template>
  <div id="page-registration-settings">

    <b-card>

      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              v-model="settings.allow_duplicates"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer"
            >{{ $t('PAGES.REGISTRATION.ALLOW_DUPLICATES') }}</span>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              v-model="settings.enable_verify_bankid"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer"
            >{{ $t('PAGES.REGISTRATION.VERIFY_BANKID') }}</span>
        </div>
      </b-form-group>

      <b-form-group
        v-if="settings.enable_verify_bankid"
        id="input-group-verify_bankid_text"
        :label="$t('PAGES.REGISTRATION.VERIFY_BANKID_TEXT')"
        label-for="input-verify_bankid_text"
      >
        <b-form-textarea
          id="input-verify_bankid_text"
          v-model="settings.verify_bankid_text"
          type="text"
          rows="5"
        ></b-form-textarea>
      </b-form-group>

      
      <b-form-group
        id="input-group-custom_font_href"
        :label="$t('PAGES.REGISTRATION.CUSTOM_FONT')"
        label-for="input-custom_font_href"
      >
        <b-form-input
          id="input-custom_font"
          v-model="settings.custom_font_href"
          type="text"
        ></b-form-input>
        <p>{{ $t('PAGES.REGISTRATION.CUSTOM_FONT_INFO') }}</p>
      </b-form-group>


      <RightSaveButton
        ref="save-button" 
        :text="$t('COMMON.SAVE')" 
        @clicked="save_data()"
      />
      
    </b-card>
  </div>

</template>

<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'RegistrationSettingsTab',
  mixins: [ toasts ],
  components: {
    RightSaveButton
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies']),
    
  },
  mounted() {
    this.load_settings();
  },
  data() {
    return {
      settings: {}
    }
  },

  watch: {
    
  },

  methods: {
    async load_settings() {
      try {
        const response = await axios.get(`/company/${this.currentCompanyId}`);
        this.settings = response.data.company_settings;
      } catch (error) {
        this.toastr('danger', this.$t('COMMON.ERROR'), error);
      }
    },

    async save_data() {

      try {
        const response = await axios.put(`/company/company_settings/${this.currentCompanyId}`, { 
          enable_verify_bankid: this.settings.enable_verify_bankid, 
          verify_bankid_text: this.settings.verify_bankid_text,
          allow_duplicates: this.settings.allow_duplicates,
          custom_font_href: this.settings.custom_font_href,
        });

        if (response.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      }

      this.$refs['save-button'].stop();
    },

  }
};
</script>
