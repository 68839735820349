const __tags = [
  { id: 'INVOICE_OCR', name: 'Faktura: OCR nummer' },
  { id: 'INVOICE_ID', name: 'Faktura: ID' },
  { id: 'INVOICE_AMOUNT', name: 'Faktura: Summa' },
  { id: 'INVOICE_AMOUNT_VAT', name: 'Faktura: Summa moms' },
  { id: 'INVOICE_AMOUNT_PAID', name: 'Faktura: Betalad summa' },
  { id: 'INVOICE_CURRENCY', name: 'Faktura: Valuta' },
  { id: 'INVOICE_DATE', name: 'Faktura: Skapat datum' },
  { id: 'INVOICE_DUE', name: 'Faktura: Förfallodatum' },
  { id: 'INVOICE_NUM_REMINDERS', name: 'Faktura: Antal påminnelser' },
  { id: 'INVOICE_TEMPLATE_HEADER', name: 'Fakturamall: Rubrik' },
  { id: 'INVOICE_TEMPLATE_BOX_TEXT', name: 'Fakturamall: Text' },
  { id: 'INVOICE_ORDER_ITEMS', name: 'Faktura: Artiklar' },

  { id: 'INVOICE_RECIPIENT_PUBLIC_ID', name: 'Fakturamottagare: Medlemsnummer/Föreningsnummer' },
  { id: 'INVOICE_RECIPIENT_NAME', name: 'Fakturamottagare: Namn' },
  { id: 'INVOICE_RECIPIENT_ADDRESS', name: 'Fakturamottagare: Adress' },
  { id: 'INVOICE_RECIPIENT_CO', name: 'Fakturamottagare: Co' },
  { id: 'INVOICE_RECIPIENT_CONTACT_NAME', name: 'Fakturamottagare: Kontaktperson' },
  { id: 'INVOICE_RECIPIENT_PHONE', name: 'Fakturamottagare: Telefon' },
  { id: 'INVOICE_RECIPIENT_COUNTRY', name: 'Fakturamottagare: Land' },
  { id: 'INVOICE_RECIPIENT_POST', name: 'Fakturamottagare: Postort' },
  { id: 'INVOICE_RECIPIENT_ZIPCODE', name: 'Fakturamottagare: Postnummer' },
  { id: 'INVOICE_RECIPIENT_EMAIL', name: 'Fakturamottagare: E-post' },
  { id: 'INVOICE_PDF_LINK', name: 'Faktura: PDF Länk' },
  
  { id: 'RENEW_LINK', name: 'Förnyelselänk' },
  { id: 'RENEW_SHORT_LINK', name: 'Kort Förnyelselänk' },
  { id: 'RENEW_CONFIRM_LINK', name: 'Förnyelse Bekräftelselänk' },
  { id: 'LATEST_RENEW_SHORT_LINK', name: 'Senaste Kort Förnyelselänk' },
  { id: 'LATEST_RENEW_CONFIRM_LINK', name: 'Senaste Förnyelse Bekräftelselänk' },
  { id: 'MEMBER_COMPANY_NAME', name: 'Medlem: Föreningsnamn' },

  { id: 'CLIENT_ID', name: 'Medlem: ID' },
  { id: 'CLIENT_PUBLIC_ID', name: 'Medlem: Medlemsnummer' },
  { id: 'CLIENT_FIRSTNAME', name: 'Medlem: Förnamn' },
  { id: 'CLIENT_LASTNAME', name: 'Medlem: Efternamn' },
  { id: 'CLIENT_EMAIL', name: 'Medlem: E-post' },
  { id: 'CLIENT_PHONE', name: 'Medlem: Telefon' },
  { id: 'CLIENT_ADDRESS', name: 'Medlem: Gatuadress' },
  { id: 'CLIENT_PERSONNR', name: 'Medlem: Personnummer' },
  { id: 'CLIENT_POSTADDRESS', name: 'Medlem: Postort' },
  { id: 'CLIENT_COUNTRY', name: 'Medlem: Land' },
  { id: 'CLIENT_ZIPCODE', name: 'Medlem: Postnummer' },
  { id: 'CLIENT_TOKEN', name: 'Medlem: Logintoken' },
  { id: 'CLIENT_PASSWORD_RESET_TOKEN', name: 'Medlem: Lösenordreset token' },
  { id: 'STICKPROV_URL', name: 'Medlem: Stickprov URL' },
  { id: 'MEMLIST_CLIENT_CONFIRM_LINK', name: 'Medlem: Bekräfta medlemskap URL' },
  { id: 'CLIENT_PAYMENT_LINK', name: 'Medlem: Betalningslänk' },

  { id: 'USER_INVITE_TOKEN', name: 'Användare inbjudningstoken' },
  { id: 'USER_ID', name: 'Användare ID' },
  { id: 'USER_EMAIL', name: 'Användare E-post' },
  { id: 'USER_FIRSTNAME', name: 'Användare förnamn' },
  { id: 'USER_LASTNAME', name: 'Användare Efternamn' },
  { id: 'USER_PHONE', name: 'Användare Telefon' },
  { id: 'USER_PERSONNR', name: 'Användare Personnummer' },
  { id: 'USER_CURRENT_COMPANY_ID', name: 'Användare FöreningsID' },
  { id: 'USER_PASSWORD_RESET_TOKEN', name: 'Användare Lösenord Reset Token' },
  { id: 'USER_AUTH_TOKEN_LINK', name: 'Användare Logintoken' },
  { id: 'USER_LOGIN_LINK', name: 'Användare Loginlänk' },
  { id: 'SYSTEM_LOGIN_LINK', name: 'System Loginlänk' },

  { id: 'TODO_WIZARD_LINK', name: 'Ärendehanteringswizard länk' },
  { id: 'MUCF_WIZARD_LINK', name: 'MUCF Wizard länk' },
  { id: 'IMPORT_WIZARD_LINK', name: 'Import Wizard länk' },
  { id: 'MEMLIST_ADMIN_CONFIRM_LINK', name: 'Bekräfta administratör länk' },

  { id: 'SYSTEM_NAME', name: 'Systemnamn' },
  { id: 'COMPANY_NAME', name: 'Föreningsnamn' },
  { id: 'COMPANY_CONTACT_EMAIL', name: 'Förening Kontakt e-post' },
  { id: 'COMPANY_CONTACT_NAME', name: 'Förening Kontaktperson' },
  { id: 'COMPANY_CONTACT_PHONE', name: 'Förening Kontakt telefon' },
  { id: 'COMPANY_ORG_NUMBER', name: 'Förening Org.nummer' },
  { id: 'COMPANY_LOGO', name: 'Förening logotyp' },
  { id: 'COMPANY_ADDRESS', name: 'Förening adress' },
  { id: 'COMPANY_ID', name: 'FöreningsID' },
  { id: 'COMPANY_ZIP', name: 'Förening Postnummer' },
  { id: 'COMPANY_WWW', name: 'Förening Hemsida' },
  { id: 'COMPANY_SWISH', name: 'Förening Swish' },
  { id: 'COMPANY_VAT', name: 'Förening Momsnummer' },
  { id: 'COMPANY_PLUSGIRO', name: 'Förening Plusgiro' },
  { id: 'COMPANY_BANKGIRO', name: 'Förening Bankgiro' },

  { id: 'TODO_NAME', name: 'Ärendenamn' },
  { id: 'TODO_DESCR', name: 'Ärendebeskrivning' },
  { id: 'TODO_ID', name: 'Ärende ID' },
  { id: 'TODO_FROM_DATETIME', name: 'Ärende från tidsstämpel' },
  { id: 'TODO_TO_DATETIME', name: 'Ärende till tidsstämpel' },

  { id: 'TODO_TASK_ID', name: 'Ärende åtgärdsID' },
  { id: 'TODO_TASK_NAME', name: 'Ärende åtgärdsnamn' },
  { id: 'TODO_TASK_DESCR', name: 'Ärende åtgärdsbeskrivning' },
  { id: 'TODO_TASK_LATEST_COMMENT_TEXT', name: 'Ärende åtgärd senaste kommentar' },
  { id: 'TODO_TASK_LATEST_COMMENT_USERNAME', name: 'Ärende åtgärd senaste kommentar användarnamn' },
  { id: 'TODO_TASK_LATEST_COMMENT_CREATED_AT', name: 'Ärende åtgärd senaste komentar datum' },

  { id: 'CREATOR_COMPANY_NAME', name: 'Ärende: Skapande förening namn' },
  { id: 'CREATOR_COMPANY_CONTACT_EMAIL', name: 'Ärende: Skapande förening kontakt e-post' },
  { id: 'CREATOR_COMPANY_CONTACT_NAME', name: 'Ärende: Skapande förening kontaktperson' },
  { id: 'CREATOR_COMPANY_CONTACT_PHONE', name: 'Ärende: Skapande förening kontakt telefon' },
  { id: 'CREATOR_COMPANY_ORG_NUMBER', name: 'Ärende: Skapande förening org.nummer' },
  { id: 'CREATOR_COMPANY_LOGO', name: 'Ärende: Skapande förening logotyp' },
  { id: 'CREATOR_COMPANY_ADDRESS', name: 'Ärende: Skapande förening gatuadress' },
  { id: 'CREATOR_COMPANY_ID', name: 'Ärende: Skapande föreningsID' },
  { id: 'CREATOR_COMPANY_ZIP', name: 'Ärende: Skapande förening postnummer' },
  { id: 'CREATOR_COMPANY_WWW', name: 'Ärende: Skapande förening hemsida' },
  { id: 'CREATOR_COMPANY_SWISH', name: 'Ärende: Skapande förening swish' },
  { id: 'CREATOR_COMPANY_VAT', name: 'Ärende: Skapande förening momsnummer' },
  { id: 'CREATOR_COMPANY_PLUSGIRO', name: 'Ärende: Skapande förening plusgiro' },
  { id: 'CREATOR_COMPANY_BANKGIRO', name: 'Ärende: Skapande förening bankgiro' },

  { id: 'CHILD_COMPANY_ID', name: 'Ärende: UnderföreningsID' },
  { id: 'CHILD_COMPANY_NAME', name: 'Ärende: Underförening namn' },
  { id: 'CHILD_COMPANY_ADDRESS', name: 'Ärende: Underförening gatuadress' },
  { id: 'CHILD_COMPANY_ZIPCODE', name: 'Ärende: Underförening postnummer' },
  { id: 'CHILD_COMPANY_ORG_NUMBER', name: 'Ärende: Underförening org.nummer' },
  { id: 'CHILD_COMPANY_CONTACT_NAME', name: 'Ärende: Underförening kontaktperson' },
  { id: 'CHILD_COMPANY_CONTACT_PHONE', name: 'Ärende: Underförening kontakt telefon' },
  { id: 'CHILD_COMPANY_CONTACT_EMAIL', name: 'Ärende: Underförening kontakt e-post' },

  { id: 'PARENT_COMPANY_ID', name: 'Moderförening: ID' },
  { id: 'PARENT_COMPANY_NAME', name: 'Moderförening: Namn' },
  { id: 'PARENT_COMPANY_ADDRESS', name: 'Moderförening: Gatuadress' },
  { id: 'PARENT_COMPANY_ZIPCODE', name: 'Moderförening: Postnummer' },
  { id: 'PARENT_COMPANY_ORG_NUMBER', name: 'Moderförening: Org.nummer' },
  { id: 'PARENT_COMPANY_CONTACT_NAME', name: 'Moderförening: Kontaktperson' },
  { id: 'PARENT_COMPANY_CONTACT_PHONE', name: 'Moderförening: Kontakt telefon' },
  { id: 'PARENT_COMPANY_CONTACT_EMAIL', name: 'Moderförening: Kontakt e-post' },
  { id: 'PARENT_COMPANY_BG', name: 'Moderförening: Bankgiro' },
  { id: 'PARENT_COMPANY_PG', name: 'Moderförening: Plusgiro' },
  { id: 'PARENT_COMPANY_SWISH', name: 'Moderförening: Swish' },
  { id: 'PARENT_COMPANY_IBAN', name: 'Moderförening: IBAN' },
  { id: 'PARENT_COMPANY_SWIFTBIC', name: 'Moderförening: SWIFT/BIC' },
  { id: 'PARENT_COMPANY_BANK', name: 'Moderförening: Bank' },
  { id: 'PARENT_COMPANY_BANK_ACCOUNT', name: 'Moderförening: Bankkonto' },

  { id: 'PARENT_COMPANY_WWW', name: 'Moderförening: Hemsida' },
  { id: 'PARENT_COMPANY_POST', name: 'Moderförening: Postort' },
  { id: 'PARENT_COMPANY_COUNTRY', name: 'Moderförening: Land' },

  { id: 'PARENT_COMPANY_INVOICE_ADDRESS', name: 'Moderförening: Faktura: adress' },
  { id: 'PARENT_COMPANY_INVOICE_PHONE', name: 'Moderförening: Faktura: telefon' },
  { id: 'PARENT_COMPANY_INVOICE_EMAIL', name: 'Moderförening: Faktura: e-post' },
  { id: 'PARENT_COMPANY_INVOICE_POST', name: 'Moderförening: Faktura: postort' },
  { id: 'PARENT_COMPANY_INVOICE_ZIPCODE', name: 'Moderförening: Faktura: postnummer' },
  { id: 'PARENT_COMPANY_INVOICE_CO', name: 'Moderförening: Faktura: C/o' },
  { id: 'PARENT_COMPANY_INVOICE_COUNTRY', name: 'Moderförening: Faktura: Land' },

  { id: 'EVENT_NAME', name: 'Evenemang: Namn' },
  { id: 'EVENT_ADDRESS', name: 'Evenemang: Gatuadress' },
  { id: 'EVENT_ZIPCODE', name: 'Evenemang: Postnummer' },
  { id: 'EVENT_POSTAL', name: 'Evenemang: Postort' },
  { id: 'EVENT_FROM', name: 'Evenemang: Från datum' },
  { id: 'EVENT_TO', name: 'Evenemang: Till datum' },
  { id: 'EVENT_TOTAL', name: 'Evenemang: Total kostnad' },
  { id: 'EVENT_VAT_TOTAL', name: 'Evenemang: Total moms' },
  { id: 'EVENT_VAT_PCT', name: 'Evenemang: Momssats (utan %)' },
  { id: 'EVENT_LAT', name: 'Evenemang: GEO Latitud' },
  { id: 'EVENT_LNG', name: 'Evenemang: GEO Longitud' },
  { id: 'EVENT_ID', name: 'Evenemang: ID' },

  { id: 'CLIENT_EVENT_ID', name: 'Evenemangsbiljett: ID' },
  { id: 'CLIENT_EVENT_QR_TICKET', name: 'Evenemangsbiljett: QR biljett' },

  { id: 'TEMPLATE_REPLY_TO', name: 'Mall: reply-to' },
  { id: 'TEMPLATE_FROM_NAME', name: 'Mall: från namn' },

  { id: 'ORDER_ID', name: 'Betalning: ID' },
  { id: 'ORDER_TOTAL', name: 'Betalning: Summa SEK inkl moms' },
  { id: 'ORDER_TOTAL_VAT', name: 'Betalning: Moms SEK' },
  { id: 'ORDER_DATE', name: 'Betalning: Orderdatum' },
  { id: 'ORDER_DINTERO_TRANSACTION_ID', name: 'Betalning: Dintero transaktionsID' },
  { id: 'ORDER_ITEMS', name: 'Betalning: Artiklar' },
  { id: 'ORDER_PAYMENT_DATE', name: 'Betalning: Betaldatum' },

  { id: 'DOMAIN', name: 'Domän' },
  { id: 'INSTANCE_LAST_POLLED_AT', name: 'Övervakning: Instans Sista svar tidsstämpel' },
  { id: 'INSTANCE_DOMAIN', name: 'Övervakning: Instans Domän' },
  { id: 'INSTANCE_VERSION', name: 'Övervakning: Instans Version' },
  { id: 'INSTANCE_NAME', name: 'Övervakning: Instans namn' },
  { id: 'INSTANCE_ID', name: 'Övervakning: Instans ID' },

  { id: 'INBOUND_EMAIL_SUBJECT', name: 'Inkommande email: Ämne' },
  { id: 'INBOUND_EMAIL_SENDER_EMAIL', name: 'Inkommande email: Avsändare' },
  { id: 'INBOUND_EMAIL_ORIGINAL_EMAIL', name: 'Inkommande email: Urprungsavsändare epost' },
  { id: 'INBOUND_EMAIL_ORIGINAL_NAME', name: 'Inkommande email: Urprungsavsändare namn' },
  { id: 'INBOUND_EMAIL_CC', name: 'Inkommande email: CC' },
  { id: 'INBOUND_EMAIL_FROM_NAME', name: 'Inkommande email: Namn avsändare' },
  { id: 'INBOUND_EMAIL_ORIGINAL_RECIPIENT', name: 'Inkommande email: Ursprunglig mottagare' },
  { id: 'INBOUND_EMAIL_TO', name: 'Inkommande email: Till email' },

  { id: 'SUPPORT_ID', name: 'Support: SpårningsID' },

  { id: 'KVITTO_RULE_NAME', name: 'Kvittoregel: Namn' },
  { id: 'KVITTO_RULE_FORWARD_EMAIL_TO', name: 'Kvittoregel: Vidarebefordrad till' },
  { id: 'KVITTO_RULE_FROM_EMAIL', name: 'Kvittoregel: Avsändare' },
  { id: 'KVITTO_RULE_FROM_ORIGINAL_EMAIL', name: 'Kvittoregel: Ursprungsavsändare' },
  { id: 'KVITTO_RULE_SUBJECT_LIKE', name: 'Kvittoregel: Ämne innehåller värde' },


  { id: 'KVITTO_TITLE', name: 'Kvitto: Titel' },
  { id: 'KVITTO_CREATED_AT', name: 'Kvitto: Skapad datum' },
  { id: 'KVITTO_AMOUNT', name: 'Kvitto: Summa' },
  { id: 'KVITTO_AMOUNT_VAT', name: 'Kvitto: Summa moms' },
  { id: 'KVITTO_CURRENCY', name: 'Kvitto: Valuta' },
  { id: 'KVITTO_PAID_AT', name: 'Kvitto: Betalad datum' },
  { id: 'KVITTO_ORIGINAL_EMAIL', name: 'Kvitto: Ursprung e-post' },
  { id: 'KVITTO_ACCOUNT_TRANSACTION_ID', name: 'Kvitto: Konto transaktions ID' },
  { id: 'KVITTO_ATTACHED_FILE_URL', name: 'Kvitto: Bifogad fil URL' },
  { id: 'KVITTO_GENERATED_FILE_URL', name: 'Kvitto: Genererad file URL' },
  

  { id: 'CUSTOMER_ORG_NUMBER', name: 'Kund: Organisationsnummer' },
  { id: 'CUSTOMER_ADMIN_PHONE', name: 'Kund: Admin telefon' },
  { id: 'CUSTOMER_NAME', name: 'Kund: Namn' },
  { id: 'CUSTOMER_ADMIN_EMAIL', name: 'Kund: Admin epost' },
];

export default __tags;
