<template>
  <div>
    <Confirm
      title="Starta körning"
      message="Är du säker på att du vill starta körningen? Denna åtgärd går inte att ångra."
      ref="confirmJob"
      :type="'success'"
      @confirm="onJobConfirmed"
    />

    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      head-variant="light"
      hover
      :fields="job_headers"
      :items="jobs"
      :per-page="per_page"
      :current-page="current_page"
    >
      <template #cell(status)="{ item }">
        {{ getJobStatusStr(item.status) }}
      </template>
      <template #cell(result)="{ item }">
        {{ getJobResultStr(item.result) }}
      </template>

      <template #cell(actions)="{ item }">
        <div class="justify-content-end d-flex">

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_job_clicked(item.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary" >
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
          
          <a
            href="#"
            class="btn btn-icon btn-light btn-sm"
            @click.prevent="delete_job_clicked(item.id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>

        </div>
      </template>

    </b-table>

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'JobTable',
  components: {
    Confirm
  },
  mixins: [ toasts ],
  props: ['type'],
  emits: ['jobSelected', 'onActiveJobRunning'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies']),
  },
  mounted() {
    this.loadJobs(this.type);
  },
  data() {
    return {
      showEditor: false,
      period: null,
      company: null,
      showConfirm: true,
      form: {
        name: 'Ny körning',
        company_ids: [],
        period_ids: [],
        period_selection: 'SELECTION',
        company_selection: 'SELECTION',
        num_lookups_calc: 0,
        cost_sek: 0
      },
      jobs: [],
      job_headers: [
        {
          label: 'Skapat',
          align: 'start',
          sortable: true,
          key: 'created_at',
          tdClass: 'td-overflow',
          thClass: 'td-overflow',
        },
        {
          label: 'Avslutat',
          align: 'start',
          sortable: true,
          key: 'finished_at',
          tdClass: 'td-overflow',
          thClass: 'td-overflow',
        },
        {
          label: 'Status',
          align: 'start',
          sortable: true,
          key: 'status',
          thClass: 'td-overflow',
          tdClass: 'td-overflow',
        },
        {
          label: 'Resultat',
          align: 'start',
          sortable: true,
          key: 'result',
          thClass: 'td-overflow',
          tdClass: 'td-overflow',
        },
        {
          label: '# Antal',
          align: 'start',
          key: 'num_records'
        },
        {
          label: '# OK',
          align: 'start',
          key: 'num_success'
        },
        {
          label: '# Fel',
          align: 'start',
          key: 'num_fail'
        },
        {
          label: '# Arbetade',
          align: 'start',
          key: 'num_processed'
        },
        {
          label: '',
          key: 'actions'
        }
      ],
      headerProps: {
        sortByText: 'Sortera'
      },
      activeExpanded: [],
      closedExpanded: [],
      singleExpand: false,
      rowsPerPage: [100, 200, 500, -1],
      current_page: 1,
      per_page: 100,
      total_rows: 0,
      search: null,
      isDisabledStart: false,
    };
  },
  watch: {
    'form.period_selection'(newValue) {
      this.isDisabledStart = true;
    },
    'form.company_selection'(newValue) {
      this.isDisabledStart = true;
    },
    companies(newValue) {
      this.company = newValue.find(item => item.company_id === this.currentCompanyId);
    },
    periods(newValue) {
      this.period = newValue.find(item => item.id === this.currentPeriodId);
    },
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
  },
  methods: {

    download_ladok_error_file() {


      axios
        .get(`/file/${this.form.options.error_file_id}`)
        .then(res => {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        })
        .catch(err => {
          console.error(err);
        });
    },

    downloadLadokFile() {
      axios
        .get(`/file/${this.form.options.file_id}`)
        .then(res => {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        })
        .catch(err => {
          console.error(err);
        });
    },

    getJobResultStr(result) {
      switch (result) {
        case 'PENDING': return 'Väntar';
        case 'SUCCESS': return 'Lyckat';
        case 'FAILED': return 'Misslyckat';
        case 'SKIPPED': return 'Hoppa över';
        case 'ERROR': return this.$t('COMMON.ERROR');
        case 'CANCELLED': return 'Avbruten';
        case 'CANCEL_REQUESTED': return 'Stoppar...';
      }

      return 'Okänt';
    },
    getJobStatusStr(status) {
      switch (status) {
        case 'DRAFT': return 'Utkast';
        case 'START': return 'Väntar på start';
        case 'RUNNING': return 'Igång';
        case 'FINISHED': return 'Klar';
        case 'DELETED': return 'Raderat';
        case 'CANCELLED': return 'Avbruten';
        case 'CANCEL_REQUESTED': return 'Stoppar...';
      }

      return 'Okänt';
    },

    updateJob(job) {
      const index = this.jobs.findIndex(item => item.id === job.id);

      if (index >= 0) {
        this.jobs[index] = job;
        // Vue3 compatability for Vue.set
        this.jobs = [...this.jobs];
      }
    },

    newJobClicked() {},

    saveJob() {},

    startJobClicked() {
      this.$refs['confirmJob'].show();
    },
    onSubmit(){},

    refresh() {
      this.loadJobs(this.type);
    },

    startJob(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`/job/start/${id}`, { })
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });
    },

    postJob(type, data) {
      return new Promise(async (resolve, reject) => {
        if (type === 'ALL') {
          reject('invalid type');
          return;
        }

        axios
          .post(`/job`, { type, data })
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });

    },

    pollJob(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`/job/poll/${id}`)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });

      });
    },

    onJobConfirmed() {
      this.startJob(this.form.id)
      .then(job => {
        const index = this.jobs.findIndex(item => item.id === job.id);

        this.jobs[index] = job;
        // Vue3 compatability for Vue.set
        this.jobs = [...this.jobs];
      })
      .catch(err => {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte starta jobb');
      });
    },

    async delete_job_clicked(id) {

      const loader = this.$loading.show();

      const job = this.jobs.find(item => item.id === id);

      const res = await axios.delete(`/job/${id}`);
      if (res.status === 204) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('JOB.DELETED'));
        this.jobs = this.jobs.filter(item => item.id !== id);
      }
      
      loader && loader.hide();
    },

    select_job_clicked(id) {
      this.form = this.jobs.find(item => item.id === id);
      this.showEditor = true;

      this.$emit('jobSelected', this.form);
    },


    getJobs(type) {
      return new Promise(async (resolve, reject) => {

        const url = type === 'ALL' ? `/job` : `/job/type/${type}`;

        axios
          .get(url)
          .then(res => {
            if (res.status === 200) {
              const jobs = res.data;

              resolve(jobs);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });

    },

    loadJobs(type) {
      this.getJobs(type)
      .then(data => {

        this.jobs = data;

        const active = this.jobs.find(item => item.status === 'RUNNING');

        if (active) {
          this.$emit('onActiveJobRunning', active);
        }

      })
      .catch(err => {
        console.error(err);
        //this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista körningar');
      });
    },

    resetForm() {
      this.form = {
        name: 'Ny körning'
      };
    },

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_table.scss";
@import "@/assets/sass/components/forms/_common_modals.scss";


:deep td {
  vertical-align: middle !important;
}

:deep th div {
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
