<template>
  <div>
    <div class="center-container">
      <form @submit.prevent="on_search_input">
        <memlist-textarea
          class="ml-2"
          id="ai_search"
          name="ai_search"
          layout="vertical"
          v-model="local_item.ai_search"
          :autoexpand="true"
          :submit="true"
          size="lg"
          @submit="search_submit"
        />

        <div class="d-flex justify-content-end mt-4">
          <a
            href="#"
            class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="on_search_input"
            ><i class="mdi mdi-assistant" aria-hidden="true"></i> {{$t('PAGES.SEARCH.AI_SEARCH')}}</a
          >
        </div>
        
      </form>

      <div class="filters">
        <memlist-circular-select-button
          class="fill-button-width"
          :text="$t('MEMBER.RDT')"
          :value="'rdt'"
          icon="plus"
          ref="select_btn_rdt"
          @selected="filter_selected"
        />

        <memlist-circular-select-button
          class="fill-button-width"
          :text="$t('MEMBER.DEACTIVATE_AT')"
          :value="'deactivate_at'"
          icon="plus"
          ref="select_btn_deactivate_at"
          @selected="filter_selected"
        />

        <memlist-circular-select-button
          class="fill-button-width"
          :text="$t('MEMBER.MEMBERSHIPS')"
          :value="'memberships'"
          icon="plus"
          ref="select_btn_memberships"
          @selected="filter_selected"
        />

        <memlist-circular-select-button
          class="fill-button-width"
          :text="$t('SKV.SKV_STATUS')"
          :value="'skv_statuses'"
          icon="plus"
          ref="select_btn_skv_statuses"
          @selected="filter_selected"
        />
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

import { is_mobile } from '@/core/services/utils';

import AdvancedSearchMemberEditor from '@/view/pages/ml/advanced_search/AdvancedSearchMemberEditor.vue';
import AdvancedSearchUserEditor from '@/view/pages/ml/advanced_search/AdvancedSearchUserEditor.vue';
import AdvancedSearchTable from '@/view/pages/ml/advanced_search/AdvancedSearchTable.vue';
import AdvancedSearchUserTable from '@/view/pages/ml/advanced_search/AdvancedSearchUserTable.vue';


export default {
  name: 'AdvancedSearchFilters',
  components: {
    AdvancedSearchMemberEditor,
    AdvancedSearchUserEditor,
    AdvancedSearchTable,
    AdvancedSearchUserTable
  },
  props: [],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS', 'sid']),

    is_mobile() {
      return is_mobile();
    },

  },
  mixins: [ toasts ],
  watch: {
    
  },
  mounted() {

  },
  methods: {
    filter_selected() {},
    search_submit() {
      console.log('submitting search', this.local_item.ai_search);
    },
    order_by_changed(order_by) {
      this.search_options.order_by = order_by;
      this.$refs['advanced-search-member-editor'].run_search();
    },

    per_page_changed(per_page, page) {
      this.$refs['advanced-search-member-editor'].change_per_page(per_page, page);
    },

    on_search_result(result, search_options) {
      this.search_options = search_options;
      this.members = result;
    },
    on_search_count_result(count) {
      this.count = count;
    },
    on_search_result_users(result, search_options) {
      this.search_options = search_options;
      this.users = result;
    },
    on_search_count_result_users(count) {
      this.count = count;
    }
  },
  data() {
    return {
      local_item: {},
      search_options: null,
      members: [],
      users: [],
      count: 0,
      columns: []
    }
  },
}

</script>

<style lang="css" scoped>
.filters div {
 margin-right: 4px;
}
</style>
