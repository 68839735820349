<template>
  <div class="radio-properties">
    <div class="form-group">
      <div class="radio-list">
        <template v-for="(prop, j) in group.properties">
          <label class="radio radio-outline radio-success mb-4" v-bind:key="j">
            <input type="radio" v-model="selected" :value="prop.prop_id" :name="group.group_id" />
            <span></span>
            {{ prop.name }}
          </label>

          <div v-if="prop.is_free_text && selected">
            <b-form-input
              v-model="free_text"
              @change="free_text_changed">
            </b-form-input>
          </div>

        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'property-page-radio-properties',
  mixins: [ toasts ],
  props: ['group', 'dataSelected'],
  emits: ['attributes_selected'],
  components: {},
  data() {
    return {
      selected: null,
      free_text: null
    };
  },
  mounted() {
    if (this.dataSelected) this.selected = this.dataSelected[0] || [];
  },
  watch: {
    dataSelected() {
      this.selected = this.dataSelected[0] || null;
    },
    selected(newValue) {
      //this.$emit('selected', this.group, newValue);
      this.emit_change();
    }
  },
  methods: {
    free_text_changed() {
      this.emit_change();
    },

    emit_change() {

      const selected_props = [
        { prop_id: this.selected, text: this.free_text }
      ];

      console.log('emit attributes_selected', this.group, selected_props)
      this.$emit('attributes_selected', this.group, selected_props);
    }

  }
};
</script>
