<template>
  <div>
    
    <b-card v-if="!local_item">
      <div class="mx-auto d-flex justify-content-center mb-3">
        <b-spinner small type="grow"></b-spinner>
      </div>
    </b-card>

    <div :class="['ml-card', status_class]" header-border-variant="white" no-body>
      <div class="contact-card-content">
        
        <!-- add a round heart button/favorit -->

        <memlist-text-input
          id="name"
          name="name"
          :title="$t('SALES.CONTACT_NAME')"
          layout="vertical"
          v-model="local_item.name"
        />

        <memlist-text-input
          id="title"
          name="title"
          :title="$t('SALES.CONTACT_TITLE')"
          layout="vertical"
          v-model="local_item.title"
        />

        <div class="stats-row" style="margin-top: 8px;">
          
          <div class="call-and-edit">
            <memlist-text-input
              style=" margin-top: 0px;"
              @blur="save"
              :placeholder="$t('SALES.PHONE1')"
              ref="phone1-input"
              id="phone1"
              name="phone1"
              layout="vertical"
              v-model="local_item.phone1"
            />
            <a
              style="width: 44px; height: 37px;"
              href="#"
              class="btn btn-outline-primary font-weight-bolder font-size-sm small-button"
              @click.prevent="call_clicked(local_item.phone1)"
              >
                <i class="mdi mdi-phone call-button-icon" aria-hidden="true"></i>
            </a>
            
          </div>
          
        </div>
        <div class="stats-row">
          
          <div class="call-and-edit">
            <memlist-text-input
              style="margin-top: 0px;"
              @blur="save"
              :placeholder="$t('SALES.PHONE2')"
              ref="phone2-input"
              id="phone2"
              name="phone2"
              layout="vertical"
              v-model="local_item.phone2"
            />
            <a
              style="width: 44px; height: 37px;"
              href="#"
              class="btn btn-outline-primary font-weight-bolder font-size-sm small-button"
              @click.prevent="call_clicked(local_item.phone2)"
              >
                <i class="mdi mdi-phone call-button-icon" aria-hidden="true"></i>
            </a>
            
          </div>

        </div>

        <div class="stats-row">
          <div class="call-and-edit">
            <memlist-text-input
              style=" margin-top: 0px;"
              @blur="save"
              :placeholder="$t('SALES.EMAIL1')"
              ref="email1-input"
              id="email1"
              name="email1"
              layout="vertical"
              v-model="local_item.email1"
            />
            <a
              style="width: 44px; height: 37px;"
              href="#"
              class="btn btn-outline-primary font-weight-bolder font-size-sm small-button"
              @click.prevent="email_clicked(local_item.email1)"
              >
                <i class="mdi mdi-email-fast-outline call-button-icon" aria-hidden="true"></i>
            </a>
            
          </div>

        </div>

        <div class="stats-row">
          <div class="call-and-edit">
            <memlist-text-input
              style="margin-top: 0px;"
              @blur="save"
              :placeholder="$t('SALES.EMAIL2')"
              ref="email2-input"
              id="email2"
              name="email2"
              layout="vertical"
              v-model="local_item.email2"
            />
            <a
              style="width: 44px; height: 37px;"
              href="#"
              class="btn btn-outline-primary font-weight-bolder font-size-sm small-button"
              @click.prevent="email_clicked(local_item.email2)"
              >
                <i class="mdi mdi-email-fast-outline call-button-icon" aria-hidden="true"></i>
            </a>
            
          </div>

        </div>

        <div class="bottom-row">

          <a
            v-if="local_item.id"  
            href="#"
            class="btn btn-outline-danger font-weight-bolder font-size-sm"
            @click.prevent="delete_clicked"
            ><i class="menu-icon flaticon2-trash" style="font-size: 1.0em;"></i>{{$t('COMMON.DELETE')}}</a
          >

        </div>
        
      </div>
    </div>
  </div>
    
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import {
  SET_USER_PREFS
} from '@/core/services/store/common.module';
import store from '@/core/services/store';
import { validate_email } from '@/core/services/email';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'SalesContactPerson',
  components: {

  },
  mixins: [ toasts ],

  props: ['contact','prospect'],
  data() {
    return {
      local_item: {},
      editing_name: false,
      editing_phone1: false,
      editing_phone2: false,
      editing_email1: false,
      editing_email2: false,
      refresh: 0,
      interval_id: null,
      showSearchPopup: false,
      showContactPopup: false,
      searchResults: [
        // Example data, replace with dynamic results
        { url: 'https://example.com', description: 'Example website' },
        { url: 'https://another.com', description: 'Another website' },
      ],
      contactResults: [
        // Example data, replace with dynamic results
        { title: 'Manager', name: 'John Doe', phone: '123-456-7890', email: 'john.doe@example.com' },
        { title: 'Assistant', name: 'Jane Smith', phone: '987-654-3210', email: 'jane.smith@example.com' },
      ],
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId','isAuthenticated','initComplete','user_prefs']),
    
    style_email1() {
      this.refresh;

      if (!this.contact.email1) {
        return {
          'border-color': 'black',
          'color': 'black'
        }
      }

      if (!validate_email(this.contact.email1)) {
        return {
          'border-color': 'rgb(255, 68, 68)',
          'color': 'rgb(255, 68, 68)'
        }
      }

    },

    style_email2() {
      this.refresh;

      if (!this.contact.email2) {
        return {
          'border-color': 'black',
          'color': 'black'
        }
      }

      if (!validate_email(this.contact.email2)) {
        return {
          'border-color': 'rgb(255, 68, 68)',
          'color': 'rgb(255, 68, 68)'
        }
      }

    },

    style_phone1() {
      this.refresh;

      if (!this.contact.phone1) {
        return {
          'border-color': 'black',
          'color': 'black'
        }
      }

    },

    style_phone2() {
      this.refresh;

      if (!this.contact.phone2) {
        return {
          'border-color': 'black',
          'color': 'black'
        }
      }

    },

    style_contacts() {
      this.refresh;

      switch (this.contact.openai_status) {
        case 'NONE':
        case 'STARTED':
          return {
            'border-color': 'gray',
            'color': 'gray'
          }
        case 'FAILED':
          return {
            'border-color': 'rgb(255, 68, 68)',
            'color': 'rgb(255, 68, 68)'
          }
        case 'ENDED': {
          return {}
        }
      }

      return {
        'border-color': 'white'
      }
    },

    style_websites() {
      this.refresh;

      switch (this.contact.search_status) {
        case 'NONE':
        case 'STARTED':
          return {
            'border-color': 'gray',
            'color': 'gray',
          }
        case 'FAILED':
          return {
            'border-color': 'red',
            'color': 'red'
          }
        case 'ENDED': {
          return {}
        }
      }

      return {
        'border-color': 'white'
      }
    },

    status_class() {
      return '';
    },
    
  },
  mounted() {

    if (this.interval_id) {
      return;
    }

    this.interval_id = setInterval(() => {
      this.refresh++;
    }, 1000);

  },
  watch: {
    currentCompanyId() {
      
    },
    currentPeriodId(newValue, oldValue) {
    
    },
    contact: {
      handler(newVal) {

        if (this.contact) {
          this.local_item = { ...this.contact };

          if (this.contact.id) {
            
          }
        }
        
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

    async delete_clicked() {
      const res = await axios.delete(`/sales/contact/${this.local_item.id}`);

      if (res.status === 200) {
        this.$emit('deleted', this.local_item.id);
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_DELETE_CONTACT'));
      }
    },

    async save() {
      
      if (this.local_item.id) {
        await this.update_contact();
      }
      else {
        await this.create_contact();
      }
    },

    async update_contact() {
      const res = await axios.put(`/sales/contact/${this.local_item.id}`, this.local_item);

      if (res.status === 204) {
        this.$emit('updated', this.local_item);
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_CONTACT_UPDATE'));
      }
    },

    async create_contact() {
      const res = await axios.post(`/sales/${this.prospect.id}/contact`, this.local_item);

      if (res.status === 201) {
        this.local_item = { ...res.data };
        this.$emit('created', this.local_item);
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_CONTACT_CREATE'));
      }
    },

    email_clicked(email) {
      if (email) {
        // open email client and populate email
        // or use our own template sendout
      }
      else {
        // open the editor (input email)
      }
    },

    call_clicked(phone) {
      console.log('call_clicked', phone);

      if (phone) {
        // call
        console.log('emitting', this.contact, phone)
        this.$emit('call_clicked', this.contact, phone);
      }
      
    },

    clicked_contact() {
      this.$emit('clicked', this.contact);
    },
    calculate_seconds_since() {
      
      if (!this.contact || !dayjs(this.contact.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.contact.last_polled_at);
      return now.diff(created, 'seconds');
    },
    calculate_minutes_since() {
      if (!this.contact || !dayjs(this.contact.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.contact.last_polled_at);
      return now.diff(created, 'minutes');
    },
  }
};
</script>

<style lang="scss" scoped>

.font-size-sm {
  font-size: 12px;
  font-style: normal;
}

.bottom-row {
  margin-top: 4px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.call-and-edit {
  display: flex;
}

.call-and-edit a {
  width: 32px;
  height: 32px;
}

.header-text {
  text-align: left;
  margin-top: 8px;
  margin-left: 3px;
  margin-bottom: 8px;
  font-size: 16px;
}

.header-text:hover {
  background-color: #dedede;
}

.small-button {
  width: 46px;
  margin-left: 4px;
}

.wide-button {
  /* Give the button a fixed width so that text-ellipsis can work properly */
  width: 230px; 
  display: inline-flex;       /* or display: flex; if you want it to fill parent container */
  align-items: center;        /* vertically center icon & text */
  justify-content: flex-start; /* keep icon on the left */
  text-decoration: none;       /* optional if you want to remove underline for <a> */
  /* Other .btn styles from Bootstrap, etc. remain as is */
}


.call-button-icon {
  margin-right: 8px; /* Some spacing between icon and text */
}

.call-button-text {
  /* Use flex: 1 if you want it to expand and fill the remaining width */
  flex: 1;

  /* Important to get ellipsis */
  display: inline-block;  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ml-card {
  color: rgb(61, 61, 61);
  border: none;
  border-radius: 16px;
  padding: 8px;
  background-color: #f5f5f5;
}

h4 {
  text-align: left;
  margin-top: 8px;
  margin-left: 3px;
  margin-bottom: 8px;
}

.description-container {
  margin-top: 8px;
  height: 236px;
}

.colored-info {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: Inter, sans-serif;
  color: rgb(32, 174, 243);
  line-height: 1.25;
}

span {
  text-align: left;
}

.description {
  max-height: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  margin-left: 4px;
}

.flex-container {
  display: flex;
  align-items: flex-start;
}

.flex-child {
  flex: 1;
  max-width: 40px;
}

.flex-col {
  flex: 1;
  text-align: left;
}

.ml-card h2 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Inter, sans-serif;
  line-height: 1.235;
}

.ml-card.dark {
  background-color: rgb(23, 24, 33);
  color: white;
}

.ml-card.light {
  background-color: rgb(237, 238, 245);
  color: black;
}

.ml-card {
  height: 376px;
}

.contact-card-content h4 {

  font-size: 1.25rem;
  margin-bottom: 2px;

  /* Add these styles to apply ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-row p {
  margin: 0;               /* Remove default margins from <p> */
}


.contact-card-content:hover {
  cursor: pointer;
}

.contact-card-content {
  
  padding: 4px;
  text-align: center;
  height: 90px;
}

.stats-row {
  display: flex;
  margin-top: 2px;
}

.stat-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stat-item i {
  font-size: 24px;
  margin-right: 5px;
  color: #4A628A;
}


.info-box {
  position: relative;
  margin-bottom: 20px;
}

.status-box {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
