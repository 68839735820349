<template>
  <div class="container">

    <b-modal
      ref="edit-form"
      hide-footer
      >
      
      <memlist-select-input2
        id="type"
        name="type"
        :title="$t('SALES.SALES_COMPANY_TYPE')"
        layout="vertical"
        v-model="company_type_selected"
        :items="company_type_options"
        :required="true"
      />

      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="saved"
        @close="$refs['edit-form'].hide()"
        ref="saveButton"
      />

    </b-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'SalesOptionsModal',

  props: [],
  emits: [],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    company_type_selected() {
     
    }
  },

  computed: {
    company_type_options() {
      return [
        { text: this.$t('SALES.SALES_COMPANY_TYPES.IDEELL'), value: 'IDEELL' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.TROSSAMFUND'), value: 'TROSSAMFUND' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.STUDENT'), value: 'STUDENT' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.AKASSA'), value: 'AKASSA' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.EKONOMISK_FORENING'), value: 'EKONOMISK_FORENING' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.OTHER_STIFTELSER'), value: 'OTHER_STIFTELSER' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.SAMFALLIGHET'), value: 'SAMFALLIGHET' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.UNKNOWN'), value: 'UNKNOWN' },
      ]
    },
    

    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    saved() {
      localStorage.setItem('sales_company_type_selected', this.company_type_selected);

      this.$emit('updated');

      this.$refs['saveButton'].stop();
      this.hide();
    },

    show() {
      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    created(item) {
      this.$refs['edit-form'].hide();
    },

    updated(item) {
      this.$refs['edit-form'].hide();
    }
  },

  mounted() {
    this.company_type_selected = localStorage.getItem('sales_company_type_selected') || 'IDEELL';
  },

  data() {
    return {
      company_type_selected: 'IDEELL',
      creating: true,
    };
  }
};

</script>


<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_table.scss";
@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
