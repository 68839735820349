<template>
  <div style="width: 380px;">
    <b-row style="padding: 8px;">
      <b-col cols="11">

        <b-form-input
          v-model="input_search_str"
          @click="open_popup"
          autocomplete="off"
        />
      </b-col>
      <b-col cols="1">
        <div style="margin-top: 6px;">
          <v-icon slot="append">fas fa-search</v-icon>
        </div>
      </b-col>
    </b-row>

    <div class="floating-result" v-if="loading">
      <div class="d-flex justify-content-center mb-8 mt-8">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
    
    
    <div class="floating-result" v-if="!loading && show_popup">
      <p style="font-weight: bold; height: 34px; color: gray; padding-top: 8px;" class="ml-8 mt-4">{{ $t('QUICKSEARCH.RESULTS') }}</p>
      <b-row 
        v-for="row of search_result_rows" 
        class="ml-2 result-row" 
        :key="`row-${row.member_id}`"

        @click="clicked_row(row)">

        <div style="float: left; width: 64px;">
          <i class="menu-icon flaticon2-user" style="font-size: 2.0em; color: rgb(96, 96, 96); margin-left: 25px;"></i>
        </div>
        <div style="float: left;">
          <p class="widep">{{ row.name }}</p>
          <p class="mt-1">{{ set_public_id_text(row.public_id) }}</p>
        </div>
        
      </b-row>
      
      <div class="item error" v-if="search_result_rows.length === 0">
        <p style="margin-left: 26px;">{{ $t('QUICKSEARCH.NO_RESULTS') }}</p>
      </div>

    </div>
    
  </div>
</template>

<style lang="css" scoped>

.floating-result {
  position: absolute;
  width: 380px;
  background-color: white;
  border: 1px solid gray;
  padding-bottom: 16px;
  max-height: 640px;
  overflow-y: scroll;
}

.result-row .widep {
  vertical-align: middle;
  margin-top: 2px;
  margin-bottom: 2px;
  display: inline-block;
  max-width: 300px;
  height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-row {
  padding-top: 8px;
  padding-bottom: 9px;
  width: 365px;
}

.result-row:hover {
  background-color: #edf1f7;
  cursor: pointer;
}

</style>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { is_mobile } from '@/core/services/utils';


export default {
  name: 'quick-search-widget',
  components: {
    
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS', 'global_settings'])
  },
  mixins: [ toasts ],
  mounted() {

    document.addEventListener('click', this.close_popup);

    this.$nextTick(()=>{
      
    });
  },
  watch: {
    input_search_str: function (val) {

      if (val === null || val === '') {
        this.search_result_rows = [];
        return;
      }

      this.loading = true;

      this.show_popup = true;

      this.input_search_string_queue = [];
      this.input_search_string_queue.push(val);

      setTimeout(()=>{
        this.concat_search(val);
      }, 750);

    }
  },
  methods: {
    async concat_search(str) {

      const found = this.input_search_string_queue.find(item => {
        return item === str;
      });

      if (!found) {
        this.loading = false;
        return;
      }

      try {
        str = str + '';
        str = str.toUpperCase();

        const res = await axios
          .post(
            `/member/concat_search`, {
              company_id: this.currentCompanyId,
              period_id: this.currentPeriodId,
              str: str,
              all: true
            }
          );
        
        if (res.status === 200) {
          this.loading = false;

          const members = res.data;
          this.search_result_rows = members.map(item => {
            item.name = this.repair_name(item.firstname) + ' ' + this.repair_name(item.lastname);
            item.period = item.pname;
            item.company = item.cname;
            item.rdt = dayjs(item.rdt).format('YYYY-MM-DD')
            item.type = 'member';
            return item;
          });
        }

        if (this.global_settings.type === 'crm' || this.global_settings.type === 'support') {
          const res = await axios.post(`/sales/concat_search`, { str: str });

          this.search_result_rows = res.data.map(item => {
            item.name = this.repair_name(item.name) + ' ' + this.repair_name(item.org_number) + ' ' + this.repair_name(item.email);
            item.type = 'sales';
            return item;
          });
        }

        this.loading = false;

      }
      catch (err) {
        this.loading = false;
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.QUICKSEARCH.UNABLE_SEARCH'));
      }
    },

    clicked_row(item) {
      console.log('clicked', item);

      this.$router.push(`/ml-member-editor/${item.member_id}`);
    },

    repair_name(val) {
      if (!val) {
        return '';
      }

      return val;
    },

    set_public_id_text(public_id) {
      if (!public_id || public_id === '' || public_id === 0) {
        return 'Medlemsnummer saknas';
      }

      return public_id;
    },

    open_popup() {
      this.show_popup = true;
      this.dont_close = true;
    },

    close_popup(e) {
      if (this.dont_close) {
        this.dont_close = false;
        return;
      }

      this.show_popup = false;
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close_popup);
  },
  data() {
    return {
      loading: false,
      dont_close: false,
      show_popup: false,
      input_search_string_queue: [],
      input_search_str: null,
      search_result_rows: [
        
      ]
    }
  },
}

</script>