<template>
  <div class="dropdown-properties">
    <b-form-group
      id="dropdown-group"
      >
      <b-form-select value-field="prop_id" text-field="name" id="value" v-model="selected" :options="group.properties"></b-form-select>

      <div v-if="selected && is_selected_free_text">
        <b-form-input
          v-model="free_text"
          @change="free_text_changed">
        </b-form-input>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'property-page-dropdown-properties',
  mixins: [ toasts ],
  props: ['group', 'dataSelected'],
  emits: ['attributes_selected'],
  components: {},
  computed: {
    is_selected_free_text() {
      return this.group.properties.find(prop => prop.prop_id === this.selected).is_free_text;
    }
  },

  data() {
    return {
      selected: null,
      free_text: null
    };
  },
  mounted() {
    if (this.dataSelected) this.selected = this.dataSelected[0] || [];
  },
  watch: {
    dataSelected() {
      this.selected = this.dataSelected[0] || null;
    },
    selected(newValue) {
      this.emit_change();
    }
  },
  methods: {
    free_text_changed() {
      this.emit_change();
    },

    emit_change() {

      const selected_props = [
        { prop_id: this.selected, text: this.free_text }
      ];

      console.log('emit attributes_selected', this.group, selected_props)
      this.$emit('attributes_selected', this.group, selected_props);
    }
  }
};
</script>
