<template>
  <div id="confirm-page">
    <b-row class="mb-8" v-if="page.html && page.html !== ''">
      <b-col lg="12">
        <div v-html="page.html"></div>
      </b-col>
    </b-row>
    <b-row v-if="allocations.length > 0">
      <b-form-group class="reg-input col-6" id="company-group" :label="$t('PAGES.REGISTRATION.SELECTED_COMPANIES')" label-for="company">
        <b-form-input
          class="mb-4"
          v-for="c in allocations"
          :key="c.id"
          id="company"
          v-model="c.name"
          placeholder=""
          disabled
        />
      </b-form-group>
    </b-row>

    <template v-for="(term, i) in terms">
      <TermsCheckbox
        class="my-2"
        v-bind:key="i"
        :term="term"
        @selected="termAccepted"
        @showTerm="showTerm"
      />
    </template>


    <b-modal ref="show-term" size="lg" :title="selected_term.name">
      <p class="my-4" :html="selected_term.template" v-html="selected_term.template"></p>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}
</style>
<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import TermsCheckbox from './terms/TermsCheckbox.vue';
import FamilyTable from '@/view/pages/members/register/registerwizard/family/FamilyTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'confirm-page',
  props: ['company', 'page_data', 'order', 'page', 'member_id', 'family','requirements'],
  emits: ['update:page_data', 'wizardPageWasUpdated', 'isAllPolicyChecked'],
  components: {
    TermsCheckbox,
    FamilyTable
  },
  mixins: [ toasts ],
  watch: {
    page_data: {
      deep: true,
      handler(val) {
        this.local_page_data = { ...val };
      }
    },
  },
  data() {
    return {
      local_page_data: {},
      // allocations: [],
      accepted: [],
      terms: [],
      selected: [],
      visable_modal: false,
      current_policy: {},
      selected_term: {
        name: 'none',
        template: ''
      }
    };
  },
  mounted() {
    if (this.page_data) {
      this.local_page_data = { ...this.page_data };
    }
    this.loadTerms();

    this.$emit('wizardPageWasUpdated', this.page.type, this.form, this.isNextButtonOK());
  },
  computed: {
    ...mapGetters(['memberCompanies']),
    allocations() {
      if (!this.local_page_data || !this.local_page_data.data || !this.local_page_data.data.area_alloc) return [];

      return this.local_page_data.data.area_alloc.company_ids.map(a => ({
        id: a,
        name: this.memberCompanies.find(c => c.company_id === a)
          ? this.memberCompanies.find(c => c.company_id === a).name
          : ''
      }));
    }
  },
  methods: {
    getData() {
      return {
        is_accepted: false
      }
    },
    formWasUpdated() {
      this.$emit('wizardPageWasUpdated', this.page.type, this.form, this.isNextButtonOK());
    },
    isNextButtonOK() {
      let valid = true;

      const mand = this.accepted.filter(item => item.is_mandatory);
      const acc = mand.filter(item => !item.isAccepted);

      valid = acc.length === 0;

      return valid;
    },
    loadTerms() {
      axios
        .get(`/company/terms/${this.company.company_id}`)
        .then(res => {
          this.terms = res.data;

          for (const t of this.terms) {
            this.accepted.push({ id: t.id, isAccepted: false, is_mandatory: t.is_mandatory });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATION.UNABLE_GET_TERMS'));
        });
    },
    gotoPayment(e, method) {
      e.preventDefault();
    },

    onClick($event) {
      this.$emit('isAllPolicyChecked', this.selected.length == this.terms.length);
    },

    termAccepted(id, isAccepted) {

      for (let i = 0; i < this.accepted.length; ++i) {
        if (this.accepted[i].id === id) {
          this.accepted[i].isAccepted = isAccepted;
          break;
        }
      }

      let valid = true;

      for (const t of this.accepted) {
        if (t.is_mandatory) {
          if (!t.isAccepted) {
            valid = false;
            break;
          }
        }
      }
      this.local_page_data[this.page.id] = {
        ...this.local_page_data[this.page.id],
        validation: { $invalid: !valid }
      };
      this.$emit('update:page_data', this.local_page_data);

      this.formWasUpdated();
    },

    showTerm(id) {
      for (const t of this.terms) {
        if (t.id === id) {
          this.showModal(t);
          break;
        }
      }
    },

    showModal(term) {
      this.selected_term = term;

      this.$refs['show-term'].show();
    },


  }
};
</script>
