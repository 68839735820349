<template>
  <div>

    <SalesOptionsModal 
      ref="sales-options-modal"
      @updated="updated_sales_options"
    />
    
    <div class="px-4">
      <b-row>
        <b-col>
          
          <a
            href="#"
            class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="$refs['sales-options-modal'].show()"
            ><i class="menu-icon flaticon-cogwheel-1" style="font-size: 1.5em; padding-right: 0px;"></i></a
          >

          <a
            href="#"
            class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="$emit('randomize')"
            ><i class="mdi mdi-dice-3-outline" aria-hidden="true" style="font-size: 1.5em; margin: 0px; padding: 0px;"></i></a
          >

          <a
            href="#"
            class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="take_next_prospect"
            ><i class="menu-icon flaticon2-next" style="font-size: 1.0em;"></i>{{$t('SALES.TAKE_NEXT_PROSPECT')}}</a
          >

          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm mr-2"
            style="float: right;"
            @click="create_sales_clicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SALES.CREATE_PROSPECT') }}</a
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="3" sm="12">

          <b-form-group
          :label="$t('SALES.SALES_STATUS')">

            <Multiselect
              class="multiselect-blue"
              v-model="filters.statuses"
              ref="status_multiselect"
              mode="tags"
              :placeholder="$t('SALES.SALES_STATUS')"
              :close-on-select="false"
              :filter-results="false"
              :min-chars="0"
              :searchable="false"
              :options="sales_status_options_mult"
            />

          </b-form-group>
          
        </b-col>

        <b-col lg="3" sm="12">
          
          <b-form-group
            :label="$t('SALES.ASSIGN_SALESPERSON')">

            <b-form-select
              v-model="filters.user_id"
              :options="user_options"
            ></b-form-select>

          </b-form-group>
        </b-col>

        <b-col lg="3" sm="12">
          <b-form-group
          :label="$t('SALES.SALES_COMPANY_TYPE')">

            <Multiselect
              class="multiselect-blue"
              v-model="filters.company_types"
              ref="company_types_multiselect"
              mode="tags"
              :placeholder="$t('SALES.SALES_COMPANY_TYPE')"
              :close-on-select="false"
              :filter-results="false"
              :min-chars="0"
              :searchable="false"
              :options="company_type_options_mult"
            />

          </b-form-group>
          

        </b-col>
        <b-col lg="3" sm="12">
          <b-form-group
            id="input-group-created_from"
            :label="$t('PAGES.RENEWS.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>
          <b-form-group
            id="input-group-created_to"
            :label="$t('PAGES.RENEWS.CREATED_TO')"
            label-for="input-created_to"
          >
            <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
          </b-form-group>
        </b-col>
        
      </b-row>

      <b-row>
        <b-col>
          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            style="max-width:621px"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>
        </b-col>
        
      </b-row>
    </div>
    

    
    <b-row>
      <b-col cols="4" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="8">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="sales-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(status)="{ item }">
        <span class="status-badge rounded" :class="item.status.toLowerCase()">{{ $t(`SALES.SALES_STATUSES.${item.status}`) }}</span>
      </template>

      <template #cell(actions)="{ item }">
        <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="select_item_clicked(item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Write.svg" />
          </span>
        </a>

        <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="delete_item_clicked(item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Trash.svg" />
          </span>
        </a>
      </template>
    </b-table>

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { upload_file } from '@/core/services/fileUpload';
import { mapGetters } from 'vuex';
import { get_sales_filters, set_sales_filters } from '@/core/services/sales';
import { format_sek } from '@/core/services/currency';


import Confirm from '@/view/components/Confirm.vue';
import axios from 'axios';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import SalesOptionsModal from './SalesOptionsModal.vue';


export default {
  name: 'SalesTable',
  components: {
    Multiselect,
    Confirm,
    SalesOptionsModal
  },
  mixins: [ toasts ],
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'currentUser', 'isTHS']),
    
    sales_status_options_mult() {
      return this.sales_status_options.map(item => ({ label: item.text, value: item.value }));
    },
    sales_status_options() {
      return [
        { text: this.$t('SALES.SALES_STATUSES.PROSPECT'), value: 'PROSPECT' },
        { text: this.$t('SALES.SALES_STATUSES.DEAD'), value: 'DEAD' },
        { text: this.$t('SALES.SALES_STATUSES.NOT_ANSWER'), value: 'NOT_ANSWER' },
        { text: this.$t('SALES.SALES_STATUSES.DECLINED'), value: 'DECLINED' },
        { text: this.$t('SALES.SALES_STATUSES.OPPORTUNITY'), value: 'OPPORTUNITY' },
        { text: this.$t('SALES.SALES_STATUSES.CLOSING'), value: 'CLOSING' },
        { text: this.$t('SALES.SALES_STATUSES.SIGNED'), value: 'SIGNED' },
        { text: this.$t('SALES.SALES_STATUSES.LIVE'), value: 'LIVE' },
      ]
    },
    company_type_options_mult() {
      return this.company_type_options.map(item => ({ label: item.text, value: item.value }));
    },
    company_type_options() {
      return [
        { text: this.$t('SALES.SALES_COMPANY_TYPES.IDEELL'), value: 'IDEELL' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.TROSSAMFUND'), value: 'TROSSAMFUND' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.STUDENT'), value: 'STUDENT' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.AKASSA'), value: 'AKASSA' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.EKONOMISK_FORENING'), value: 'EKONOMISK_FORENING' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.OTHER_STIFTELSER'), value: 'OTHER_STIFTELSER' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.SAMFALLIGHET'), value: 'SAMFALLIGHET' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.UNKNOWN'), value: 'UNKNOWN' },
      ]
    },
    decline_reason_options() {
      return [
        { text: this.$t('SALES.DECLINE_REASONS.BAD_LEAD'), value: 'BAD_LEAD' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_MONEY'), value: 'NO_MONEY' },
        { text: this.$t('SALES.DECLINE_REASONS.TOO_EXPENSIVE'), value: 'TOO_EXPENSIVE' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_INTEREST'), value: 'NO_INTEREST' },
        { text: this.$t('SALES.DECLINE_REASONS.SATISFIED_COMPETITOR'), value: 'SATISFIED_COMPETITOR' },
        { text: this.$t('SALES.DECLINE_REASONS.LACKS_FEATURES'), value: 'LACKS_FEATURES' },
        { text: this.$t('SALES.DECLINE_REASONS.UNKNOWN'), value: 'UNKNOWN' },
      ]
    }
  },
  props: [],
  emits: ['on_select_item', 'on_delete_item'],
  mounted() {
    this.filters = get_sales_filters()

    this.load_users_for_company(this.currentCompanyId);
  },
  data() {
    return {
      filters: {},
      company_type_option: null,
      headers: [
        {
          label: this.$t('SALES.SHORT_ORG_NUMBER'),
          sortable: true,
          key: 'org_number',
          tdClass: 'td-short4 td-overflow',
          thClass: 'td-short4 td-overflow'
        },
        {
          label: this.$t('SALES.SHORT_SALES_COMPANY_TYPE'),
          sortable: true,
          key: 'type',
          tdClass: 'td-short5 td-overflow',
          thClass: 'td-short5 td-overflow',
          formatter: (_, __, item) => {
            return this.company_type_options.find(i => i.value === item.type)?.text || '';
          },
        },

        

        {
          label: this.$t('SALES.SALES_STATUS'),
          sortable: true,
          key: 'status',
          tdClass: 'td-short5',
          thClass: 'td-short5',
          formatter: (_, __, item) => {
            return this.sales_status_options.find(i => i.value === item.status)?.text || '';
          },
        },
        {
          label: this.$t('SALES.YRR'),
          sortable: true,
          key: 'offered_per_year',
          tdClass: 'td-short5',
          thClass: 'td-short5',
          formatter: (_, __, item) => {
            return format_sek((item.offered_per_year || item.accepted_per_year || '-')) + ' SEK';
          },
        },
        {
          label: this.$t('COMPANY.NAME'),
          sortable: true,
          key: 'name',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right',
        }
      ],
      total_rows: 0,
      current_page: 1,
      per_page: 100,
      search: null,
      user_options: []
    };
  },
  watch: {
    items() {
      this.total_rows = this.items.length;
    },
    filters: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        set_sales_filters(val);
      },
    },
  },
  methods: {
    updated_sales_options() {
      this.refresh();
    },
    take_next_prospect() {
      this.$emit('next_prospect');
    },
    refresh() {
      this.$refs['sales-table'].refresh();
    },
    
    async load_users_for_company(company_id) {
      this.user_options = [];
      
      const res = await axios.get(`/access/users?company_id=${company_id}`);

      if (res.status === 200) {
        this.user_options = res.data.map((item) => ({ text: item.email, value: item.user_id }));
        this.user_options.unshift({ text: this.$t('COMMON.ALL'), value: null });
      }
    },

    create_sales_clicked() {
      this.$emit('create_clicked');
    },

    select_item_clicked(id) {
      this.$emit('on_select_item', id);
    },
    delete_item_clicked(id) {
      this.$emit('on_delete_item', id);
    },


    get_filters_string() {
      let url_filters = '';

      if (this.filters.text) {
        url_filters += `&text=${encodeURIComponent(this.filters.text)}`;
      }

      if (this.filters.created_from) {
        url_filters += `&created_from=${this.filters.created_from}`;
      }

      if (this.filters.created_to) {
        url_filters += `&created_to=${this.filters.created_to}`;
      }

      if (this.filters.statuses) {
        url_filters += `&statuses=${encodeURIComponent('[' + this.filters.statuses.map(x => `"${x}"`).join(',') + ']')}`;
      }

      if (this.filters.types) {
        url_filters += `&types=${encodeURIComponent('[' + this.filters.types.map(x => `"${x}"`).join(',') + ']')}`;
      }

      if (this.filters.user_id) {
        url_filters += `&user_id=${this.filters.user_id}`;
      }
      
      return url_filters;
    },

    get_pagination(callback) {

      axios
        .get(`/sales?page=${this.current_page}&limit=${this.per_page}${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination: invalid status', res.status);
            return;
          }

          if (callback) {
            callback(res.data || []);
          }
        })
        .catch(err => {
          console.error('pagination error', err);
        });
    },

    get_count() {
      axios
        .get(`/sales/count?${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination count: invalid status', res.status);
            return;
          }

          this.total_rows = res.data.count;

        })
        .catch(err => {
          console.error('pagination count error', err);
        });
    },

    ajax_pagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_count();
      this.get_pagination(callback);

      return null;
    },

    
  }
};
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
  

.status-badge {
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 1pc;
  border: 2px solid;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}

.status-badge.opportunity {
  background-color: #333;
  color: #19f7f7;
  border-color: #19f7f7;
}
.status-badge.not_answer {
  background-color: #333;
  color: #ffcc00;
  border-color: #ffcc00;
}
.status-badge.decline {
  background-color: #333;
  color: #ff6a6a;

  border-color: #ff6a6a;
}
.status-badge.dead {
  background-color: #333;
  color: #ff6a6a;
  border-color: #ff6a6a;
}
.status-badge.live {
  background-color: #fff;
  color: #4caf50;
  border-color: #4caf50;
  
}
.status-badge.closed {
  background-color: #fff;
  color: #4caf50;
  border-color: #4caf50;
}
.status-badge.closing {
  background-color: #fff;
  color: #4caf50;
  border-color: #4caf50;
}
.status-badge.signed {
  background-color: #fff;
  color: #4caf50;
  border-color: #4caf50;
}

.status-badge.prospect {
  background-color: #fff;
  color: #333333;
  border-color: #333333;
}
</style>

