<template>
  <b-card id="register-family-page" title="Familjeregistrering">
    <div v-html="page.html"></div>
    
    <RegisterFamilyForm
      ref="familyForm"
      :me="local_page_data.data?.details"
      :company_id="company_id"
      :area_alloc="local_page_data.data?.area_alloc"
      :editData="data"
      :settings="page.settings"
      :regions="regions"
      @save="saveFamilyMember"
      @visible="edit_family_visibility_changed"
    />

    <div class="mt4">
      <div class="card-toolbar text-right">
        <a class="btn btn-primary font-weight-bolder font-size-sm mr-4" v-if="!edit_family_visible" @click="createFA">
          <i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>{{$t('PAGES.REGISTRATION.NEW_FAMILY_MEMBER')}}
        </a>
      </div>
      <b-table class="mt-8" responsive small :fields="fields" :items="items">

        <template #cell(action)="data">
          <div class="text-left" v-if="data.item.id !== 0">
            <a class="btn btn-icon btn-light btn-sm mx-2" @click="editFA(data.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <a class="btn btn-icon btn-light btn-sm mx-2" @click="deleteFA(data.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RegisterFamilyForm from '@/view/components/RegisterFamilyForm';
import validatePersonnr from '@/core/services/personnr';

export default {
  name: 'RegisterFamily',
  mixins: [ toasts ],
  components: { RegisterFamilyForm },
  props: ['company_id', 'page', 'member-type', 'regions', 'page_data', 'requirements'],
  emits: ['update:page_data', 'wizardPageWasUpdated'],
  computed: {
  },
  data() {
    return {
      local_page_data: {},
      items: [],
      idCounter: 0,
      fields: [
        { key: 'action', label: this.$t('COMMON.ACTIONS')  },
        { key: 'firstname', label: this.$t('MEMBER.FIRSTNAME')  },
        { key: 'lastname', label: this.$t('MEMBER.LASTNAME')  },
        // { key: 'head', label: 'Huvudmedlem' }
      ],
      data: null,
      head: true,
      edit_family_visible: false
    };
  },
  mounted() {
    // Skip validation for now
    if (this.page_data) {
      this.local_page_data = { ...this.page_data };
    }
    this.local_page_data[this.page.id] = {
      ...this.local_page_data[this.page.id]
    };
    this.$emit('update:page_data', this.local_page_data);
  },
  watch: {
    page_data: {
      deep: true,
      handler(val) {
        this.local_page_data = { ...val };
      }
    },
    items(newVal) {
      this.local_page_data[this.page.id] = {
        ...this.local_page_data[this.page.id],
        form: { family: newVal }
      };
      this.$emit('update:page_data', this.local_page_data);

      this.formWasUpdated();
    }
  },
  methods: {
    getData() {
      return this.items;
    },
    formWasUpdated() {
      this.$emit('wizardPageWasUpdated', this.page.type, this.items, this.isNextButtonOK());
    },
    isNextButtonOK() {
      return true;
    },
    setForm(data) {
      /**
      data: {
        fid: number,
        id: number,
        relation: string,
        member_id: number,
        rel_member_id: number | null,
        rel_person_id: number | null,
        member_id: number;
        member: {
          member_id: number;
          hd: number;
          public_id: string | null;
          in_personnr: string | null;
          email: string | null;
          firstname: string | null;
          lastname: string | null;
        };
        rel_member: {
          ...;
        } | null;
        rel_person: {
          ...;
        } | null;
      }[] */

      this.items = data;

      this.items = this.items.filter(item => item.rel_member_id !== null);

      this.items = this.items.map((item) => ( {
        id:         item.id,
        member_id:  item.rel_member.member_id,
        firstname:  item.rel_member.firstname,
        lastname:   item.rel_member.lastname,
        personnr:   item.rel_member.in_personnr,
        email:      item.rel_member.email,
        phone:      item.rel_member.phone
      }))

      this.formWasUpdated();
    },
    saveFamilyMember(data) {

      const head_member_data = this.local_page_data.data;

      if (!data.hasOwnProperty('id') || data.id === '' || data.id === null) {
        if (data.personnr && (this.items.find(item => item.personnr === data.personnr) || data.personnr == head_member_data.personnr)) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_FAMILY_ADD'));
        }
        else {
          // create
          data.id = ++this.idCounter;
          this.items.push(data);
        }
      } else {
        const idx = this.items.findIndex(item => item.id === data.id);
        if (idx > -1) {
          if (data.personnr && (this.items.find(item => item.personnr === data.personnr && item.id !== data.id) || data.personnr == head_member_data.personnr)) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_FAMILY_SAVE'));
          }
          else {
            const index = this.items.findIndex(item => item.id === data.id);
            this.items[index] = data;
            // Vue3 compatability for Vue.set
            this.items = [...this.items];
          }
        }
      }
    },
    edit_family_visibility_changed(visible) {
      this.edit_family_visible = visible;
    },
    createFA() {
      this.$refs['familyForm'].show();
    },
    editFA(data) {
      this.$refs['familyForm'].show();
      this.$refs['familyForm'].setData(data);
    },
    deleteFA(id) {
      if (id === 0) return;
      // this.setHead(this.items.findIndex(item => item.id === id), true);
      this.$refs['familyForm'].hide();
      this.items = this.items.filter(item => item.id !== id);
    },
    setHead(item, deleted) {
      if (item.head && deleted) {
        this.head = true;
        return;
      }

      if (item.id === 0 || !item.head) {
        this.head = true;
      } else {
        this.head = false;
      }

      for (const fm of this.items) {
        if (item.head && fm.id !== item.id) {
          fm.head = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.table > tbody > tr > td {
  vertical-align: middle;
}
.login-body {
  max-width: 500px;
  max-height: 300px;
  width: 100%;
  height: 100%;
}
</style>
