<template>

  <div id="sales-select-prospects">
    
    <memlist-modal ref="edit-form" size="xxl" theme="light" :visible="show_modal" @close="show_modal = false">
      
      <div class="instance-grid" v-if="prospects">

        <template v-for="(prospect, index) in prospects" >

          <SalesProspectSelectBox
            :key="index"
            :prospect="prospect"
            @contacts_found="contacts_found"
            @websites_found="websites_found"
            @updated="updated"
            @clicked="clicked_prospect"
          />
        
        </template>

        <div class="empty-box">
          <a
            href="#"
            style="width: 100%;"
            class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
            @click.prevent="randomize_again"
            ><i class="menu-icon flaticon2-next" style="font-size: 1.0em;"></i>{{$t('SALES.RANDOMIZE_AGAIN')}}</a
          >
        </div>
      </div>

    </memlist-modal>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SalesProspectSelectBox from '@/view/pages/ml/ths/sales/SalesProspectSelectBox.vue';

import dayjs from 'dayjs';

export default {
  name: 'SalesSelectProspects',
  props: ['prospects'],
  components: {
    SalesProspectSelectBox
  },
  data() {
    return {
      show_modal: false,
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods', 'isTHS'])
  },
  mounted() {
    
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
    
    },
    currentPeriodId(newValue, oldValue) {
    
    }
  },
  methods: {
    clicked_prospect(prospect) {
      this.$emit('clicked_prospect', prospect);
    },
    updated(updated_sale) {
      this.$emit('updated', updated_sale);
    },
    contacts_found(id, updated_sale) {
      this.$emit('contacts_found', id, updated_sale);
    },
    websites_found(id, websites) {
      this.$emit('websites_found', id, websites);
    },
    
    randomize_again() {
      this.$emit('randomize');
    },
    show() {
      this.show_modal = true;
    }
  }
};
</script>

<style lang="scss" scoped>

.instance-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 12px;
}


</style>
