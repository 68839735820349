<template>
  <div>
    <a
      class="btn btn-primary font-weight-bolder font-size-sm mt-4 aligns"
      @click.prevent="downloadSalesReport"
    >
      {{ $t('PAGES.REPORTS.DOWNLOAD_SALES') }}
    </a>
    <b-form-select class="aligns mt-4 ml-4"
      v-model="year" :options="yearOptions">
    </b-form-select>
  </div>
</template>



<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'DownloadSalesReport',
  components: {

  },
  mixins: [ toasts ],
  props: [],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  data() {
    return {
      yearOptions: [],
      year: null
    };
  },
  watch: {

  },
  mounted() {
    this.year = parseInt(dayjs().format('YYYY'));


    for (let i = this.year - 5; i < this.year + 1; ++i) {
      this.yearOptions.push({ text: i, value: i });
    }
  },
  methods: {
    downloadSalesReport() {

      const to_date = this.year !== parseInt(dayjs().format('YYYY')) ? this.year + '-12-31' : dayjs().format('YYYY-MM-DD');

      axios
        .post(`/company/sales/xlsx`, {
          from_date: this.year + '-01-01',
          to_date: to_date
        })
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

            this.toastr('success', this.$t('COMMON.OK'), 'Rapport skapad');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel status');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa rapport');
        });
    },

  }
};
</script>
<style scoped>
  .aligns {
    float: left;
    max-width: 200px;
  }
</style>
