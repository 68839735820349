<template>
  <div id="property-page">

    <div v-html="page.html"></div>
    <template v-for="(group, k) in groups">
      <PropertyPageGroup
        v-bind:key="k"

        :group="group"
        @attributes_selected="attributes_selected"

        v-if="group.is_registration"
      />
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import PropertyPageGroup from '@/view/pages/members/register/registerwizard/PropertyPageGroup.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'PropertyPage',
  props: ['page', 'page_data', 'company_id', 'index', 'requirements'],
  emits: ['wizardPageWasUpdated'],
  components: {
    PropertyPageGroup
  },
  mixins: [ toasts ],
  data() {
    return {
      local_page_data: {},
      form: {},
      formData: null,
      num_mandatory_groups: 0,
      num_selected_groups: 0,
      selected_membership: null,
      total_price: 0,
      total_vat: 0,
      cart: [],
      groups: [],
      validation: {},

      local_properties: []
    };
  },
  async mounted() {

    await this.loadPropertyGroups(this.company_id);

    this.$nextTick(()=>{
      this.formWasUpdated();
    });

  },
  watch: {
    company_id(newValue) {
      if (newValue) {
        this.loadPropertyGroups()
      }
    },
    page_data: {
      deep: true,
      handler(val) {

      }
    },
  },
  computed: {
    isValid() {

      const is_valid = !this.local_properties.some(g => !g.valid);

      return is_valid;
    }
  },
  methods: {

    /**
     *
    "properties": [
    {
      "group_id": "29",
      "selected_attributes": [
        {
          "prop_id": 106,
          "text": null
        }
      ]
    },
    */
    attributes_selected(group, selected_attributes, is_valid) {
      console.log('attribute was selected', group, selected_attributes);

      const idx = this.local_properties.findIndex(g => g.group_id === group.group_id);

      // Set validity of group
      this.local_properties[idx] = {
        group_id: group.group_id,
        selected_attributes,
        valid: is_valid
      }

      this.local_properties = [ ...this.local_properties ];

      this.formWasUpdated();
    },

    getData() {
      return this.local_properties;
    },
    formWasUpdated() {
      this.$emit('wizardPageWasUpdated', this.page.type,
        {
          properties: this.local_properties
        }, this.isNextButtonOK());
    },
    isFieldValid(name) { return false },
    isNextButtonOK() {
      return this.isValid;
    },
    setForm(data) {
      if (!data.properties) return;

      this.local_properties = [ ...data.properties ];

      this.formWasUpdated();
    },

    async loadPropertyGroups(company_id) {
      if (!company_id) {
        throw `company_id is not set`;
      }


      try {
        const res = await axios.get(`/property_group/extlist?company_id=${company_id}`)

        if (res.status === 200) {
          this.groups = res.data;

          this.local_properties = this.groups.filter(g => g.is_registration).map(g => {
            return {
              group_id: g.group_id,
              selected_attributes: [],
              valid: !g.is_mandatory
            }
          });

          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta attribut');
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta attribut');
      }

      throw `error loading property groups`;
    },

  }
};
</script>
