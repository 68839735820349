<template>


  <div>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="create_shop_item_clicked"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.SHOPITEM.NEW')}}</a
      >
    </div>

        <div class="d-flex align-items-center">

          <memlist-checkbox 
            :text="$t('SHOP_ITEM.SHOW_INACTIVE')"
            v-model="show_inactive"
          />
          
        </div>
        
    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[25, 50, 100]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="shopitem-table"
      class="mt-3 cursor mh-100 table-striped"
      :filter="filter"
      :items="filtered_items"
      :fields="showFields"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      sticky-header
    >
      <template #cell(name)="data">
        <div :title='data.item.name' class='d-none d-sm-block'>
          {{ data.item.name }}
        </div>
        <div :title='data.item.name' class='d-block d-sm-none'>
          {{ maxLengthOfCell(data.item.name, 14) }}
        </div>
      </template>
      <template #cell(error)="row">
        <v-chip
          style="font-size: 10px; color: red; border-color: red;"
          v-if="calc_error(row.item) === 'no_period_type'"
          color="red"
          outlined
        >
          {{ $t('SHOP_ITEM.ERRORS.NO_PERIOD_TYPE') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: red; border-color: red;"
          v-if="calc_error(row.item) === 'no_period'"
          color="red"
          outlined
        >
          {{ $t('SHOP_ITEM.ERRORS.NO_PERIOD') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: orange; border-color: orange;"
          v-if="calc_error(row.item) === 'no_next_shop_item'"
          color="orange"

          outlined
        >
          {{ $t('SHOP_ITEM.ERRORS.NO_NEXT_SHOP_ITEM') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: orange; border-color: orange;"
          v-if="calc_error(row.item) === 'no_next_period'"
          color="orange"
          outlined
        >
          {{ $t('SHOP_ITEM.ERRORS.NO_NEXT_PERIOD') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: green; border-color: green;"
          v-if="calc_error(row.item) === 'ok'"
          color="green"
          outlined
        >
          {{ $t('SHOP_ITEM.ERRORS.OK') }}
        </v-chip>
      </template>
      <template #cell(is_active)="data">
        <v-checkbox @change="toggle_is_active(data.item)" v-model="data.item.is_active" style="margin-top: 0px; padding-top: 0px;"></v-checkbox>
      </template>
      <template #cell(actions)="data">
        <div class='d-flex justify-content-end'>
          <a
            href="#"
            class="btn btn-icon btn-light btn-sm mx-3"
            @click.prevent="select_shop_item_clicked(data.item.shop_item_id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </a>
          <a
            href="#"
            class="btn btn-icon btn-light btn-sm mr-4"
            @click="delete_shop_item_clicked(data.item.shop_item_id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </a>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ShopItemsTable',
  props: ['items', 'currentCompanyId'],
  emits: ['create_shop_item_clicked', 'select_shop_item_clicked', 'delete_shop_item_clicked'],
  components: {

  },
  watch: {
    
  },
  mixins: [ toasts ],
  methods: {
    
    async toggle_is_active(item) {
      try {
        const response = await axios.put(`/shop_item/${item.shop_item_id}`, { is_active: item.is_active });

        if (response.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SHOP_ITEM.UPDATED'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ITEM.UNABLE_UPDATE'));
        }
      }
      catch (err) {
        console.error('toggle_is_active error', err);
      }
    },

    calc_error(item) {
      if (item.period_type === null || item.period_type === undefined || item.period_type === '') {
        return 'no_period_type';
      }

      if (item.period_type === 'PERIODS') {
        if (item.period_ids === null || item.period_ids === undefined || item.period_ids.length === 0) {
          return 'no_period';
        }
      }

      if (!item.next_shop_item) {
        return 'no_next_shop_item';
      }

      if (!item.next_period_id) {
        return 'no_next_period';
      }

      return 'ok'
    },

    create_shop_item_clicked() {
      this.$emit('create_shop_item_clicked');
    },
    select_shop_item_clicked(shop_item_id) {
      this.$emit('select_shop_item_clicked', shop_item_id);
    },
    delete_shop_item_clicked(shop_item_id) {
      this.$emit('delete_shop_item_clicked', shop_item_id);
    },
    maxLengthOfCell(str, length) {
      return str.length > length ? str.substring(0, length) + '...' : str;
    },
  },
  computed: {
    filtered_items() {
      return this.items.filter(item => this.show_inactive ? true : item.is_active);
    },
    total_rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      show_inactive: true,
      per_page: 25,
      current_page: 1,

      showFields: [
        { key: 'shop_item_id', label: this.$t('COMMON.ID'), show: true, sortable: true, thClass: 'td-short', tdClass: ''},
        {
          key: 'next_shop_item_id',
          label: this.$t('PAGES.SHOPITEM.NEXT_SHOP_ITEM'),
          show: true,
          sortable: true,
          thClass: 'td-short4',
          tdClass: 'td-short4',
          formatter: (_, __, item) => {
            return item.next_shop_item ? item.next_shop_item.name : '';
          },
        },
        { 
          key: 'created_at', 
          label: this.$t('PAGES.SHOPITEM.CREATED_AT'), 
          show: true, 
          sortable: true, 
          thClass: '', 
          tdClass: '',
          formatter: (_, __, item) => {
            return item.created_at ? dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss') : '-';
          },
        },
        { key: 'name', label: this.$t('PAGES.SHOPITEM.NAME'), show: true, sortable: true, thClass: 'w-100', tdClass: 'bold w-100'},
        { key: 'error', label: this.$t('SHOP_ITEM.ERROR'), show: true, sortable: true, thClass: 'td-short2', tdClass: '' },
        { key: 'is_active', label: this.$t('SHOP_ITEM.IS_ACTIVE'), show: true, sortable: true, thClass: 'td-short2', tdClass: '' },
        
        { key: 'amount', label: this.$t('SHOP_ITEM.AMOUNT'), show: true, sortable: true, thClass: '', tdClass: ''},
        { key: 'amount_vat', label: this.$t('SHOP_ITEM.AMOUNT_VAT'), show: true, sortable: true, thClass: '', tdClass: ''},
        { key: 'actions', label: '', show: true, sortable: false}
      ],
      filter: '',
      list: [

      ]
    };
  }
};
</script>


<style lang="css" scoped>

.bold {
  font-weight: bold !important;
}

.bold div {
  font-weight: bold !important;
}


:deep .v-icon {
  color: rgb(35 154 255);
}

:deep orange--text {
  background-color: orange !important;
}



:deep .td-short {
  width: 32px;
  overflow: hidden;
}

:deep .td-short2 {
  width: 96px;
  overflow: hidden;
}

</style>
