<template>
  <div class="wizard-body mx-auto bg-white shadow-sm">
    <b-card v-if="created" :title="$t('RENEW_WIZARD.MEMBERSHIP_RENEWED_HEADER')" hide-footer class="mb-2 mt-2 ">
      <b-alert variant="success" show>{{ $t('RENEW_WIZARD.MEMBERSHIP_RENEWED') }}</b-alert>
    </b-card>

    <b-card v-if="is_renewed" :title="$t('RENEW_WIZARD.ALREADY_RENEWED')" hide-footer class="mb-2 mt-2 ">
      <b-alert variant="success" show>{{ $t('RENEW_WIZARD.ERROR_ALREADY_RENEWED1') }}</b-alert>
    </b-card>


    <b-card v-if="error" title="Ett fel uppstod" hide-footer class="mb-2 mt-2 bg-white">
      <b-alert variant="danger" show>{{ $t('RENEW_WIZARD.ERROR_NOT_RENEWED1') }}</b-alert>
    </b-card>

    <b-card
      v-if="renew_error"
      title="Ett fel uppstod"
      class="wizard-body mb-5 pt-5 bg-white"
      hide-footer
    >
      <b-alert variant="danger" show
        >{{ $t('RENEW_WIZARD.UNABLE_GET_DATA') }}</b-alert
      >
    </b-card>

    <!-- End: Member Type-->
    <b-card
      v-if="!created && !renew_error"
      title=""
      class="wizard-body mb-5 pt-5 bg-white"
      hide-footer
    >
      <!--begin: Wizard-->

      <b-card v-if="pages === null || pages.length === 0">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-card>

      <!--begin: Wizard Body-->
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="col-xl-12">
          <!--begin: Wizard Form-->
          <form class="form" id="kt_form" @submit.prevent="renew_account">


            <div
              v-for="(page, idx) in pages"
              :key="idx"
            >

              <!-- Begin: Personnr -->
              <div class="fade-in-up-enter-to" v-if="page.type === 'personnr'">
                <PersonnrPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_personnr"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  @isAlreadyRegistered="personnrIsAlreadyRegistered"
                  :requirements="requirements"
                  :page_data.sync="page_data"
                  :page="page"
                  :isRenew="true"
                  @ageUpdated="ageUpdated"
                  @doSparLookup="doSparLookup"
                />
              </div>
              <!-- End: Personnr -->

              <!-- Begin: Details -->
              <div class="fade-in-up-enter-to" v-else-if="page.type === 'details'">
                <DetailsPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_details"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  @isAlreadyRegistered="emailIsAlreadyRegistered"
                  :requirements="requirements"
                  :isRenew="true"
                  :page_data.sync="page_data"
                  :spar="spar"
                  :page="page"
                  :regions="regions"
                  :personnr="postForm.personnr.personnr"
                />
              </div>

              <!-- End: Details -->

              <!-- Begin: Register Family -->
              <div class="fade-in-up-enter-to" v-else-if="page.type === 'reg_family'">
                <RegisterFamily
                  v-show="renderPage.id === page.id"
                  ref="ref_page_reg_family"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :company_id="company_id"
                  :page_data.sync="page_data"
                  :page="page"
                  :me="me"
                  :regions="regions"
                />
              </div>

              <!-- End: Register Family -->

              <!-- Begin: Properties -->
              <div class="fade-in-up-enter-to" v-else-if="page.type === 'properties'">
                <PropertyPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_properties"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :company_id="company_id"
                  :page="page"
                  :page_data.sync="page_data"
                />
              </div>
              <!-- End: Properties -->

              <!-- Begin: Parents -->
              <div class="pb-5 fade-in-up-enter-to" v-else-if="page.type === 'parents' && age < company.registration.age_max_parent"
              >
                <ParentsPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_parents"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :parentRecord="parentRecord"
                  :requirements="requirements"
                  :age="age"
                  :page_data.sync="page_data"
                  :ageMaxParent="company.registration.age_max_parent"
                  :page="page"
                  :regions="regions"
                />
              </div>
              <!-- End: Parents -->

              <!-- Begin: Shop -->
              <div class="fade-in-up-enter-to" v-else-if="page.type === 'shop'">
                <ShopMembershipSelect
                  v-show="renderPage.id === page.id"
                  ref="ref_page_shop"
                  @wizardPageWasUpdated="wizardPageWasUpdated"

                  :requirements="requirements"
                  :member_renew_id="member_renew_id"
                  :token="token"
                  :memberships="memberships"
                  :extras="extras"
                  :page="page"
                  :page_data.sync="page_data"
                  :age="age"
                  :company_id="company_id"
                  :company_ids="company_ids"
                  :member_id="member_id"
                  @membershipUpdated="membershipUpdated"

                />
              </div>
              <!-- End: Shop -->

              <!-- Begin: registration V2 -->
              <div class="fade-in-up-enter-to" v-else-if="page.type === 'company_select_v2'">

                <CompanySelectRegistrationV2
                  v-show="renderPage.id === page.id"
                  ref="ref_page_company_select_v2"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :company_id="company_id"
                  :page_data.sync="page_data"
                  :page="page"
                  :region_code="region_code"
                  :commune_code="commune_code"
                  @selected="companies_selected"
                />
              </div>
              <!-- End: registration V2 -->

              <!-- Begin: Final confirm step -->
              <div class="fade-in-up-enter-to" v-else-if="page.type === 'confirm'">
                <ConfirmRenewPage
                  v-if="me"
                  v-show="renderPage.id === page.id"
                  ref="ref_page_confirm"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :member_id="me.member_id"
                  :family="family"
                  :page="page"
                  :regdata="regdata"
                  :company="company"
                  :page_data.sync="page_data"
                  @isAllPolicyChecked="isAllPolicyChecked"
                />
              </div>
              <!-- End: Final confirm step -->

              <!-- Begin: Student -->
              <div class="fade-in-up-enter-to" v-else-if="page.type === 'student'">
                <StudentPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_student"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :pageData.sync="page_data"
                  :page="page"
                />
              </div>

            <!-- End: Student -->
            </div>


            <!--begin: Wizard Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2" v-show="currentPage !== 0">
                <button
                  class="reg-button btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  @click.prevent="previousPage"
                >
                {{$t('PAGES.REGISTRATION.PREV_STEP')}}
                </button>
              </div>
              <div v-show="!lastPage">
                <button
                  class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  @click.prevent="nextPage"
                  :disabled="!showNextPage"
                >
                {{$t('PAGES.REGISTRATION.NEXT_STEP')}}
                </button>
              </div>

              <div v-show="lastPage && showNextPage">
                <button
                  class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  type="submit"
                  :disabled="disabled_renew_button"
                >
                  {{ this.page_data.data.mustpay ? $t('PAGES.REGISTRATION.PROCEED_TO_PAYMENT') : $t('PAGES.REGISTRATION.RENEW_ACCOUNT') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Actions -->
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";


.wizard-body {
  max-width: 1000px;
  width: 100%;
}
</style>

<script>
import i18nService from "@/core/services/i18n.service.js";

import AreaAllocMultiMembership from '@/view/pages/members/register/registerwizard/area_alloc/AreaAllocMultiMembership.vue';
import { mapGetters } from 'vuex';
import messageParent from '@/core/services/messageParent';
import { isPlugin } from "@/core/services/messageParent";
import axios from 'axios';

import ShopMembershipSelect from '@/view/pages/members/register/registerwizard/ShopMembershipSelect.vue';
import ConfirmRenewPage from '@/view/pages/members/register/registerwizard/ConfirmRenewPage.vue';
import PropertyPage from '@/view/pages/members/register/registerwizard/PropertyPage.vue';
import PersonnrPage from '@/view/pages/members/register/registerwizard/PersonnrPage.vue';
import ParentsPage from '@/view/pages/members/register/registerwizard/ParentsPage.vue';
import DetailsPage from '@/view/pages/members/register/registerwizard/DetailsPage.vue';
import RegisterFamily from './registerwizard/RegisterFamily';
import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import StudentPage from '@/view/pages/members/register/registerwizard/StudentPage.vue';
import CompanySelectRegistrationV2 from '@/view/pages/members/register/registerwizard/company_select_v2/CompanySelectRegistrationV2.vue';
import { set_session_customer_id } from '@/core/services/member.service.js';


import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'renewwizard',
  components: {
    CompanySelectRegistrationV2,
    AreaAllocMultiMembership,
    ShopMembershipSelect,
    ConfirmRenewPage,
    PropertyPage,
    PersonnrPage,
    ParentsPage,
    DetailsPage,

    //DinteroPayment,

    RegisterFamily,
    StudentPage
  },
  mixins: [ toasts ],
  mounted: function() {
    messageParent(JSON.stringify({ message: 'this is a message from the child iframe' }));

    this.loadData();
  },
  data() {
    return {

      /// companies to renew into
      company_ids: [],

      disabled_renew_button: false,
      parentRecord: null,

      is_renewed: false,
      currentPageType: '',
      isNextButtonOK: false,
      pageValidations: {},
      requirements: {},
      member_renew_id: null,
      me: null,
      family_page: null,
      getPersonnr: null,
      spar: null,
      registration: null,
      renew_error: false,
      form: {},
      memberships: [],
      extras: [],
      company: {},
      regdata: {},
      accept_terms: false,
      age: 0,
      groups: [],
      region_code: '',
      commune_code: '',
      regions: [],
      communes: [],
      regionOptions: [],
      communeOptions: [],
      showNext: false,
      currentPage: 0,
      wizard: null,
      order: {},
      pages: [],
      ref_page_confirm: {
        settings: {
          terms: []
        }
      },
      page_data: {
        data: {}
      },

      created: false,
      error: false,
      show: '',
      show_list: [],
      personNr: {},
      renderPage: null,
      token: null,
      member_id: null,
      postForm: { // the data from all pages that is POSTed to server
        area_alloc: {
          company_ids: []
        },
        shop: {
          shop_items: [],
          cart: [],
          total_price: 0,
          total_vat: 0
        },
        details: {
          firstname: null, lastname: null, address: null, co: null, commune_code: null, region_code: null, dob: null, email: null, email2: null, invoice_address: null, invoice_firstname: null, invoice_lastname: null, invoice_post: null, country: 'NONE', invoice_country: 'NONE', invoice_zipcode: null, landline: null, password: null, password2: null, phone: null, post: null, zipcode: null, company_name: null
        },
        personnr: {
          personnr: null,
          skip: false
        },
        properties: [],
        family: [],
        parents: {}
      },
    };
  },
  watch: {
    age(newVal, oldVal) {
      // find min age we allow without parent
      if (newVal > this.registration.age_max_parent) {
        this.pages = this.pages.filter(p => p.type !== 'parents');
      }
    },
    commune_code(newVal, oldVal) {
      if (!newVal) {
        return;
      }
      if (this.region_code in this.regions && newVal in this.regions[this.region_code].communes) {
        this.form.commune = this.regions[this.region_code].communes[newVal];
      }
    },
    region_code(newVal, oldVal) {
      if (!newVal) {
        return;
      }

      if (newVal in this.regions) {
        this.form.region = this.regions[newVal].region;
      }
    },
    showNext(newVal, oldVal) {}
  },
  computed: {
    ...mapGetters(['isMemAuthenticated']),

    showNextPage() {
      if (!this.renderPage || !this.pageValidations) {
        return false;
      }

      return this.isNextButtonOK;


      if (
        !this.renderPage ||
        !this.page_data[this.renderPage.id] ||
        !this.page_data[this.renderPage.id].validation
      )
        return false;
      return !this.page_data[this.renderPage.id].validation.$invalid;
    },
    pageArray() {
      return Object.values(this.page_data)
        .filter(
          a => a.id !== undefined && a.id !== null && a.index !== undefined && a.index !== null
        )
        .sort((a, b) => a.index - b.index);
    },
    lastPage() {
      if (!this.renderPage || !this.pageArray) return false;
      return this.renderPage.id === this.pageArray[this.pageArray.length - 1].id;
    },
    nextIndex() {
      let next = this.currentPage + 1;

      for (let i = next; i < this.pageArray.length; i++) {
        if (this.pageArray[i].skip) {
          next = next + 1;
        } else {
          break;
        }
      }

      if (next >= this.pageArray.length) {
        return -1;
      }

      return next;
    },
    previousIndex() {
      let previous = this.currentPage - 1;

      for (let i = previous; i >= 0; i--) {
        if (this.pageArray[i].skip) {
          previous = previous - 1;
        } else {
          break;
        }
      }

      if (previous < 0) {
        return -1;
      }

      return previous;
    },
    familyPage() {
      return this.pageArray.find(p => p.type === 'reg_family');
    },
    ref_page_parents() {
      return this.pageArray.find(p => p.type === 'parents');
    },
    ref_page_area_alloc() {
      return this.pageArray.find(p => p.type === 'area_alloc');
    },

  },
  methods: {

    /// companies has been selected from Company Select V2
    companies_selected(company_ids) {

      console.log('companies_selected', company_ids);

      this.postForm.area_alloc.company_ids = company_ids;

      this.postForm = { ...this.postForm };

      this.company_ids = company_ids;

      this.company_ids = [ ...this.company_ids ];
    },

    emailIsAlreadyRegistered(email) {
      this.showAlreadyRegistered(false, true);
    },
    personnrIsAlreadyRegistered(personnr) {
      this.showAlreadyRegistered(true, false);
    },
    calcNextButtonIsOKForCurrentPage() {

      try {
        const ref_name = `ref_page_${this.currentPageType}`;
        const ref = this.$refs[ref_name][0];

        if (!ref) {
          console.error('current page has undefiend ref');

          return;
        }

        this.isNextButtonOK = ref.isNextButtonOK();
      }
      catch (err) {
        console.error('attempting to get ref for page type ' + this.currentPageType, err);
      }

      this.isNextButtonOK = true;

    },
    getPageData(pageType) {

      const ref_name = `ref_page_${pageType}`;
      const ref = this.$refs[ref_name][0];

      if (!ref) {
        console.error('current page has undefined ref', pageType);

        return null;
      }

      return ref.getData();
    },
    wizardPageWasUpdated(pageType, data, isNextButtonOK) {

      const pd = pageType === 'details' ? data : this.getPageData(pageType);

      if (pd === null || pd === undefined) {
        console.error('wizardPageWasUpdated pd is null', pageType, pd);
        return;
      }

      switch (pageType) {
        case 'student': {
          this.postForm.student = pd;

          break;
        }
        case 'shop': {
          this.postForm.shop = pd;
          this.postForm.campaign_code = pd.campaign_code;
          break;
        }
        case 'company_select_v2': {
          this.postForm.area_alloc = pd;

          this.postForm = { ...this.postForm };

          break;
        }
        case 'area_alloc': {
          this.postForm.area_alloc = pd;

          break;
        }
        case 'details': {
          for (const k in pd) {
            if (k && pd[k] !== null && pd[k] !== undefined && pd[k] !== '') {
              this.postForm.details[k] = pd[k];
            }
          }

          if (pd.dob !== null && pd.dob !== undefined && pd.dob !== '') {
            const age = this.getAgeFromDob(pd.dob);
            this.ageUpdated(age);
          }

          break;
        }
        case 'personnr': {
          this.postForm.personnr = pd;

          break;
        }
        case 'reg_family': {
          this.postForm.family = pd;

          break;
        }
        case 'parents': {
          this.postForm.parents = pd;

          break;
        }

        case 'properties': {
          this.postForm.properties = pd;

          break;
        }

      }

      if (pageType === this.currentPageType) {
        this.isNextButtonOK = isNextButtonOK;
      }
    },
    getAgeFromDob(dob) {
      const personnr = formatPersonnr(dob);

      if (personnr.success) {
        return dayjs().diff(
          personnr.year + '-' + personnr.month + '-' + personnr.day,
          'years'
        );
      }

      return 18;
    },

    async nextPage() {
      
      this.page_data.data = Object.values(this.page_data).reduce((acc, cur) => {
        acc = { ...acc, ...cur.form };
        return acc;
      }, {})
      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };

      if (this.currentPageType == 'details') {
        const ref_name = `ref_page_${this.currentPageType}`;
        const ref = this.$refs[ref_name][0];
        const valid = await ref.validate();
        
        //if (!valid) { return; }
      }

      if (this.nextIndex >= 0) {
        this.renderPage = this.pageArray[this.nextIndex];
        this.currentPage = this.nextIndex;
      }

      this.currentPageType = this.renderPage.type;

      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
      
      setTimeout(function() {
        messageParent(JSON.stringify({ height: document.body.scrollHeight }));
      }, 300);
    },
    previousPage(e) {
      if (this.previousIndex >= 0) {
        this.renderPage = this.pageArray[this.previousIndex];
        this.currentPage = this.previousIndex;
        this.currentPageType = this.renderPage.type;
        this.calcNextButtonIsOKForCurrentPage();
      }

       window.scrollTo(0, 0);
    },
    isAllPolicyChecked(isChecked) {
      this.accept_terms = isChecked;
    },
    doSparLookup(personnr) {

      axios
        .post(`/member/spar_personnr`, { personnr })
        .then(res => {
          if (res.status === 200) {
            this.spar = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.OK'), 'Kunde inte göra SPAR uppslag');
        });
    },
    ageUpdated(age) {
      const parent_page = Object.values(this.page_data).find(item => item.type === 'parents');

      if (!parent_page) {
        return;
      }

      parent_page.skip = age >= parent_page.settings.from_age;

      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };
    },
    membershipUpdated(memberships) {
      if (!this.familyPage) return;
      const skip = memberships.find(m => m.is_family) ? false : true;

      this.page_data[this.familyPage.id] = {
        ...this.page_data[this.familyPage.id],
        skip
      };
      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };
    },
    async loadData() {
      this.token = this.$route.params.token;
      this.member_id = this.$route.params.member_id;
      this.company_id = this.$route.params.company_id;

      await this.loadRegistration(this.company_id);
      await this.loadRegions();
    },
    loadRegistration(company_id) {
      if (this.member_id === null || this.token === null) {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Token eller medlemsID saknas');

        return;
      }

      axios
        .get(`/company/public/${company_id}`)
        .then(res => {
          this.company = res.data;
          this.memberships = [];
          this.extras = [];
          this.requirements = {
            MEMBER_REQ_PERSONNR: false,
            MEMBER_REQ_SSN: false,
            MEMBER_REQ_AGE: false,
            MEMBER_REQ_SEX: false,
            MEMBER_REQ_ADDRESS: false,
            MEMBER_REQ_FULLNAME: false,
            MEMBER_REQ_EMAIL: false,
            MEMBER_REQ_AVD: false,
            MEMBER_REQ_PHONE: false,
            MEMBER_REQ_EMAILPHONE: false,
            MEMBER_REQ_CNAME: false,
            MEMBER_REQ_ORG_NUMBER: false,
          };

          set_session_customer_id(res.data.customer_id);

          for (const r of res.data.requirements) {
            this.requirements[r.name] = true;
          }

          this.company.shop_items.filter(item => {
            if (item.is_member_fee && Boolean(item.is_active))
              this.memberships.push(item);
            if (!item.is_member_fee && Boolean(item.is_active)) this.extras.push(item);
          });

          if (this.company.company_settings.custom_font_href) {
            const link = document.createElement('link');
            link.href = this.company.company_settings.custom_font_href;
            link.rel = 'stylesheet';

            document.getElementsByTagName('head')[0].appendChild(link);
          }


          this.accept_terms = false;

          this.registration = res.data.registration;

          if (this.registration.register_css) {
            let file = document.createElement('link');
            file.rel = 'stylesheet';
            file.href = this.registration.register_css;
            file.type = 'text/css';
            document.head.appendChild(file)
          }

          if (this.registration.lang) {
            i18nService.setActiveLanguage(this.registration.lang);
          }

          this.pages = res.data.pages;

          const company_page = this.pages.find(item => item.type === 'company_select_v2');

          if (!company_page && this.company_ids.length === 0) {
            this.company_ids = [this.company.company_id];
          }

          const shop_page = this.pages.find(item => item.type === 'shop');

          const final_pages = [];

          for (const p of this.pages) {
            if (p.settings === null || p.settings === undefined) {
              final_pages.push(p);
              continue;
            }

            if (p.settings.is_renew === null || p.settings.is_renew === undefined) {
              final_pages.push(p);
              continue;
            }

            if (p.settings.is_renew) {
              final_pages.push(p);
            }
          }

          this.pages = final_pages;

          this.page_data = this.pages.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});

          this.page_data.data = {};
          // Vue3 compatability for Vue.set
          this.page_data = { ...this.page_data };

          this.renderPage = this.pages[0];

          this.loadClient(this.member_id, this.token);

          this.currentPageType = this.renderPage.type;

          for (const p of this.pages) {
            this.pageValidations[p.type] = false;
          }

          setTimeout(function() {
            messageParent(JSON.stringify({ height: document.body.scrollHeight }));
          }, 300);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta registreringswizard');
        });
    },
    setForm(client, company_ids, family, parentRecord) {

      if (this.$refs.ref_page_personnr !== undefined && this.$refs.ref_page_personnr.length > 0) {
        this.$refs.ref_page_personnr[0].setForm(client);
      }

      if (this.$refs.ref_page_details !== undefined && this.$refs.ref_page_details.length > 0) {
        this.$refs.ref_page_details[0].setForm(client);
      }

      if (this.$refs.ref_page_properties !== undefined && this.$refs.ref_page_properties.length > 0) {
        this.$refs.ref_page_properties[0].setForm(client);
      }

      if (this.$refs.ref_page_area_alloc !== undefined && this.$refs.ref_page_area_alloc.length > 0) {
        this.$refs.ref_page_area_alloc[0].setForm(company_ids);
      }

      if (this.$refs.ref_page_parents !== undefined && this.$refs.ref_page_parents.length > 0) {
        this.$refs.ref_page_parents[0].setForm(parentRecord);
      }

      if (this.$refs.ref_page_reg_family !== undefined && this.$refs.ref_page_reg_family.length > 0) {
        // this.$refs.ref_page_reg_family[0].setData(client);
        this.$refs.ref_page_reg_family[0].setForm(family);
      }
    },
    loadClient(member_id, token) {
      axios
        .get(`/member/renew?member_id=${member_id}&token=${token}&reset=true`)
        .then(res => {
          if (res.status !== 200) {
            this.renew_error = true;
            return;
          }

          // check if the member has paid already

          if (res.data.dintero_id && res.data.is_paid) {
            this.is_renewed = true;
            if (this.isMemAuthenticated) {
              location.href = '/profile';
            }
            return;
          }

          this.me = res.data.member;

          if (!this.me) {
            console.error('me is undefined');
            this.renew_error = true;
            return;
          }

          this.member_renew_id = res.data.member_renew_id;
          this.region_code = this.me.region_code;
          this.commune_code = this.me.commune_code;
          this.family = res.data.family;

          this.parentRecord = res.data.parents && res.data.parents.length > 0 ? res.data.parents[0] : null;


          const renew = res.data.renew;

          if (renew.is_confirm) {
            this.$router.push(`/renew-confirm/${this.me.member_id}/${renew.id}/${token}`);
            return;
          }

          try {
            var formatted = formatPersonnr(this.me.in_personnr);

            if (formatted.success) {
              this.me.fmt_personnr = formatted.formatted;
              this.me.age = dayjs().diff(
                formatted.year + '-' + formatted.month + '-' + formatted.day,
                'years'
              );
            }
          } catch (err) {
            console.error('unable to format personnr: ' + err);
          }

          this.company_ids = res.data.company_ids;

          this.company_ids = [ ...this.company_ids ];

          this.setForm(this.me, res.data.company_ids, res.data.family, this.parentRecord);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hitta medlemsdata');
        });
    },
    async renew_account() {
      try {
        this.disabled_renew_button = true;

        const loader = this.$loading.show();

        let data = this.validateData(this.postForm);

        data.member_id = this.member_id;
        data.token = this.token;

        data.area_alloc = {
          company_ids: this.company_ids
        };

        /// if no area alloc page, we add the company id
        if (data.area_alloc.company_ids.length === 0) {
          data.area_alloc.company_ids = [this.company_id];
        }

        const res = await axios.post('/member/renew', data)

        if (res.status === 201) {
          loader && loader.hide();

          messageParent(
            JSON.stringify({
              register: true,
              client_id: res.data.member.member_id,
              token: res.data.token
            })
          );

          const order = res.data.order;
          const member_id = res.data.member_id;

          if (order) {
            switch (order.expected_action) {
              case 'SELECT_PAYMENT': {
                this.$router.push(`/select-payment/${order.token}/${order.shop_order_id}`);
                break;
              }
              case 'DINTERO_PAYMENT': {
                this.$router.push(`/payment/${order.shop_order_id}/${order.dintero_id}/${order.token}`);
                break;
              }
              case 'STRIPE_PAYMENT': {
                /// send to stripe payment with register as type
                this.$router.push(`/stripe-payment/${member_id}?client_secret=${order.stripe_client_secret}&type=register&token=${order.token}`);
                break;
              }
              case 'NO_PAYMENT': {
                if (this.company.company_settings.post_renew_url) {
                  let url = this.company.company_settings.post_renew_url;

                  // add http:// if not present

                  if (!url.match(/^https?:\/\//)) {
                    url = 'http://' + url;
                  }

                  messageParent(JSON.stringify({ redirect: url }));

                  if (!isPlugin()) {
                    window.location.href = url;
                  }
                } else {
                  this.created = true;
                }
                break;
              }
              default: {
                /// STRIPE_INVOICE_PAYMENT
                /// INVOICE_PAYMENT
                /// NOT_DETERMINED
                /// UNKNOWN
                this.toastr(this.$t('COMMON.ERROR'), 'Not implemented payment option: ' + order.expected_action);
                break;
              }
            }
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'No order was created');
          }

        } else {
          this.error = true;
          messageParent(JSON.stringify({ register: false }));
          this.disabled_renew_button = false;
          loader && loader.hide();
        }
      }
      catch (err) {
        console.error('renew_account error', err);

        loader && loader.hide();

          messageParent(JSON.stringify({ register: false }));

          this.error = true;
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa medlemskonto');
          this.disabled_renew_button = false;
      }

    },

    validateData(data) {

      // TODO: For now, make sure that everything that the backend requires exists on the object
      // Later, we should make sure that the data is valid and aligned with the backend
      if (!data.area_alloc || !data.area_alloc.company_ids || data.area_alloc.company_ids.length === 0) {
        data.area_alloc = { company_ids: [this.company_id] };
      }

      return data;
    },

    loadRegions() {
      axios
        .get('https://zip.memlist.se/api/v1/regions_communes')
        .then(res => {
          this.regions = res.data.regions;
          this.regionOptions = [];

          for (var k in this.regions) {
            this.regionOptions.push({ value: k, text: this.regions[k].region });
          }

          this.regionOptions.sort(function(a, b) {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta regioner');
        });
    }
  }
};
</script>
<style lang="css">

.reg-button {}
.reg-title {}
.reg-help {}
.reg-input {}

</style>