<template>
  <div class="memlist-select-input-container ml-select-input2">
    <div v-if="layout === 'horizontal'">
      <div v-if="title" class="horizontal1">
        <label style="font-weight: bold;">{{ title }}</label>
      </div>
      <div class="horizontal2">
        <select v-model="local_value" id="memlist-select">
          <option :value="null">{{ $t('PAGES.ATTRIBUTES.NONE') }}</option>
          <option
            v-for="prop of items"
            :key="prop.value"
            :value="prop.value"
          >{{ prop.text }}</option>
        </select>

        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">{{ invalid_text }}</div>

        <div class="info-text">{{ info_text }}</div>
      </div>
    </div>

    <div v-if="layout === 'vertical'">
      <div v-if="title" class="vertical1">
        <label style="font-weight: bold;">{{ title }}</label>
      </div>
      <div class="vertical2">
        <select v-model="local_value" id="memlist-select">
          <option
            v-for="prop of items"
            :key="prop.value"
            :value="prop.value"
          >{{ prop.text }}</option>
        </select>

        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">{{ invalid_text }}</div>

        <div class="info-text">{{ info_text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';
import { validate_email } from '@/core/services/email';
import { validatePersonnr } from '@/core/services/personnr';

export default {
  name: 'MemlistSelectInput2',

  props: {
    layout: {
      type: String,
      default: 'horizontal',
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    validate: {
      type: Number,
      default: 0,
    },
    invalid_text: {
      type: String,
      default: '',
    },
    info_text: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    validation_type: {
      type: String,
      default: 'TEXT',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: [String, Number], // For Vue 2
    placeholder: {
      type: String,
      default: '',
    },
    validation_function: {
      type: Function,
      default: null,
    },
  },

  mixins: [toasts],

  emits: ['input', 'validated'],

  data() {
    return {
      locked: false,
      local_state: null,
      local_value: this.value,
    };
  },

  watch: {
    local_value(newValue) {
      if (this.locked) {
        return;
      }
      // Emit the 'input' event with the new value
      this.$emit('input', newValue);
    },
    validate(newValue) {
      if (newValue !== 0) {
        this.local_state = this.validate_input();
      }
    },
    value(newValue) {
      this.local_value = newValue;
    },
  },

  computed: {
    state_class() {
      if (!this.required) {
        return 'memlist-text-input';
      }

      if (this.local_state !== null) {
        return this.local_state ? 'memlist-text-input success1' : 'memlist-text-input failure1';
      }

      return 'memlist-text-input';
    },
  },

  mounted() {
    this.locked = true;
    this.local_value = this.value;
    this.locked = false;
  },

  methods: {
    validate_input() {
      if (this.validation_function !== null) {
        const res = this.validation_function(this.local_value);

        this.$emit('validated', this.name, this.local_value, res);

        this.local_state = res;

        return res;
      }

      if (!this.required) {
        return true;
      }

      if (this.local_value === undefined) {
        this.local_value = '';
      }

      if (this.disabled) {
        this.$emit('validated', this.name, this.local_value, true);
        return true;
      }

      if (this.required && !this.local_value) {
        this.$emit('validated', this.name, this.local_value, false);
        return false;
      }

      if (this.validation_type === 'TEXT') {
        const res = this.local_value.length > 0;

        this.$emit('validated', this.name, this.local_value, res);

        return res;
      } else if (this.validation_type === 'PERSONNR') {
        const validated = validatePersonnr(this.local_value);

        if (validated.valid) {
          this.local_value = validated.formatted;

          this.$emit('validated', this.name, this.local_value, true);
          return true;
        } else {
          this.$emit('validated', this.name, this.local_value, false);
          return false;
        }
      } else if (this.validation_type === 'DOB') {
        const validated = validatePersonnr(this.local_value);

        if (validated.success) {
          this.local_value = validated.birthdate;

          this.$emit('validated', this.name, this.local_value, true);
          return true;
        } else {
          this.$emit('validated', this.name, this.local_value, false);
          return false;
        }
      } else if (this.validation_type === 'EMAIL') {
        const res = validate_email(this.local_value);

        this.$emit('validated', this.name, this.local_value, res);

        return res;
      }

      this.$emit('validated', this.name, this.local_value, true);
      return true;
    },

    on_input(event) {
      this.local_state = this.validate_input();

      // Emit 'input' event with the new value
      this.$emit('input', this.local_value);
    },
  },
};
</script>

<style scoped>
/* Reset default margin and padding */
select {
  border: 1px solid #e1e1e1;
  -webkit-appearance: auto;
  padding: 8px;
  min-width: 200px;
  border-radius: 12px;
}

.vertical1 {
  font-weight: bold;
}

.memlist-select-input-container {
  margin-top: 12px;
}
</style>
