<template>
  <div class="segmented-button-container ml-segmented-select-button">
    <div
      v-for="(part, index) of parts"
      :key="index"
      class="segment"
      :style="segmentStyle(index)"
      @click="selectSegment(index)"
      @mouseover="hoverOver(index)"
      @mouseleave="hoverOut"
    >
      {{ part.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemlistSegmentedSelectButton',
  props: {
    parts: {
      type: Array,
      default: () => []
    },
    primary_color: {
      type: String,
      default: '#0085eb'
    },
    secondary_color: {
      type: String,
      default: '#ffffff'
    },
    hover_color: {
      type: String,
      default: '#cce7f9'
    }
  },
  data() {
    return {
      selectedIndex: null,
      hoveredIndex: null
    };
  },
  methods: {
    
    segmentStyle(index) {
      let bg = this.secondary_color;
      let textColor = this.primary_color;
      let borderColor = this.primary_color;

      if (this.selectedIndex === index) {
        // Selected state
        bg = this.primary_color;
        textColor = this.secondary_color;
      } else if (this.hoveredIndex === index) {
        // Hover state (when not selected)
        bg = this.hover_color;
      } else {
        // Normal unselected, unhovered state
        bg = this.secondary_color;
      }

      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: `${borderColor}`,
        backgroundColor: bg,
        color: textColor,
        cursor: 'pointer',
        transition: 'background-color 0.2s ease, color 0.2s ease'
      };
    },
    selectSegment(index) {
      this.selectedIndex = index;
      this.$emit('selected', this.parts[index].value);
    },
    reset() {
      this.selectedIndex = null;
    },
    hoverOver(index) {
      this.hoveredIndex = index;
    },
    hoverOut() {
      this.hoveredIndex = null;
    }
  }
};
</script>

<style scoped>
.segmented-button-container {
  display: flex;
  height: 50px; /* Adjust as needed */
  box-sizing: border-box;
}

.segment {
  width: 80px;
  flex: 1;
  box-sizing: border-box;
  user-select: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid;
  border-bottom: 1px solid;
  /* Removed color and background from here; handled inline */
}

.segment:last-child {
  
}

.segment:first-child {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  border-left: 1px solid;
}

.segment:last-child {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  border-right: 1px solid;
}

.selected:last-child {
  border-right: none;
}

</style>
