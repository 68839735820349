<template>
  <div class="widget">

    <DonateraWidgetPopup 
      ref="donatera-popup"
      :form="form"
    />
    
    <div class="center-container">

      <memlist-circular-button
        class="fill-button-width"
        v-if="form && form.style && type === 'widget'"
        :text="form?.texts?.button_text || 'Donera'"
        :primary_color="form?.style?.primary_color"
        :secondary_color="form?.style?.secondary_color"
        :hover_color="form?.style?.hover_color"
        @click="open_popup"
      />

    </div>
    
  </div>
</template>

<script>
import axios from 'axios';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import DonateraWidgetPopup from './DonateraWidgetPopup.vue';


export default {
  name: 'DonateraWidget',
  props: [],
  mixins: [ toasts ],
  components: {
    DonateraWidgetPopup
  },
  computed: {
    
  },
  data() {
    return {
      bg: 'white',
      linkstr: null,
      form: null,
      type: 'widget',
    };
  },
  async mounted() {
    document.body.classList.add('no-background');

    this.linkstr = this.$route.params.token;
    this.bg = decodeURIComponent(this.$route.query.bg);
    this.type = this.$route.query.type || 'widget';

    if (this.type === 'openPopup') {
      this.$refs['donatera-popup'].show();
    }

    this.form = await this.load_form(this.linkstr);
  },
  watch: {
    
  },
  beforeDestroy() {
    document.body.classList.remove('no-background');
  },
  methods: {
    open_popup() {
      if (this.type === 'widget') {
        window.parent.postMessage({ action: 'expandOverlay' }, '*');
      }
      else {
        this.$refs['donatera-popup'].show();
      }
    },
    async load_form(linkstr) {
      const res = await axios.get(`/form/${linkstr}`);

      if (res.status === 200) {
        console.log('data', res.data);

        return res.data;
      }

      return null;
    },
  }
};
</script>


<style lang="css" scoped>

.no-background {
  background: none transparent !important;
}

.custom-container {
  display: flex;
  flex-direction: column;  /* Stacks items vertically */
  align-items: center;     /* Centers items horizontally */
  justify-content: center; /* Centers items vertically within the container if needed */
}

.top-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fill-button-width {
  height: 46px;
  min-width: 140px;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.single-selected {
  display: flex;
  align-items: center;
  padding: 8px;
}

.single-selected p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

h4 {
  text-align: center;
}

h5 {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

/* CSS Code */
.radio-button-group {
  display: flex;
  flex-direction: column;
}

.radio-button {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid #ccc; /* Default border color is gray */
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #fff; /* Default background color */
  transition: background-color 0.2s, border-color 0.2s;
}

.radio-button:hover {
  background-color: #f9f9f9;
}

.radio-button input[type="radio"] {
  display: none;
}

.radio-custom {
  width: 22px;
  height: 22px;
  border: 2px solid #ccc; /* Match the button's border color */
  border-radius: 50%;
  position: relative;
  margin-right: 12px;
  transition: border-color 0.2s;
}

.radio-custom::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #0085eb;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  opacity: 0;
  transition: opacity 0.2s;
}

.radio-label {
  font-size: 16px;
  transition: color 0.2s;
}

input[type="radio"] {
  display: none;
}

/* Styles when the radio button is selected */
input[type="radio"]:checked + .radio-button {
  background-color: #e6f0ff; /* Highlighted background color */
  border-color: #0085eb;     /* Highlighted border color */
}

input[type="radio"]:checked + .radio-button .radio-custom {
  border-color: #0085eb; /* Change radio indicator border color */
}

input[type="radio"]:checked + .radio-button .radio-custom::after {
  opacity: 1;
}

input[type="radio"]:checked + .radio-button .radio-label {
  font-weight: bold;
  color: #005bb5;
}

.amount-input {
  width: 100%;
}

.amount-input input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.amount-input input:focus {
  outline: none;
  border-color: #0085eb;
  box-shadow: 0 0 5px rgba(0, 133, 235, 0.5);
}

.custom_amount {
  font-weight: 400;
}

</style>