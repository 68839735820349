<template>
  <div :class="['memlist-text-input-container', styleClass, 'mt-2', 'ml-text-area']">
    <div v-if="layout === 'horizontal'">
      <div v-if="title" class="horizontal1">
        <label>{{ title }}</label>
      </div>
      <div class="horizontal2">
        <textarea
          :id="`${id}-1`"
          @input="on_input"
          :value="local_value"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="[state_class, inputStyleClass]"
          rows="1"
        ></textarea>
        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">
          {{ invalid_text }}
        </div>
      </div>
    </div>

    <div v-if="layout === 'vertical'">
      <div v-if="title" class="vertical1">
        <label>{{ title }}</label>
      </div>
      <div class="vertical2">
        <textarea
          :id="`${id}-2`"
          @input="on_input"
          :value="local_value"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="[state_class, inputStyleClass]"
          rows="1"
        ></textarea>
        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">
          {{ invalid_text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { randomstr } from '@/core/services/deviceId';

export default {
  name: 'MemlistTextArea',
  props: {
    variation: {
      type: String,
      default: 'normal'
    },
    size: {
      type: String,
      default: 'normal',
    },
    layout: {
      type: String,
      default: 'horizontal'
    },
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    validate: {
      type: Number,
      default: 0
    },
    invalid_text: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validation_type: {
      type: String,
      default: 'TEXT'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: [String, Number],
    placeholder: {
      type: String,
      default: ''
    },
    validation_function: {
      type: Function,
      default: null
    },
    autoexpand: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    }
  },
  
  mixins: [toasts],
  emits: ['input', 'change', 'validated'],

  watch: {
    validate(newValue) {
      if (newValue !== 0) {
        this.local_state = this.validate_input();
      }
    },

    value: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_value = val;

        this.$nextTick(()=>{
          this.do_autoexpand();
        });
      },
      immediate: true
    },

  },

  computed: {
    state_class() {
      if (!this.required) {
        return 'memlist-text-input';
      }
      if (this.local_state !== null) {
        return this.local_state ? 'memlist-text-input success1' : 'memlist-text-input failure1';
      }
      return 'memlist-text-input';
    },
    inputStyleClass() {

      
      switch (this.size) {
        case 'md':
          return 'text-md';
        case 'lg':
          return 'text-lg';
      }

      return '';
    },
    styleClass() {
      return this.variation === 'enhanced' ? 'enhanced-container' : '';
    }
  },
  mounted() {
    this.id = randomstr(8);
    this.local_value = this.value;

    this.$nextTick(() => {
      const elem1 = document.getElementById(`${this.id}-1`);
      const elem2 = document.getElementById(`${this.id}-2`);

      if (elem1) {
        elem1.addEventListener('keypress', this.on_keypress);
      }

      if (elem2) {
        elem2.addEventListener('keypress', this.on_keypress);
      }

    });

  },
  methods: {
    on_keypress(event) {
      if (this.submit && event.which === 13) {
        event.preventDefault();
        this.$emit('submit');
        return;
      }
    },
    validate_input() {
      return true;
    },

    do_autoexpand() {
      if (this.autoexpand) {
        
        const elem1 = document.getElementById(`${this.id}-1`);
        const elem2 = document.getElementById(`${this.id}-2`);

        if (elem1) {
          elem1.style.height = "";
          elem1.style.height = Math.min(elem1.scrollHeight + 10, 300) + "px"
        }

        if (elem2) {
          elem2.style.height = ""; 
          elem2.style.height = Math.min(elem2.scrollHeight + 10, 300) + "px"
        }
        
      }
    },

    on_input(event) {
      const inputValue = event.target.value;
      
      this.local_value = inputValue;
      this.local_state = this.validate_input();
      this.$emit('input', inputValue);

      this.do_autoexpand();
    }
  },
  data() {
    return {
      id: null,
      local_state: null,
      local_value: null
    };
  }
};
</script>

<style lang="css" scoped>

textarea.text-lg {
  font-size: 22px;
  padding: 8px;
  padding-left: 16px;
  padding-top: 12px;
}

textarea:focus {
  outline: none;
  box-shadow:0 1px 0 #3968ce;
  border-color:#3968ce;
}

.horizontal1 {
  float: left;
  width: 50%;
  text-align: right;
  padding-right: 16px;
  margin-top: 8px;
}

.horizontal2 {
  float: left;
  width: 50%;
}

label {
  font-weight: bold;
}

.vertical1 {
  margin-top: 8px;
}

.vertical2 {
  margin-right: 8px;
}

:deep .success1 {
  border: 1px solid #1BC5BD !important;
}

:deep .failure1 {
  border: 1px solid #fd397a !important;
}

:deep textarea:disabled {
  background-color: #E4E6EF !important;
  opacity: 1;
}

.error-text {
  color: #fd397a;
  font-style: italic;
  margin-top: 6px;
}

:deep .memlist-text-input {
  display: block;
  width: 100%;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff !important;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: vertical; /* Allow vertical resizing if needed */
}

/* Enhanced style for the textarea and container */
.enhanced-style {
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.enhanced-container {
  padding: 1rem;
}
</style>
