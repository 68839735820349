<template>
  <button :style="buttonStyle" class="custom-button ml-contrast-button" @click="onClick">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'MemlistContrastButton',
  props: {
    /**
     * The background color of the button. Accepts any valid CSS color value.
     */
    bgColor: {
      type: String,
      default: '#0085eb', // Default background color
    },

    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    /**
     * Determines if the background color is dark based on luminance.
     * Returns true if the color is dark, false if light.
     */
    isDarkBackground() {
      const rgb = this.hexToRgb(this.bgColor);
      if (!rgb) return false; // Default to light background if color parsing fails
      const luminance = this.getLuminance(rgb.r, rgb.g, rgb.b);
      return luminance < 0.5; // Threshold for dark background
    },
    /**
     * Sets the text color based on the background color brightness.
     * White text for dark backgrounds, black text for light backgrounds.
     */
    textColor() {
      return this.isDarkBackground ? '#ffffff' : '#000000';
    },
    /**
     * Combines the background and text colors into a style object.
     */
    buttonStyle() {
      return {
        backgroundColor: this.bgColor,
        color: this.textColor,
      };
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },

    /**
     * Converts a hex color string to an RGB object.
     * @param {String} hex - The hex color string.
     * @returns {Object|null} An object with r, g, b properties or null if invalid.
     */
    hexToRgb(hex) {
      // Expand shorthand hex codes, e.g., "#03F" to "#0033FF"
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    /**
     * Calculates the relative luminance of a color.
     * @param {Number} r - Red component (0-255).
     * @param {Number} g - Green component (0-255).
     * @param {Number} b - Blue component (0-255).
     * @returns {Number} The luminance value between 0 and 1.
     */
    getLuminance(r, g, b) {
      const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928
          ? v / 12.92
          : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    },
  },
};
</script>

<style scoped>
.custom-button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.custom-button:hover {
  opacity: 0.9;
}
</style>
