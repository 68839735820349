<template>
  <div class="todo-container mt-16">
    <KvittoSelectTableModal 
      ref="kvitto-select-modal"
      @connect="kvitto_connected"
    />

    <AccountTransactionModal
      :item="selected_transaction"
      ref="transaction-editor-modal"
    />

    <KvittoEditorModal
      :item="selected_kvitto"
      ref="kvitto-editor-modal"
    />

    <!-- HTML Code -->
    <div class="filter-container">
      <!-- First Row -->
      <div class="filter-row">
        <!-- Created From -->
        <div class="form-group">
          <label for="created_from">{{ $t('KVITTO.CREATED_FROM') }}</label>
          <input v-model="filters.created_from" type="date" id="created_from" name="created_from">
        </div>
        <!-- Created To -->
        <div class="form-group">
          <label for="created_to">{{ $t('KVITTO.CREATED_TO') }}</label>
          <input v-model="filters.created_to" type="date" id="created_to" name="created_to">
        </div>
      </div>

      <!-- Second Row -->
      <div class="filter-row">
        <!-- Status -->
        <div class="form-group">
          <label for="status">{{ $t('KVITTO.STATUS') }}</label>
          <select v-model="filters.status" id="status" name="status">
            <option value="">{{ $t('KVITTO.SELECT_STATUS') }}</option>
            <template v-for="status of status_options">
              <option :value="status.value">{{ status.text }}</option>
            </template>
          </select>
        </div>
        <!-- Assigned User -->
        <div class="form-group">
          <label for="assigned_user_id">{{ $t('KVITTO.ASSIGNED_USER_ID') }}</label>
          <select v-model="filters.assigned_user_id" id="assigned_user_id" name="assigned_user_id">
            <option value="">{{ $t('KVITTO.SELECT_USER') }}</option>

            <template v-for="user of assigned_user_options">
              <option :value="user.value">{{ user.text }}</option>
            </template>
          </select>
        </div>
      </div>

      <!-- Search Row -->
      <div class="filter-row">
        <!-- Search Input -->
        <div class="form-group">
          <label for="search_input">{{ $t('KVITTO.SEARCH') }}</label>
          <input type="search" id="search_input" name="search_input" :placeholder="$t('KVITTO.SEARCH_CONTENT')">
        </div>
      </div>
    </div>


    <!-- Sorting Header -->
    <div class="todo-header">
      <span @click="sortBy('title')" :class="{'sortable-column': true, 'sorted': sortKey === 'title'}">{{ $t('KVITTO.NAME') }}
        <i v-if="sortKey === 'title'" :class="sortOrder === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
      </span>
      <span @click="sortBy('display_date')" :class="{'sortable-column': true, 'sorted': sortKey === 'display_date'}">{{ $t('KVITTO.CREATED_AT') }}
        <i v-if="sortKey === 'display_date'" :class="sortOrder === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
      </span>
      <span @click="sortBy('assigned_user_id')" :class="{'sortable-column': true, 'sorted': sortKey === 'assigned_user_id'}">{{ $t('KVITTO.ACCOUNT.ASSIGNED_USER_ID') }}
        <i v-if="sortKey === 'assigned_user_id'" :class="sortOrder === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
      </span>
    </div>

    <!-- Todo Items List -->
    <div v-for="item in filteredAndSortedTodos" :key="item.id" class="todo-item">
      <div class="todo-content">
        <span class="todo-title-date">
          <span>{{ item.title }}</span>
          <small>{{ item.display_date }}</small>
        </span>
        <span class="todo-amount rounded">{{ safe_div_100(item.amount) }} {{ item.currency }}</span>

        <!-- Status Badge -->
        <span class="status-badge rounded" :class="item.status.toLowerCase()">{{ $t(`KVITTO.TODO_STATUSES.${item.status}`) }}</span>

        <b-form-select
          id="status-filter"
          class="mr-sm-2 mx-0 assign-dropdown rounded"
          v-model="item.assigned_user_id"
          :options="assigned_user_options"
        ></b-form-select>

        <!-- Action Buttons -->
        <div class="action-buttons">
          <a class="btn btn-icon btn-light btn-sm mx-1" @click="editItem(item)" v-b-tooltip="{ title: $t('KVITTO.EDIT_TODO_ITEM_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
          <a v-if="!item.kvitto" class="btn btn-icon btn-light btn-sm mx-1" @click="connectItem(item)" v-b-tooltip="{ title: $t('KVITTO.CONNECT_TRANSACTION_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-link color-primary"></i>
            </span>
          </a>
          <a v-if="!item.kvitto" class="btn btn-icon btn-light btn-sm mx-1" @click="uploadFile(item)" v-b-tooltip="{ title: $t('KVITTO.UPLOAD_KVITTO_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-upload color-primary"></i>
            </span>
          </a>
          <a v-if="item.kvitto" class="btn btn-icon btn-light btn-sm mx-1" @click="downloadFile(item)" v-b-tooltip="{ title: $t('KVITTO.DOWNLOAD_KVITTO_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-download color-primary"></i>
            </span>
          </a>
          <a class="btn btn-icon btn-light btn-sm mx-1" @click="deleteItem(item)" v-b-tooltip="{ title: $t('KVITTO.DELETE_KVITTO_TRANSACTION_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-trash color-primary"></i>
            </span>
          </a>
          
        </div>

        <div class="icon-container">
          <fa-icon v-if="item.kvitto" :icon="['fas', 'receipt']" class="icon" v-b-tooltip="{ title: $t('KVITTO.RECEIPT_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }"/>
          <fa-icon v-else :icon="['fas', 'landmark']" class="icon" v-b-tooltip="{ title: $t('KVITTO.TRANSACTION_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }"/>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import KvittoSelectTableModal from '@/view/pages/ml/kvitto/KvittoSelectTableModal.vue';
import AccountTransactionModal from '@/view/pages/ml/kvitto/AccountTransactionModal.vue';
import KvittoEditorModal from '@/view/pages/ml/kvitto/KvittoEditorModal.vue';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


export default {
  components: {
    KvittoSelectTableModal,
    AccountTransactionModal,
    KvittoEditorModal,
  },
  
  mixins: [ toasts ],


  data() {
    return {
      selected_kvitto: null,
      selected_transaction: null,
      assigned_user_options: [],
      selected_item_to_connect: null,
      list_of_todos: [],
      todos: [
        { id: 123, currency: "SEK", title: "ICA BANKEN 02332 BANKEN BANKEN BANKEN", amount: 344, display_date: "2024-07-09 20:00:11", assigned_user_id: 1, kvitto: null, status: 'CLOSED' },
        { id: 124, currency: "SEK", title: "PRESSBYRAN01", amount: 120, display_date: "2024-07-10 11:00:00", assigned_user_id: null, kvitto: { file_id: 34, id: 13, name: 'kvitto_butik.pdf' }, status: 'MAPPED' },
      ],
      filters: {
        created_from: '',
        created_to: '',
        status: '',
        assigned_user_id: null,
        text: ''
      },
      sortKey: '',
      sortOrder: 'asc',
       
    };
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    
    status_options() {
      return [

        { text: this.$t('KVITTO.TODO_STATUSES.TODO'), value: 'TODO' },
        { text: this.$t('KVITTO.TODO_STATUSES.DONE'), value: 'DONE' },
        { text: this.$t('KVITTO.TODO_STATUSES.ERROR'), value: 'ERROR' },
        { text: this.$t('KVITTO.TODO_STATUSES.ACCOUNTING'), value: 'ACCOUNTING' },
        
      ];
      
    },
    filteredAndSortedTodos() {
      let filtered = this.list_of_todos.filter(item => {
        const matchesDate = (!this.filters.created_from || item.display_date >= this.filters.created_from) &&
                            (!this.filters.created_to || item.display_date <= this.filters.created_to);
        const matchesStatus = !this.filters.status || item.status === this.filters.status;
        const matchesUser = !this.filters.assigned_user_id || Number(item.assigned_user_id) === Number(this.filters.assigned_user_id);
        const matchesSearch = item.title.toLowerCase().includes(this.filters.text.toLowerCase());
        return matchesDate && matchesStatus && matchesUser && matchesSearch;
      });
      
      if (this.sortKey) {
        filtered = filtered.sort((a, b) => {
          const result = a[this.sortKey] > b[this.sortKey] ? 1 : -1;
          return this.sortOrder === 'asc' ? result : -result;
        });
      }
      return filtered;
    }
  },

  watch: {
    currentCompanyId() {
      this.load_todo_list();
      this.load_users_for_company(this.currentCompanyId);
    }
  },

  mounted() {
    this.load_todo_list();
    this.load_users_for_company(this.currentCompanyId);
  },
  methods: {
    
    async load_users_for_company(company_id) {
      this.assigned_user_options = [];
      
      const res = await axios.get(`/access/users?company_id=${company_id}`);

      if (res.status === 200) {
        this.assigned_user_options = res.data.map((item) => ({ text: item.email, value: item.user_id }));
      }
    },


    async kvitto_connected(kvitto) {
      if (!this.selected_item_to_connect || !this.selected_item_to_connect.transaction) {
        console.error('selected item transaction not set');
        return;
      }

      const res = await axios.put(`/kvitto/${kvitto.id}/connect/transaction/${this.selected_item_to_connect.transaction.id}`, {});

      if (res.status === 204) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('KVITTO.CONNECTED_OK'));

        this.load_todo_list();
      }
    },

    safe_div_100(amount) {
      if (!amount) {
        return 0;
      }

      const famount = parseFloat(amount+'') / 100;

      return famount;
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
    },
    async editItem(item) {
      if (item.transaction) {
        this.selected_transaction = item.transaction;

        const res = await axios.get(`/account/transaction/${this.selected_transaction.id}`);

        if (res.status === 200) {
          this.selected_transaction = res.data;

          this.$refs['transaction-editor-modal'].show();
        }
        
      }
      else if (item.kvitto) {
        this.selected_kvitto = item.kvitto;

        const res = await axios.get(`/kvitto/${this.selected_kvitto.id}`);

        if (res.status === 200) {
          this.selected_kvitto = res.data;
          this.$refs['kvitto-editor-modal'].show();
        }

      }
    },
    async deleteItem(item) {

      if (item.kvitto) {
        await axios.delete(`/kvitto/${item.kvitto.id}`);
      }

      if (item.transaction) {
        await axios.delete(`/account/transaction/${item.transaction.id}`);
      }
      
      this.load_todo_list();
    },

    has_file(item) {
      const file_id = item.kvitto.attached_file_id ? item.kvitto.attached_file_id : item.kvitto.generated_file_id;
      
      return !!file_id;
    },

    connectItem(item) {
      this.selected_item_to_connect = item;
      this.$refs['kvitto-select-modal'].show();
    },
    uploadFile(item) {
      alert(`Upload for ${item.name}`);
    },
    async downloadFile(item) {
      const file_id = item.kvitto.attached_file_id ? item.kvitto.attached_file_id : item.kvitto.generated_file_id;
      
      if (!file_id) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('KVITTO.NO_FILE_FOUND'));
        return;
      }

      const res = await axios.get(`/file/${file_id}`);

      if (res.status === 200) {
        downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
      }
    },

    async load_todo_list() {
      const res = await axios.get(`/kvitto/todo/company/${this.currentCompanyId}`);

      if (res.status === 200) {
        this.list_of_todos = res.data;
      }
    }
  }
};
</script>

<style lang="css" scoped>



.todo-container {
  max-width: 860px;
  margin: 0 auto;
}
.filter-section, .todo-header, .todo-item {
  display: flex;
  gap: 8px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.todo-header {
  justify-content: space-between;
}
.sortable-column {
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.sorted i {
  display: inline;
}
.todo-item {
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
}
.todo-content {
  display: flex;
  gap: 10px;
}

.todo-title-date {
  width: 200px;
}

.todo-title-date span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 189px;
  font-weight: bold;
  display: block;
}

.todo-amount {
  width: 90px;
  display: flex;
  align-items: center;
}

.status-badge {
  width: 80px;
  display: flex;
  align-items: center;
}

.assign-dropdown {
  width: 146px;
  height: 47px;
  font-weight: bold;
}

.todo-title-date, .todo-amount, .assign-dropdown, .status-badge, .action-buttons {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}
.status-badge {
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.status-badge.skipped {
  background-color: #ffcc00;
  color: #333;
}
.status-badge.none {
  background-color: #ffcc00;
  color: #333;
}
.status-badge.urgent_need_mapping {
  background-color: #ff6a6a;
  color: #333;
}
.status-badge.todo {
  background-color: #ff6a6a;
  color: #333;
}
.status-badge.mapped {
  background-color: #4caf50;
  color: #fff;
}
.status-badge.closed {
  background-color: #4caf50;
  color: #fff;
}

.action-buttons {
  display: flex;
  gap: 4px;
}

.action-buttons .btn {
  margin-top: 3px;
}


.icon-container {
  margin-top: 10px;
  margin-left: 5px;

  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  
  width: 32px;
  height: 32px;
  background-color: #f0f0f0; /* Optional: set a background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 20px; /* Adjust this value to make the icon larger */
}


.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
}


/* CSS Code */
.filter-container {
  display: flex;
  flex-direction: column;
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.filter-row .form-group {
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 0 10px;
}

.filter-row:last-child .form-group {
  flex: 1 1 100%;
}

@media (max-width: 767px) {
  .filter-row .form-group {
    flex: 1 1 100%;
    padding: 0;
  }
}


</style>
