<template>

  <div id="shop-cart-table">
    <div v-if="is_mobile">
      <h4>{{$t('SHOP_ORDER.AMOUNT')}}: {{ total_price }} {{ currency }}</h4>
    </div>
    <div v-else>
      <b-row class="cart-header">
        <b-col cols="6">
          <h4>{{$t('SHOP_ORDER.ARTICLE')}}</h4>
        </b-col>

        <b-col cols="2" class="text-right">
          <h4>{{$t('SHOP_ORDER.COUNT')}}</h4>
        </b-col>

        <b-col cols="2" class="text-right">
          <h4>{{$t('SHOP_ORDER.AMOUNT')}}</h4>
        </b-col>

        <b-col cols="1"></b-col>
      </b-row>

      <template v-for="(item, i) in cart">
        <ShopCartTableRow
          v-bind:key="i"
          :item="item"
          @deleteItemClicked="deleteItemClicked"
          @toastr="toastr"
        />
      </template>

      <b-row class="mt-0" v-if="discount">
        <b-col cols="6">
          <b-row>
            <b-col cols="2"></b-col>
            <b-col cols="10" class="item-descr">
              <h5 style="margin-top: 10px;">{{ discount.campaign_name }}</h5>
              <p>{{ discount.campaign_descr }}</p>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="2">
        </b-col>

        <b-col cols="2" class="amounts-cols text-right">
          <h5 class="item-discount">-{{ discount.total_discount }} {{ currency }}</h5>
        </b-col>

        <b-col cols="1"></b-col>
      </b-row>

      <b-row class="cart-header mt-2">
        <b-col cols="6">
          <h4></h4>
        </b-col>

        <b-col cols="2" class="text-right">
          <h4>{{$t('SHOP_ORDER.TOTAL')}}</h4>
        </b-col>

        <b-col cols="2" class="text-right">
          <h4>{{ total_price }} {{ currency }}</h4>
        </b-col>

        <b-col cols="1"></b-col>
      </b-row>
    </div>

  </div>

</template>

<script>
import ShopCartTableRow from '@/view/pages/members/register/registerwizard/ShopCartTableRow.vue';
import { is_mobile } from '@/core/services/utils';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'shopcart-table',
  props: ['cart', 'total_price', 'total_vat', 'discount'],
  emits: ['deleteItemClicked'],
  mixins: [ toasts ],
  components: {
    ShopCartTableRow
  },
  computed: {
    currency() {
      return (this.cart && this.cart.length > 0) ? this.cart[0].currency : '???';
    },
    is_mobile() {
      return is_mobile();
    }
  },
  methods: {
    deleteItemClicked(shop_item_id) {
      this.$emit('deleteItemClicked', shop_item_id);
    }
  },
  data() {
    return {
      list: []
    };
  }
};
</script>
<style scoped>

  .cart-header h4 {
    text-transform: uppercase;
    padding: 8px 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    color: #6c6c6c;
  }
  .cart-header {
    background-color: #efeff2;
  }
  .item-icon {
    max-width: 64px;
  }
  .item-descr {

  }
  .item-descr h5 {
    margin-top: 10px;
  }
  .item-descr p {
    font-style: italic;
    color: #8a8a8a;
  }
  .item-discount {
    color: #fd397a;
  }
  .table > thead > tr > th:first-child, .table > th:last-child {
    border-radius: 0;
  }
  .amounts-cols h5 {
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 1rem;
  }
</style>
