<template>
  <div class="mt-16">
    <SendoutTemplateComponent />
  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import SendoutTemplateComponent from '@/view/pages/ml/sendout_template/SendoutTemplateComponent.vue';


export default {
  components: {
    SendoutTemplateComponent,
  },

  data() {
    return {
      
    };
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
  },

  watch: {
    currentCompanyId() {
      
    }
  },

  mounted() {
    
  },
  methods: {
    
  }
};
</script>
