<template>
  <div>

      <div class="mb-4">
        <required-fields-info/>
      </div>

      <b-form-group id="group-id" label="ID" label-for="field-id">
        <b-form-input id="field-id" v-model="local_term.id" type="text" disabled />
      </b-form-group>

      <b-form-group id="group-active">
        <div class="d-flex align-items-center">
          <label class="checkbox checkbox-outline checkbox-success">
            <input id="field-active" type="checkbox" name="" v-model="local_term.is_active"/>
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('COMMON.ACTIVE')}}</span >
        </div>
      </b-form-group>

      <b-form-group id="group-mandatory">
        <div class="d-flex align-items-center">
          <label class="checkbox checkbox-outline checkbox-success">
            <input id="field-mandatory" type="checkbox" name="" v-model="local_term.is_mandatory"/>
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('COMMON.MANDATORY')}}</span >
        </div>
      </b-form-group>

      <b-form-group id="group-name">
        <tip-label class="mb-2" for="field-name" asterix>{{$t('COMMON.NAME')}}</tip-label>
          <b-form-input
            id="field-name"
            type="text"
            v-model="local_term.name"
            :state="name_state"
          />
      </b-form-group>

      <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{$t('PAGES.TERMS.TERMS_TEXT_INFO')}}</div>
      </div>

      <b-form-group id="group-descr">
        <tip-label class="mb-2" for="field-descr" asterix>{{$t('PAGES.TERMS.CHECKBOX_TEXT')}}</tip-label>
          <b-form-input
            id="field-descr"
            type="text"
            v-model="local_term.descr"
            :state="descr_state"
          />
      </b-form-group>

      <b-form-group id="group-template">
        <tip-label class="mb-2" asterix>{{$t('PAGES.TERMS.TEXT')}}</tip-label>
        
        <tiptap-vuetify
          data-app
          v-model="local_term.template"
          :extensions="extensions"
        />
        
      </b-form-group>

      <b-row>
        <b-col>
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.SAVE')"
            :spin="true"
            @clicked="save_term"
            @close="$emit('close')"
            ref="saveButton"
          />
        </b-col>
      </b-row>

  </div>
</template>

<script>


import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { validate_state } from '@/core/services/validations.service';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'TermsEdit',
  components: { 
    TiptapVuetify,
    TipLabel, 
    RequiredFieldsInfo, 
    RightModalSaveAndCloseButtons
  },
  mixins: [ toasts ],
  props: {
    term: {
      type: Object,
      default: {},
      required: true
    },
  },
  emits: ['save_term'],
  computed: {
    descr_state() {
      return validate_state({ dirty: this.v$.local_term.descr.$dirty, error: this.v$.local_term.descr.$error });
    },
    name_state() {
      return validate_state({ dirty: this.v$.local_term.name.$dirty, error: this.v$.local_term.name.$error });
    },
  },

  watch: {
    term: {
      deep: true,
      handler(val) {
        this.local_term = { ...val };
      }
    },
  },

  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_term: {
        name: { required },
        descr: { required },
        template: { required }
      }
    }
  },

  methods: {
    valid_editor_style(state) {
      const result = state.dirty
        ? !state.error
        : null;
      if (result === true) { return 'editor-valid'; }
      if (result === false) { return 'editor-invalid'; }
    },

    async save_term() {
      
      const valid = await this.v$.$validate();

      this.$refs['saveButton'].stop();

      if (!valid) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.INVALID_FORM'));
        this.v$.$touch();
        return
      }

      this.$emit('save_term', this.local_term);
    },

  },

  mounted() {
    if (this.term) {
      this.local_term = { ...this.term };
    }
  },

  data() {
    return {
      local_term: {},
      extensions: [
        
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  }
};

</script>

<style scoped>
.editor-valid { border-color: var(--success) !important; }
.editor-invalid  { border-color: var(--danger) !important; }
</style>
