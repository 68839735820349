<template>
  <div class="m-overlay ml-modal" v-if="visible">
    <div class="m-container" :style="calculated_style" >
      <button class="m-close" @click="$emit('close')">×</button>
      <div class="m-content">
        <slot>Default content goes here.</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemlistModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    calculated_style() {

      const style = {
        'background-color': 'white',
        'width': '320px',
      }

      if (this.theme === 'dark') {
        style['background-color'] = 'rgb(33, 34, 45)';
      }

      switch (this.size) {
        case 'sm':
          style['width'] = '320px';
          break;
        case 'lg':
          style['width'] = '640px';
          break;
        case 'xl':
          style['width'] = '1024px';
          break;
        case 'xxl':
          style['width'] = '1360px';
          break;
        case 'full':
          style['width'] = '100%';
          break;
      }

      return style;
    }
  }
}
</script>

<style scoped>
.m-overlay {
  position: fixed;
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  /* Remove or override align-items: center */
  align-items: flex-start; 
  justify-content: center;
  z-index: 999;
  overflow-y: auto; /* Important for long content */
}

.m-container {
  margin-top: 32px;
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 1em;
  box-shadow: 0 4px 20px rgba(0,0,0,0.2);
}

.m-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  line-height: 1;
}

.m-content {
  margin-top: 2em;
}
</style>
