<template>
  <div>

    <MemberExitConfirmPopup
      ref="member-exit-confirm-popup"
      :member_id="selected_member_id"
      @exit_success="exit_success"
    />

    <Confirm
      :title="$t('PAGES.MEMBER_REGISTRATIONS.CONFIRM_REJECT_EXIT')"
      :message="$t('PAGES.MEMBER_REGISTRATIONS.CONFIRM_REJECT_EXIT_MESSAGE')"
      ref="confirm-reject"
      @cancel="cancel_reject"
      @confirm="confirm_reject"
    />


    <b-table
      id="member-registrations-table"
      class="table-striped mh-100"
      :fields="headers"
      :items="items"
      head-variant="light"
      sticky-header
      responsive
      striped
      hover
    >

      <template #cell(actions)="row">

        <div class="justify-content-end d-flex">

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="reject_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-times-circle color-primary"></i>
            </span>
          </a>

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="confirm_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-check-circle color-primary"></i>
            </span>
          </a>

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>

        </div>
      </template>

    </b-table>

    <div v-if="items.length === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import Confirm from '@/view/components/Confirm.vue';
import MemberExitConfirmPopup from './MemberExitConfirmPopup.vue';

export default {
  name: "MemberExitRequestTable",
  mixins: [ toasts ],
  props: ["items","show_create"],
  emits: ['member_status_changed', 'member_clicked', 'skv_job_finished'],
  components: {
    Confirm,
    MemberExitConfirmPopup
  },
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    exit_success(member_id) {
      this.$emit('exit_success', member_id)
    },

    reject_member_clicked(member_id) {
      this.selected_member_id = member_id;
      this.$refs['confirm-reject'].show();
    },

    confirm_member_clicked(member_id) {
      this.selected_member_id = member_id;
      this.$refs['member-exit-confirm-popup'].show();
    },

    cancel_reject() {},

    async confirm_reject() {
      await this.put_member_status(this.selected_member_id, 'MEMBER');

      this.$emit('status_updated', this.selected_member_id, 'MEMBER');
    },

    
    select_member_clicked(member_id) {
      this.$emit('member_clicked', member_id);
    },

    async put_member_status(member_id, status) {
      try {
        const res = await axios.put(`/member/status/${member_id}`, { status: status });

        if (res.status === 200) {
          if (status === 'MEMBER') {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.EXIT_REJECTED_MESSAGE'));
          }
          
        }
      }
      catch (err) {
        console.error('put_member_status error', err)
      }


    }

  },
  data() {
    return {

      selected_member_id: null,

      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [

        {
          key: 'public_id',
          label: this.$t('MEMBER.PUBLIC_ID_SHORT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'cname',
          label: this.$t('MEMBER.NAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle',
          formatter: (_, __, item) => {
            if (item.cname) {
              return item.cname;
            }

            return (item.firstname || '').substring(0,1) + '. ' + item.lastname;
          }
        },

        {
          key: 'actions',
          label: ' ',
          thClass: 'w-120px text-right pr-12 align-middle',
        },

      ],

    };
  }
};
</script>
