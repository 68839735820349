<template>
  <div class="ml-date-time-picker">
    <span v-if="label" class="date-time-picker_label">{{ label }}</span>
    <div class="d-flex align-center">
      <memlist-date-picker
        v-model="local_date"
        class="mr-4"
        :disabled="disabled"
      />
      <memlist-time-picker
        v-model="local_time"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'MemlistDateTimePicker',
  props: ['value', 'disabled', 'label'],
  emits: ['input'],
  watch: {
    value(new_val) {
      this.assign_local_values(new_val)
    },
    local_date(new_val) {
      const time = this.local_time
        ? this.local_time
        : dayjs().format('HH:mm');

      this.$emit('input', `${new_val} ${time}`)
    },
    local_time(new_val) {
      this.$emit('input', `${this.local_date} ${new_val}`)
    },
  },
  data() {
    return {
      local_time: null,
      local_date: null,
    }
  },
  methods: {
    get_date_and_time(datetime) {
      if (!datetime) {
        console.error('Invalid datetime was provided')
        return {
          date: null,
          time: null,
        }
      }
      const date_time = datetime.split(' ');

      if (date_time.length !== 2) {
        console.error('Invalid datetime format');
        return {
          date: null,
          time: null,
        }
      }

      return {
        date: date_time[0],
        time: date_time[1],
      }
    },
    assign_local_values(datetime) {
      const { date, time } = this.get_date_and_time(datetime);
      this.local_date = date;
      this.local_time = time;
    }
  },
  mounted() {
    this.assign_local_values(this.value)
  },
}
</script>

<style scoped>
  :deep .date-time-picker_label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
</style>
