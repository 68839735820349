import { render, staticRenderFns } from "./ExportSalesButton.vue?vue&type=template&id=27a9cefe&scoped=true"
import script from "./ExportSalesButton.vue?vue&type=script&lang=js"
export * from "./ExportSalesButton.vue?vue&type=script&lang=js"
import style0 from "./ExportSalesButton.vue?vue&type=style&index=0&id=27a9cefe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a9cefe",
  null
  
)

export default component.exports