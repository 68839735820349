import { render, staticRenderFns } from "./TestRegisterDonationCustomer.vue?vue&type=template&id=5f984d0c&scoped=true"
import script from "./TestRegisterDonationCustomer.vue?vue&type=script&lang=js"
export * from "./TestRegisterDonationCustomer.vue?vue&type=script&lang=js"
import style0 from "./TestRegisterDonationCustomer.vue?vue&type=style&index=0&id=5f984d0c&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f984d0c",
  null
  
)

export default component.exports