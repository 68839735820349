<template>
  <div id="renew-guide-page">
    <RenewGuideWizard />
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import RenewGuideWizard from '@/view/pages/ml/renew/renew_guide_wizard/RenewGuideWizard.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'RenewGuidePage',
  mixins: [ toasts ],
  components: {
    RenewGuideWizard
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  watch: {

  },
  methods: {
    
  }
};
</script>
<style scoped>
.v-window {
  overflow: visible !important;
}
</style>
