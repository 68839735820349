<template>
  <b-modal ref="test-swish-payment-modal" hide-footer>

    <b-form>

      <b-row>
        <b-col cols="12">
          <div class="radio-list">

            <label class="radio radio-outline radio-success mb-4">
              <input type="radio" v-model="entry_option" value="PAYER_ALIAS" name="entry_option" :disabled="!!order" />
              <span></span>
              {{ $t('SWISH_MERCHANT.USE_SWISH_NUMBER') }}
            </label>

            <label class="radio radio-outline radio-success mb-4">
              <input type="radio" v-model="entry_option" value="QR" name="entry_option" :disabled="!!order"/>
              <span></span>
              {{ $t('SWISH_MERCHANT.USE_QR_CODE') }}
            </label>

            <label class="radio radio-outline radio-success mb-4">
              <input type="radio" v-model="entry_option" value="LOCAL_APP" name="entry_option" :disabled="!!order"/>
              <span></span>
              {{ $t('SWISH_MERCHANT.USE_LOCAL_APP') }}
            </label>

          </div>
        </b-col>
      </b-row>

      <b-row v-if="entry_option === 'PAYER_ALIAS'">
        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('SWISH_MERCHANT.PAYER_ALIAS')">

              <b-form-input
                v-model="local_item.payer_alias"
                :disabled="!!order"
              />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('SWISH_MERCHANT.AMOUNT')">

              <b-form-input
                v-model="local_item.amount"
                :disabled="!!order"
              />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('SWISH_MERCHANT.MESSAGE')">

              <b-form-input
                v-model="local_item.message"
                :disabled="!!order"
              />

          </b-form-group>
        </b-col>
      </b-row>

      <p style="margin-top: 42px;"></p>

      <b-row>
        <b-col>

          <RightModalSaveAndCloseButtons
            class="mb-4"
            :text="$t('COMMON.CREATE')"
            :spin="true"
            :disabled="!!order"
            @clicked="on_submit"
            @close="$refs['test-swish-payment-modal'].hide()"
            ref="saveButton"
          />
        </b-col>
      </b-row>

      <b-row v-if="entry_option === 'LOCAL_APP'">
        <b-col align-h="center">
          App string: {{ swish_app_string }}
          <button style="font-size: 22px; min-height: 35px; min-width: 280px;" class="btn btn-success btn-lg mt-4" @click.prevent="open_swish_clicked()">{{$t('PUBLIC.PAYMENT.OPEN_SWISH')}}</button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <PaymentStatusComponent
            class="mx-auto"
            v-if="order"
            :order="order"
            @done="payment_done"
          />
        </b-col>

      </b-row>

      <b-row v-if="order && local_item.qr" style="text-align: center;">
        <b-col>
          <b-img :src="qr_src" style="max-width: 300px;" class="mx-auto"/>
        </b-col>
      </b-row>

    </b-form>


  </b-modal>
</template>

<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import PaymentStatusComponent from '@/view/pages/members/payment/PaymentStatusComponent.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'TestSwishPaymentModal',

  props: ['merchant'],
  emits: ['created', 'updated'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    PaymentStatusComponent
  },

  computed: {

    qr_src() {
      return `swish-qr/${this.order.shop_order_id}`
    }
  },

  data() {
    return {
      entry_option: 'PAYER_ALIAS',
      local_item: {},

      order: null,

      interval_id: null
    };
  },

  async mounted() {

    this.local_item = {
      qr: false
    }

  },

  watch: {
    entry_option: {
      deep: true,
      handler(val) {
        this.local_item.qr = this.entry_option === 'QR';
      }
    }
  },

  methods: {

    payment_done(order) {
      if (order && order.redirect_url) {
        location.href = order.redirect_url;
      }
    },

    get_callback_url() {
      if (!this.order || !this.order.swish_token) {
        console.log('callback url, this.order', this.order)
        return null;
      }

      return get_domain_url() + `/return-all-payment/${this.order.token}/${this.order.shop_order_id}`
    },

    async open_swish_clicked() {

      await this.create_swish_test();

      this.$refs['saveButton'].stop();

      if (this.order) {
        this.open_swish_local_app();
      }
    },

    get_swish_app_string() {
      if (!this.order || !this.order.swish_token) {
        console.log('app string, this.order', this.order)
        return null;
      }

      return `swish://paymentrequest?token=${this.order.swish_token}&callbackurl=${encodeURIComponent(this.get_callback_url())}`;
    },

    async open_swish_local_app() {

      while (this.get_callback_url() === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      while (this.get_swish_app_string() === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      this.$nextTick(() => {

        const swish_app = this.get_swish_app_string();

        console.log('swish url', swish_app);

        window.location = swish_app;
      });


    },

    async on_submit() {
      await this.create_swish_test();

      this.$refs['saveButton'].stop();
    },

    async create_swish_test() {
      try {
        const res = await axios.post(`/swish/merchant/test/${this.merchant.id}`, this.local_item);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SWISH_MERCHANT.TEST_CREATED'));

          this.order = res.data;

          this.start_polling();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        console.error('create_swish_test error', err);
      }

      return true;
    },

    start_polling() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.interval_id = setInterval(()=>{
        this.poll_shop_order();
      }, 1000);
    },

    async get_shop_order(shop_order_id) {
      const res = await axios.get(`/shop_order/${shop_order_id}`);

      if (res.status === 200) {
        return res.data;
      }

      return null;
    },

    async poll_shop_order() {
      try {
        const item = await this.get_shop_order(this.order.shop_order_id);

        if (item) {

          this.order =  { ...item };

        }
      }
      catch (err) {
        console.error('poll_shop_order error', err);
      }
    },

    stop_poll_shop_order() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }
    },

    swish_merchant_created(item) {
      this.$emit('created', item);
    },

    swish_merchant_updated(item) {
      this.$emit('updated', item);
    },

    show() {
      this.$refs['test-swish-payment-modal'].show();
    },

    close() {
      this.$refs['test-swish-payment-modal'].hide();
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
