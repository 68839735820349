import Cookies from 'js-cookie';

// action types
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const SET_ACCESS = 'setAccess';
export const SET_IS_SUPERADMIN = 'setIsSuperAdmin';
export const SET_IS_THS = 'setIsTHS';
export const SET_INIT_COMPLETE = 'setInitComplete';
export const SET_PAGES = 'setPages';
export const SET_ACCESS_HIERARCHY = 'setAccessHierarchy';
export const SET_ULOGIN_LINK = 'setUserLoginLink';
export const SET_USER_REFRESH_TOKEN = 'setUserRefreshToken';
export const SET_USER_ACCESS_TOKEN = 'setUserAccessToken';
export const SET_NEED_TERMS = 'setNeedTerms';
export const SET_UNLOCKS = 'setUnlocks';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const UPDATE_ACCESS = 'updateAccess';
export const UPDATE_IS_SUPERADMIN = 'updateIsSuperAdmin';
export const UPDATE_IS_THS = 'updateIsTHS';
export const UPDATE_INIT_COMPLETE = 'updateInitComplete';
export const UPDATE_PAGES = 'updatePages';
export const UPDATE_ACCESS_HIERARCHY = 'updateAccessHierarchy';
export const UPDATE_ULOGIN_LINK = 'updateUserLoginLink';
export const UPDATE_USER_REFRESH_TOKEN = 'updateUserRefreshToken';
export const UPDATE_USER_ACCESS_TOKEN = 'updateUserAccessToken';
export const UPDATE_NEED_TERMS = 'updateNeedTerms';
export const UPDATE_UNLOCKS = 'updateUnlocks';

import { jwtDecode } from "jwt-decode";


function token_expires_at(token) {
  try {
    if (token === null || token === undefined) return 0;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    
    const expirationTime = decodedToken.exp;

    return expirationTime;
  }
  catch (err) {
    console.error('token_expires_at error', err);
  }
  
  return 0;
}


const userc = Cookies.get('user');
const is_superadminc = Cookies.get('is_superadmin');
const is_thsc = Cookies.get('is_ths');
const access_hierarchy = localStorage.getItem('access_hierarchy');
const acl = localStorage.getItem('acl');
const need_terms = localStorage.getItem('need_terms');
let ulogin_link = Cookies.get('uloginlink');
const user_refresh_token = Cookies.get('user_refresh_token');
const user_access_token = Cookies.get('user_access_token');

if (ulogin_link === null || ulogin_link === undefined) {
  ulogin_link = 'ml-main-login';
}

const state = {
  user_access_token: 'undefined' === typeof user_access_token || user_access_token === undefined || user_access_token === 'undefined' ? null : Cookies.get('user_access_token'),
  user_refresh_token: ('undefined' === typeof user_refresh_token || user_refresh_token === undefined || user_refresh_token === 'undefined') ? null : Cookies.get('user_refresh_token'),
  ulogin_link: ulogin_link,
  user: 'undefined' === typeof userc ? null : JSON.parse(Cookies.get('user')),
  acl: ('undefined' === typeof acl || acl === undefined || acl === 'undefined') ? null : JSON.parse(acl),
  is_superadmin: ('undefined' === typeof is_superadminc || is_superadminc === undefined || is_superadminc === 'undefined') ? null : Cookies.get('is_superadmin'),
  is_ths: is_thsc === true || is_thsc === 'true',
  initComplete: false,
  pages: {},
  access_hierarchy: ('undefined' === typeof access_hierarchy || access_hierarchy === undefined || access_hierarchy === 'undefined' || access_hierarchy === '[object Object]') ? null : JSON.parse(access_hierarchy),
  need_terms: need_terms === true || need_terms === 'true',
  unlocks: {
    template_sync: false
  }
};

const getters = {
  user_access_token: state => state.user_access_token,
  user_refresh_token: state => state.user_refresh_token,
  ulogin_link:      state => state.ulogin_link,
  currentUser:      state => state.user, // from SET_AUTH
  currentAccess:    state => state.acl,
  isSuperAdmin:     state => state.is_superadmin,
  isTHS:            state => state.is_ths,
  // check token expiration
  isAuthenticated:  state => {
    const user_refresh_token_expire_at = token_expires_at(state.user_refresh_token);

    const d1= new Date();
    const d2= new Date(user_refresh_token_expire_at * 1000);

    console.log('expiration check ', d1, d2);

    return d1 < d2;
  },
  initComplete:     state => state.initComplete,
  pages:            state => state.pages,
  access_hierarchy: state => state.access_hierarchy,
  need_terms:       state => state.need_terms,
  unlocks:          state => state.unlocks
};


const actions = {
  // Add isAuthenticated check as an action
  isAuthenticated({ state }) {
    const user_refresh_token_expire_at = token_expires_at(state.user_refresh_token);
    const currentTime = new Date();

    if (!state.user_refresh_token) {
      return false;
    }

    return currentTime < new Date(user_refresh_token_expire_at * 1000);
  },
  [SET_UNLOCKS](context, data){
    context.commit(UPDATE_UNLOCKS, data);
  },
  [SET_NEED_TERMS](context, data){
    context.commit(UPDATE_NEED_TERMS, data);
  },
  [SET_USER_ACCESS_TOKEN](context, data){
    context.commit(UPDATE_USER_ACCESS_TOKEN, data);
  },
  [SET_USER_REFRESH_TOKEN](context, data) {
    context.commit(UPDATE_USER_REFRESH_TOKEN, data);
  },
  [SET_ULOGIN_LINK](context, data) {
    context.commit(UPDATE_ULOGIN_LINK, data);
  },
  [SET_ACCESS_HIERARCHY](context, data) {
    context.commit(UPDATE_ACCESS_HIERARCHY, data);
  },

  [SET_PAGES](context, data) {
    context.commit(UPDATE_PAGES, data);
  },
  [SET_IS_SUPERADMIN](context, data) {
    context.commit(UPDATE_IS_SUPERADMIN, data);
  },
  [SET_INIT_COMPLETE](context, data) {
    context.commit(UPDATE_INIT_COMPLETE, data);
  },
  [SET_IS_THS](context, data) {
    context.commit(UPDATE_IS_THS, data);
  },
  [SET_ACCESS](context, data) {
    context.commit(UPDATE_ACCESS, data);
  },
  [LOGIN](context, data) {
    context.commit(SET_AUTH, data);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  }
};

const mutations = {
  [UPDATE_UNLOCKS](state, data) {
    state.unlocks = data;
  },
  
  [UPDATE_NEED_TERMS](state, data) {
    state.need_terms = data;

    localStorage.setItem('need_terms', data);
  },
  
  [UPDATE_USER_ACCESS_TOKEN](state, data) {
    state.user_access_token = data;

    const user_token_expire_at = token_expires_at(state.user_access_token);

    Cookies.set('user_access_token', state.user_access_token, { expires: new Date(user_token_expire_at * 1000) });
  },
  [UPDATE_USER_REFRESH_TOKEN](state, data) {
    state.user_refresh_token = data;

    const user_refresh_token_expire_at = token_expires_at(state.user_refresh_token);

    Cookies.set('user_refresh_token', state.user_refresh_token, { expires: new Date(user_refresh_token_expire_at * 1000) });
  },
  [UPDATE_ULOGIN_LINK](state, data) {
    state.ulogin_link = data;
    Cookies.set('uloginlink', state.ulogin_link)
  },
  [UPDATE_ACCESS_HIERARCHY](state, data) {
    state.access_hierarchy = data;

    localStorage.setItem('access_hierarchy', JSON.stringify(data));
  },
  [UPDATE_PAGES](state, data) {
    state.pages = data;
  },
  [UPDATE_IS_SUPERADMIN](state, data) {
    state.is_superadmin = data;

    Cookies.set('is_superadmin', data);
  },
  [UPDATE_INIT_COMPLETE](state, data) {
    state.initComplete = data;

    Cookies.set('initComplete', data);
  },
  [UPDATE_IS_THS](state, data) {
    state.is_ths = data;

    Cookies.set('is_ths', data);
  },
  [UPDATE_ACCESS](state, data) {
    state.acl = data;

    localStorage.setItem('acl', JSON.stringify(data));
  },
  [SET_AUTH](state, data) {
    state.user = data.user;
    state.user_access_token = data.token;
    state.user_refresh_token = data.refresh_token;

    // set cookie expire based on token expiration
    
    const user_token_expire_at = token_expires_at(state.user_access_token);
    const user_refresh_token_expire_at = token_expires_at(state.user_refresh_token);

    Cookies.set('user', JSON.stringify(data.user));
    
    Cookies.set('user_access_token', state.user_access_token, { expires: new Date(user_token_expire_at * 1000) });
    Cookies.set('user_refresh_token', state.user_refresh_token, { expires: new Date(user_refresh_token_expire_at * 1000) });

  },
  [PURGE_AUTH](state) {
    state.user = null;
    localStorage.removeItem('acl');
    Cookies.remove('access_hierarchy');
    Cookies.remove('acl');
    //Cookies.remove('sid');
    Cookies.remove('is_superadmin');
    Cookies.remove('is_ths');
    Cookies.remove('user_id');
    Cookies.remove('user');
    Cookies.remove('token');
    Cookies.remove('company_id');
    Cookies.remove('period_id');
    Cookies.remove('user_refresh_token');
    Cookies.remove('user_access_token');
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
