<template>
  <div class="" id="page-sales">

    <SalesSelectProspects 
      ref="select-sales-prospects"
      :prospects="suggested_prospects"
      @randomize="randomize_prospects"
      @websites_found="websites_found"
      @contacts_found="contacts_found"
      @updated="updated_sale"
      @clicked_prospect="clicked_prospect"
    />

    <SalesEditorModal 
      ref="edit-sales"
      :sale_id="selected_sale_id"
      :current_call_id="call_id"
      @created="created"
      @updated="updated_sale"
      @cancelled="cancelled"
      
    />

    <SalesTable 
      ref="sales-table"
      @on_select_item="on_select_item"
      @on_delete_item="on_delete_item"
      @create_clicked="create_clicked"
      @next_prospect="get_next_prospect"
      @randomize="randomize_prospects"
    />

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>


<script>

import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import axios from 'axios';
import SalesTable from '@/view/pages/ml/ths/sales/SalesTable.vue';
import SalesEditorModal from '@/view/pages/ml/ths/sales/SalesEditorModal.vue';
import SalesSelectProspects from '@/view/pages/ml/ths/sales/SalesSelectProspects.vue';


export default {
  name: 'SalesPage',
  components: {
    SalesTable,
    SalesEditorModal,
    SalesSelectProspects
  },
  computed: {
    ...mapGetters(['isTHS']),
  },
  mixins: [ toasts ],
  mounted() {

    const psale_id = this.$route.params.sale_id;

    if (psale_id) {
      this.selected_sale_id = psale_id;
      this.$nextTick(() => {
        this.$refs['edit-sales'].show();
      });

      return;
    }

    this.get_sales();

    const call_id = localStorage.getItem('call_id');
    const sale_id = localStorage.getItem('sale_id');
    const call_status = localStorage.getItem('call_status');

    if (sale_id && call_id) {
      this.selected_sale_id = sale_id;
      this.current_call_id = call_id;

      this.$nextTick(() => {
        this.$refs['edit-sales'].show();
      });
    }

  },
  data() {
    return {
      selected_sale_id: null,
      current_call_id: null,
      items: [],
      suggested_prospects: [
        
      ]
    };
  },
  watch: {

  },
  methods: {

    clicked_prospect(prospect) {
      this.on_select_item(prospect.id);
    },

    updated_sale(sale, notify) {
      const index = this.suggested_prospects.findIndex(item => item.id === sale.id);

      if (index >= 0) {
        this.suggested_prospects[index] = sale;

        this.suggested_prospects = [...this.suggested_prospects];
      }

      if (notify) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.UPDATED'));
      }
    },

    websites_found(id, websites) {
      const sale = this.suggested_prospects.find(item => item.id === id);

      if (sale) {
        sale.websites = websites;
        this.suggested_prospects = [...this.suggested_prospects];
      }
    },

    contacts_found(id, updated_sale) {
      const index = this.suggested_prospects.findIndex(item => item.id === id);

      if (index >= 0) {
        this.suggested_prospects[index] = updated_sale;

        this.suggested_prospects = [...this.suggested_prospects];
      }
    },

    async randomize_prospects() {
      this.suggested_prospects = await this.get_suggested_prospects();
      this.suggested_prospects = [ ...this.suggested_prospects ];

      this.$refs['select-sales-prospects'].show();
    },

    cancelled() {
      this.selected_sale_id = null;
      this.current_call_id = null;

      localStorage.removeItem('call_id');
      localStorage.removeItem('sale_id');
      localStorage.removeItem('call_status');

      this.$refs['sales-table'].refresh();
    },

    /// get a list of suggested prospects that the salesperson can take
    async get_suggested_prospects() {
      const type = localStorage.getItem('sales_company_type_selected') || 'IDEELL';

      const res = await axios.get(`/sales/random?type=${type}&count=4`);

      if (res.status === 200) {

        return res.data;
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_GET_RANDOM'));
      }

      return [];
    },

    async get_next_prospect() {

      const type = localStorage.getItem('sales_company_type_selected') || 'IDEELL';

      const res = await axios.get(`/sales/random?type=${type}&count=1`);

      if (res.status === 200) {

        const sale = res.data[0];

        const r2 = await axios.post(`/sales/assign/${sale.id}`);

        if (r2.status === 200) {
          this.selected_sale_id = sale.id;
          this.current_call_id = null;

          localStorage.setItem('call_id', null);
          localStorage.setItem('sale_id', this.selected_sale_id);
          localStorage.setItem('call_status', null);

          this.$refs['edit-sales'].show();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_GET_RANDOM'));
        }
        
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_GET_RANDOM'));
      }

    },


    created(item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.CREATED'));

      this.$refs['sales-table'].refresh();
    },
    
    create_clicked() {
      this.selected_sale_id = null;
      
      this.$nextTick(()=>{
        this.$refs['edit-sales'].show();
      });
    },
    on_select_item(id) {
      this.selected_sale_id = id;
      
      this.$nextTick(()=>{
        this.$refs['edit-sales'].show();
      });
      
    },

    async on_delete_item(id) {
      const res = await axios.delete(`/sales/${id}`);

      if (res.status == 204) {
        this.$refs['sales-table'].refresh();
        this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.DELETED'));
      }
    },

    async get_sales() {
      const loader = this.$loading.show();

      try {
        const res = await axios.get(`/sales`);
      
        if (res.status === 204) {
          this.items = res.data;
        }
      }
      catch (err) {
        console.error('get_sales error', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_LIST'));
      }

      loader && loader.hide();
    },

  }
};
</script>
