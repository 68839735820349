<template>
  <div class="px-5" id="page-edit-grant">
    <b-card>
      <SuccessPopup
        :header="success_header_message"
        :text="success_text_message"
      />

      <Confirm
        :title="$t('COMMON.CONFIRM')"
        :message="$t('GRANT.CONFIRM_PUBLISH')"
        ref="confirmGrant"
        type="success"
        @confirm="onPublishGrantConfirm"
      />

        <div class="scroll-to-container"></div>

        <b-form class="mt-8" @submit.prevent="saveGrant">
            <div v-if="form.status === 'DRAFT' && form.todo_id" class="mb-8">
              <b-alert show variant="warning" >{{$t('GRANT.NOT_PUBLISHED_WARNING')}}</b-alert>
              <b-button type="button" variant="primary" @click="showPublishConfirmModal">{{$t('GRANT.SEND_APPLICATION')}}</b-button>
            </div>

            <b-form-group id="input-group-todo_id" label="ID" label-for="input-todo_id">
              <b-form-input
                id="input-todo_id"
                v-model="form.todo_id"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-total_sum" label="Totalt bidragssutrymme" label-for="input-total_sum">
              <b-form-input
                id="input-total_sum"
                v-model="total_sum"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>


            <b-form-group id="input-group-num_participants" :label="$t('GRANT.NUM_PARTICIPANTS')" label-for="input-num_participants">
              <b-form-input
                id="input-num_participants"
                v-model="form.num_participants"
                type="text"
              ></b-form-input>
            </b-form-group>


            <b-form-group id="input-group-1" :label="$t('GRANT.NAME')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="form.name"
                type="text"
                :placeholder="$t('GRANT.NAME_PLACEHOLDER')"
                :disabled="form.status !== 'DRAFT'"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="descr-group" :label="$t('GRANT.DESCRIPTION')" label-for="descr">
              <b-form-textarea
                v-model="form.descr"
                id="input-prop-descr"
                size="sm"
                :placeholder="$t('GRANT.DESCRIPTION_PLACEHOLDER')"
                rows="8"
                :disabled="form.status !== 'DRAFT'"
                >
              </b-form-textarea>
            </b-form-group>

            <b-form-group id="input-group-from_datetime" :label="$t('GRANT.FROM_DATETIME')" label-for="input-from_datetime">
              <memlist-date-picker
                v-model="form.from_datetime"
                class="mb-8"
                :placeholder="$t('GRANT.STARTTIME')"
                :disabled="form.status !== 'DRAFT'"
              />
            </b-form-group>


            <b-form-group id="input-group-to_datetime" :label="$t('GRANT.TO_DATETIME')" label-for="input-to_datetime">
              <memlist-date-picker
                v-model="form.to_datetime"
                class="mb-8"
                :placeholder="$t('GRANT.ENDTIME')"
                :disabled="form.status !== 'DRAFT'"
              />
            </b-form-group>


            <b-card :title="$t('GRANT.BUDGET')" class="mb-8">
              <div v-if="form.status === 'DRAFT' && amount_left_in_application > 0" class="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">{{ $t('GRANT.AMOUNT_LEFT_IN_APPLICATION', { amount: formatsek(amount_left_in_application) } ) }}</div>
              </div>

              <div v-if="form.status === 'DRAFT' && amount_left_in_application === 0" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">{{ $t('GRANT.AMOUNT_LEFT_IN_APPLICATION', { amount: formatsek(amount_left_in_application) } ) }}</div>
              </div>

              <GrantBudgetTable
                title="Utgifter"
                :isExpense="true"
                :items="form.budget"
                :showNewButton="form.status === 'DRAFT'"
                :isDisabled="form.status !== 'DRAFT'"
                @onRowChanged="onBudgetRowChanged"
                @createRowClicked="createBudgetRowClicked"
                @delete_row_clicked="deleteBudgetRowClicked"
              />

              <GrantBudgetTable
                title="Intäkter"
                :isExpense="false"
                :items="form.income"
                :showNewButton="form.status === 'DRAFT'"
                :isDisabled="form.status !== 'DRAFT'"
                @onRowChanged="onIncomeRowChanged"
                @createRowClicked="createIncomeRowClicked"
                @delete_row_clicked="deleteIncomeRowClicked"
              />

              <p v-if="diff_sum !== 0" style="color: red; margin-bottom: 16px;">{{$t('GRANT.INCORRECT_BALANCE')}} {{ diff_sum }}kr
              </p>

              <b-form-group id="input-group-2" :label="$t('GRANT.SUM_APPLICATION')" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.sum_application"
                  type="number"
                  placeholder="0"
                  :disabled="true"
                ></b-form-input>
            </b-form-group>

            </b-card>

            <b-button
              class="mt-4"
              type="submit"
              variant="primary"
              :disabled="form.status !== 'DRAFT'"
              >{{ $t('COMMON.SAVE') }}</b-button
            >
        </b-form>
      </b-card>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

.bg-white {
  background-color: white;
}
.collapse_button {
  width: 80px;
}

.b-table-sticky-header{
  overflow-y: hidden;
  max-height: unset;
}

</style>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';

import GrantBudgetTable from '@/view/pages/ml/grant/GrantBudgetTable.vue';

import { format_sek } from '@/core/services/currency';
import SuccessPopup from '@/view/components/SuccessPopup.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'grant-application-form',
  components: {
    Confirm,
    GrantBudgetTable,
    SuccessPopup
  },
  emits: ['onGrantCreated', 'onGrantUpdated', 'onGrantPublished'],
  computed: {
    ...mapGetters(['companies', 'currentCompanyId', 'currentPeriodId', 'sid'])
  },
  mixins: [ toasts ],
  mounted() {

  },
  data() {
    return {
      success_header_message: null,
      success_text_message: null,
      diff_sum: 0,
      total_sum: 0, // the grant total the company is allowed per year, grantSettings.amount
      previously_granted_sum: 0, // total of previously granted
      amount_left_for_period: 0, // total left of period
      amount_left_in_application: 0, // how much is left in the application
      previusly_granted_for_application: 0, // this is the previusly granted amount for application (0 if this is a new application)
      grantSettings: { amount: 0},
      form: {
        income: [],
        sum_income: 0,
        sum: 0,
        budget: [],
        name: '',
        descr: '',
        from_datetime: dayjs().format('YYYY-MM-DD'),
        num_participants: 0
      }
    };
  },
  watch: {
  },
  methods: {
    formatsek(sum) {
      return format_sek(sum);
    },
    async resetForm() {
      await this.loadCompany();
      this.form = {
        budget: [],
        income: [],
        sum_income: 0,
        name: '',
        descr: '',
        from_datetime: dayjs().format('YYYY-MM-DD'),
        company_id: this.currentCompanyId,
        creator_company_id: this.currentCompanyId,
        type: 'GRANT',
        status: 'DRAFT',
        sum: 0
      },
      this.previusly_granted_for_application = 0;
      this.amount_left_in_application = this.amount_left_for_period;
    },

    async setForm(todo) {
      await this.loadCompany();
      this.form = todo;
      this.previusly_granted_for_application = this.form.sum_application;
      this.amount_left_in_application = this.amount_left_for_period + this.previusly_granted_for_application;
    },

    showPublishConfirmModal() {
      this.$refs['confirmGrant'].show();
    },

    onPublishGrantConfirm() {
      this.$emit('onGrantPublished', this.form);
    },


    onBudgetRowChanged(row) {
      const index = this.form.budget.findIndex(item => item.id === row.id);

      this.form.budget[index] = row;
      // Vue3 compatability for Vue.set
      this.form = [...this.form];

      this.calcBudgetSums();
    },

    onIncomeRowChanged(row) {
      const index = this.form.income.findIndex(item => item.id === row.id);

      this.form.income[index] = row;
      // Vue3 compatability for Vue.set
      this.form = [...this.form];

      this.calcBudgetSums();
    },

    createBudgetRowClicked() {
      let max_id = 1;

      for (const b of this.form.budget) {
        if (max_id < b.id) {
          max_id = b.id;
        }
      }

      this.form.budget.push({ id: max_id+1, name: '', amount: 0, apply: false });
    },

    deleteBudgetRowClicked(id) {
      this.form.budget = this.form.budget.filter(item => item.id !== id);
    },

    createIncomeRowClicked() {
      let max_id = 1;

      for (const b of this.form.income) {
        if (max_id < b.id) {
          max_id = b.id;
        }
      }

      this.form.income.push({ id: max_id+1, name: '', amount: 0, apply: false });
    },

    deleteIncomeRowClicked(id) {
      this.form.income = this.form.income.filter(item => item.id !== id);
    },


    calcBudgetSums() {
      let sum = 0;
      for (const b of this.form.budget) {
        if (!b.apply) {
          continue;
        }

        sum += parseInt(b.amount+'');
      }

      let sum_income = 0;
      let sum_application = 0;

      for (const b of this.form.income) {
        if (b.apply) {
          sum_application += parseInt(b.amount+'');
        }

        sum_income += parseInt(b.amount+'');
      }

      this.form.sum = sum;
      this.form.sum_income = sum_income;
      this.form.sum_application = sum_application;

      this.diff_sum = sum_income - sum;
    },

    saveGrant() {

      this.calcBudgetSums();

      if (this.form.sum_application > this.amount_left_in_application) {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Ni kan inte ansöka denna summa. Måste vara mindre eller lika med:' + ' ' + this.amount_left_in_application);
        return;
      }

      this.form.num_participants = parseInt(this.form.num_participants+'');

      if (isNaN(this.form.num_participants)) {
        this.form.num_participants = 0;
      }

      if (!this.form.todo_id) {

        this.form.company_id = this.sid;
        this.form.creator_company_id = this.currentCompanyId;
        this.form.type = 'GRANT';
        this.form.status = 'DRAFT';

        axios
          .post(`/todo`, this.form)
          .then(res => {
            if (res.status === 201) {
              this.form = res.data;
              this.$emit('onGrantCreated', res.data);
              this.toastr('success', this.$t('COMMON.OK'), this.$t('GRANT.CREATED_APPLICATION'));
            }
            else {
              this.toastr('danger', 'Ansökansfel', this.$t('GRANT.UNABLE_CREATE_APPLICATION'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('GRANT.UNABLE_CREATE_APPLICATION'));
          });

      }
      else {
        axios
          .put(`/todo/${this.form.todo_id}`, this.form)
          .then(res => {
            if (res.status === 204 || res.status === 200) {
              this.$emit('onGrantUpdated', this.form);
              this.toastr('success', this.$t('COMMON.OK'), this.$t('GRANT.UPDATED_APPLICATION'));
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('GRANT.UNABLE_UPDATE_APPLICATION'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('GRANT.UNABLE_UPDATE_APPLICATION'))
          });
      }
    },

    async loadCompany() { // load a grant from company
      try {
        const res = await axios.get(`/company/settings/grant/${this.currentCompanyId}/${dayjs().format('YYYY-MM-DD')}`);
        if (res.status === 200) {
          this.grantSettings = res.data;
          this.total_sum = this.grantSettings.amount;
          this.previously_granted_sum = this.grantSettings.total_used;
          this.amount_left_for_period = this.total_sum - this.previously_granted_sum;
        }
        else {
          console.error(res);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('GRANT.UNABLE_GET_COMPANY'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('GRANT.UNABLE_GET_COMPANY'));
      }
    },

    scrollToClassName(classname) {

      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });
    },

  }
};
</script>
