<template>
  <div class="d-flex flex-column align-items-center">

    <div v-if="!local_order">
      
      <div class="d-flex justify-content-center mb-8 mt-8">
        
        <b-spinner label="Loading..."></b-spinner>
      
      </div>
      <p>{{ $t('PUBLIC.PAYMENT.LOADING_SHOP_ORDER') }}</p>
    </div>
    <div v-else>
      <b-card v-if="payment_complete">
        <div class="row">
          <div class="col-12 text-center">
            <i class="fa fa-check-circle" style="font-size: 42px; color: #00b315;"></i>
          </div>
        </div>
        <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
          <div class="col-12 text-center">
            <h4 class="event-name" style="text-align: center;">{{ $t('PUBLIC.PAYMENT.PAYMENT_COMPLETE') }}</h4>
            <br />
            <p>
              {{ $t('PUBLIC.PAYMENT.CLOSE_THIS_PAGE') }}
            </p>
          </div>
        </div>

      </b-card>
      
      <b-card v-else style="min-width: 420px;">

        <h4 class="text-center mb-8">{{ payment_status_header }}</h4>

        <div v-if="local_order.status === 'FAILED'" class="col-12 text-center mb-16">
          <i class="fa fa-minus-circle" style="font-size: 64px; color: #fe0000;"></i>
        </div>

        <div style="max-width: 360px;" v-if="!is_donation && local_order && local_order.shop_order_items">

          <template v-for="(item, i) in local_order.shop_order_items" >
            <div class="item-row">
              <div class="left-item" v-if="item.shop_item"><span>{{ item.shop_item.name }}</span></div>
              <div class="right-item" v-if="item.shop_item">{{ formatted_amount(item.shop_item.amount * item.quantity) }}</div>
            </div>
          </template>

        </div>


        <div class="payment-amount" v-if="local_order">

          <div class="amount">
            {{ formatted_amount(local_order.amount) }}
          </div>

          <div class="info mt-4">
            {{ $t('PUBLIC.PAYMENT.TOTAL_INC_VAT') }}
          </div>

          <!--<p class="text-center mt-8"><a @click.prevent="show_payment_recipient" href="">{{ $t('PUBLIC.PAYMENT.CHANGE_RECIPIENT') }}</a></p>-->

        </div>

        <b-form class="payment-recipient-form" v-if="edit_payment_recipient">
          <b-form-group :label="$t('MEMBER.FIRSTNAME')">
            <b-form-input />
          </b-form-group>

          <b-form-group :label="$t('MEMBER.LASTNAME')">
            <b-form-input />
          </b-form-group>

          <b-form-group :label="$t('MEMBER.CNAME')">
            <b-form-input />
          </b-form-group>

          <b-form-group :label="$t('COMPANY.VAT')">
            <b-form-input />
          </b-form-group>

          <b-form-group :label="$t('MEMBER.ADDRESS')">
            <b-form-input />
          </b-form-group>

          <b-form-group :label="$t('MEMBER.ZIPCODE')">
            <b-form-input />
          </b-form-group>

          <b-form-group :label="$t('MEMBER.POST')">
            <b-form-input />
          </b-form-group>

          <b-form-group :label="$t('MEMBER.EMAIL')">
            <b-form-input />
          </b-form-group>

          <b-button variant="primary" block :disabled="spinning" @click.prevent="save_recipient_clicked">
            <b-spinner v-if="spinning" small type="grow"></b-spinner>
            {{ $t('COMMON.SAVE') }}
          </b-button>

        </b-form>
        </b-card>
    </div>


  </div>
</template>

<script>
import axios from 'axios';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import messageParent from '@/core/services/messageParent';
import { isPlugin } from "@/core/services/messageParent";

export default {
  name: 'OrderSummaryComponent',
  props: ['order','is_donation'],
  mixins: [ toasts ],
  components: {
    
  },
  computed: {

    payment_complete() {
      return this.order && this.order.status === 'PAID_IN_FULL';
    },

    payment_status_header() {
      if (!this.order) {
        return '...';
      }

      switch (this.order.status) {
        case 'NONE':
        case 'CREATED':
        case 'WAIT_PAYMENT':
          return this.$t('PUBLIC.PAYMENT.WAITING_FOR_PAYMENT');
        case 'DONE':
        case 'PAID_IN_FULL':
          return this.$t('PUBLIC.PAYMENT.PAYMENT_COMPLETE');
        default:
          return this.$t('PUBLIC.PAYMENT.PAYMENT_ERROR');
      }
    },

    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },

  },
  data() {
    return {

      interval_id: null,

      local_order: null,

      local_recipient: null,

      edit_payment_recipient: false,

      spinning: false

    };
  },
  async mounted() {
    
    if (this.order) {
      this.local_order = { ...this.order }

      if (this.local_order.receipt_recipient) {
        this.local_recipient = { ...this.local_order.receipt_recipient }
      }
      else if (this.local_order.member) {
        this.local_recipient = { ...this.local_order.member }
      }
      else if (this.local_order.person) {
        this.local_recipient = { ...this.local_order.person }
      }
    }

    this.interval_id = setInterval(this.check_done, 1000);
  },
  watch: {
    order: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_order = { ...this.order };
      }
    },
  },
  destroyed() {
    if (this.interval_id) {
      clearInterval(this.interval_id);
    }
  },
  methods: {

    check_done() {
      
      if (this.payment_complete) {

        clearInterval(this.interval_id);

        let url = this.local_order.is_renew
                ? this.local_order.company.company_settings.post_renew_url
                : this.local_order.company.company_settings.post_register_url;

        if (!url.match(/^https?:\/\//)) {
          url = 'http://' + url;
        }

        messageParent(JSON.stringify({ redirect: url }));

        if (!isPlugin()) {
          window.location.href = url;
        }
      }
    },

    formatted_amount(amount) {
      if (!amount || !this.local_order) {
        return '...';
      }

      if (this.local_order.currency !== 'SEK') {
        return amount + ' ' + this.local_order.currency;
      }

      const int_amount = parseInt(amount+'');

      if (isNaN(int_amount)) {
        return 'Okänt';
      }

      if (int_amount >= 1000) {
        return Math.floor(int_amount / 1000) + ' ' + this.pad_left((int_amount % 1000)+'', 3) + ' kr';
      }

      return int_amount + ' kr';
    },

    async save_recipient_clicked() {
      this.spinning = true;

      try {
        const res = await axios.put(`/shop_order/token/${this.order.token}/${this.order.shop_order_id}`);

        if (res.status === 200) {

        }
      }
      catch (err) {
        console.error('save_recipient_clicked error', err);
      }

      setTimeout(()=>{
        this.spinning = false;
      },1000);
    },

    show_payment_recipient() {
      this.edit_payment_recipient = true;
    },

    pad_left(str, len) {
      let s = str;

      for (let i = 0; s.length < len; ++i) {
        s = '0' + s;
      }

      return s;
    },

  }
};
</script>


<style lang="css" scoped>

.item-row {
  border-bottom: 1px dashed #808085;
  float: left;
  height: 25px;
  max-width: 400px;
  margin-bottom: 18px;
  color: #535356
}

.item-row::after {
  clear: both;
}

.left-item span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.left-item {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 300px;
  max-width: 300px;
  float: left;
}

.right-item {
  float: left;
  min-width: 52px;
  text-align: right;
}

.amount {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;

  text-align: center;
}

.info {
  color: #1a1a1d;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

:deep .form-group {
  margin-bottom: 8px;
}

:deep .col-form-label {
  padding-bottom: 2px;
}

.payment-amount {
  float: left;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 340px;
}

.payment-recipient-form {
  float: left;
  margin-left: auto;
  margin-right: auto;
  min-width: 360px;
}

</style>
