<template>
  <b-card id="page-onboarding">
    
    <h5 class="m-4">{{ $t('ONBOARDING.HEADER') }}</h5>

    <OnboardingGroupModal 
      ref="onboarding-group-modal"
      :item="selected_onboarding_group"
      @create="create_onboarding_group"
      @update="update_onboarding_group"
    />

    <OnboardingListModal 
      ref="onboarding-list-modal"
      @select="onboarding_selected"
    />

    <div v-if="!onboarding || !onboarding.id">
      <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
          @click.prevent="create_onboarding_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('ONBOARDING.CREATE_ONBOARDING')}}</a
        >      
      </div>
    </div>

    <div v-else>
      <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
          @click.prevent="create_group_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('ONBOARDING.CREATE_GROUP')}}</a
        >
      </div>

      <template v-for="group in onboarding.groups">
        <OnboardingStepGroup :item="group" @create_step_clicked="create_step_clicked"/>
      </template>

    </div>

    
  </b-card>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import OnboardingStepGroup from '@/view/pages/ml/onboarding/OnboardingStepGroup.vue';
import OnboardingGroupModal from '@/view/pages/ml/onboarding/OnboardingGroupModal.vue';
import OnboardingListModal from '@/view/pages/ml/onboarding/OnboardingListModal.vue';


export default {
  name: 'OnboardingPage',
  mixins: [ toasts ],
  components: {
    OnboardingStepGroup,
    OnboardingGroupModal,
    OnboardingListModal
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    this.get_onboardings();
  },
  data() {
    return {
      
      onboarding: null,
      onboardings: [],
      selected_onboarding_group: null,
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      
    },
    currentPeriodId(newValue, oldValue) {
      
    }
  },
  methods: {

    onboarding_selected(onboarding) {},

    async create_onboarding_clicked() {
      this.$refs['onboarding-list-modal'].show();
    },

    /// List all the available onboardings
    async get_onboardings() {
      try {
        const res = await axios.get(`/onboarding`);

        if (res.status === 200) {
          this.onboardings = res.data;

          if (this.onboardings.length > 0) {
            /// show the onboarding for this customer
            this.onboarding = this.onboardings[0];
          }
        }
        
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ONBOARDING.UNABLE_LOAD'));
      }
    },

    async create_step_clicked(parent_step_id) {

      this.create_onboarding_step(this.onboarding.id, {
        name: this.$t('ONBOARDING.NEW_STEP'),
        type: 'STEP',
        parent_step_id,
        description: ''
      });
    },

    async update_onboarding_group(group) {
      await this.put_onboarding_step(group);
    },

    async create_onboarding_group() {
      this.create_onboarding_step(this.onboarding.id, {
        name: this.$t('ONBOARDING.NEW_GROUP'),
        type: 'GROUP',
        description: ''
      });
    },

    async create_group_clicked() {

      this.selected_onboarding_group = {};

      this.$nextTick(()=>{
        this.$refs['onboarding-group-modal'].show();
      });

    },

    async create_onboarding_step(onboarding_id, item) {
      try {
        const res = await axios.post(`/onboarding/step/${onboarding_id}`, item)
        
        if (res.status === 201) {
          
          this.toastr('success', this.$t('COMMON.OK'), this.$t('ONBOARDING.CREATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ONBOARDING.UNABLE_CREATE'));
      }
    },

    async put_onboarding_step(item) {
      try {
        const res = await axios.put(`/onboarding/step/${item.id}`, item)
        
        if (res.status === 204) {
          
          this.toastr('success', this.$t('COMMON.OK'), this.$t('ONBOARDING.UPDATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ONBOARDING.UNABLE_UPDATED'));
      }
    },
    
  }
};
</script>
