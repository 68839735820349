
export function get_sales_filters() {
  const filters_str = localStorage.getItem('sales.filters');

  if (!filters_str) {
    const filters = {
      user_id: null,
      statuses: ['PROSPECT','NOT_ANSWER','OPPORTUNITY','CLOSING','SIGNED','LIVE'],
      types: ['IDEELL']
    }

    localStorage.setItem('sales.filters', JSON.stringify(filters));

    return filters;
  }

  const filters = JSON.parse(filters_str);

  return filters;
}

export function set_sales_filters(filters) {
  if (!filters) {
    return false;
  }

  localStorage.setItem('sales.filters', JSON.stringify(filters));

  return true;
}
