<template>
  <b-modal ref="book-editor-modal" size="lg" hide-footer>

    <b-card
      title=""
      class="mb-2 edit-live-book-container"
      ref="edit-live-book"
      id="edit-live-book"
      hide-footer
    >
      <div>
        <b-form class="mt-8" @submit="submit_book">
          <b-form-group id="input-group-book_id" label="ID" label-for="input-book_id">
            <b-form-input
              id="input-book_id"
              v-model="local_item.book_id"
              type="text"
              required
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="local_item.status === BOOK_STATUS.IN_FORTNOX"
            id="input-group-voucher_id"
            :label="$t('BOOK.VOUCHER_NUMBER')"
            label-for="input-voucher_id"
          >
            <b-form-input
              id="input-voucher_id"
              :value="local_item.external_id"
              type="text"
              required
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-0" :label="$t('BOOK.MESSAGE')" label-for="input-0">
            <b-form-input
              id="input-0"
              v-model="local_item.message"
              :disabled="local_item.status && local_item.status !== BOOK_STATUS.DO_NOT_SYNC_FORTNOX"
              :state="validMessage"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-row>
            <b-col sm="12" md="12">

              <div v-for="(input, index) in local_item.rows" :key="`rows-${index}`">

                <h4>Rad #{{ index+1 }}</h4>
                <b-row class="mt-4">
                  <b-col sm="12" md="4">
                    <b-form-group
                      :id="`row-${index}-input-group-1`"
                      :label="$t('BOOK.ACCOUNT')"
                      :label-for="`row-${index}-input-1`"
                    >
                      <b-form-input
                        :id="`row-${index}-input-1`"
                        :disabled="local_item.status && local_item.status !== BOOK_STATUS.DO_NOT_SYNC_FORTNOX"
                        v-model="input.account"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <b-form-group
                      :id="`row-${index}-input-group-2`"
                      :label="$t('BOOK.COST_CENTER')"
                      :label-for="`row-${index}-input-2`"
                    >
                      <b-form-input
                        :id="`row-${index}-input-2`"
                        :disabled="local_item.status && local_item.status !== BOOK_STATUS.DO_NOT_SYNC_FORTNOX"
                        v-model="input.ks"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <b-form-group
                      :id="`row-${index}-input-group-3`"
                      :label="$t('BOOK.PROJECT')"
                      :label-for="`row-${index}-input-3`"
                    >
                      <b-form-input
                        :id="`row-${index}-input-3`"
                        :disabled="local_item.status && local_item.status !== BOOK_STATUS.DO_NOT_SYNC_FORTNOX"
                        v-model="input.pn"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="6">
                    <b-form-group
                      :id="`row-${index}-input-group-4`"
                      :label="$t('BOOK.DEBIT')"
                      :label-for="`row-${index}-input-4`"
                    >
                      <b-form-input
                        :id="`row-${index}-input-4`"
                        v-model="input.debit"
                        :disabled="
                          input.credit !== '0' || (local_item.status && local_item.status !== BOOK_STATUS.DO_NOT_SYNC_FORTNOX)
                        "
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="6">
                    <b-form-group
                      :id="`row-${index}-input-group-5`"
                      :label="$t('BOOK.CREDIT')"
                      :label-for="`row-${index}-input-5`"
                    >
                      <b-form-input
                        :id="`row-${index}-input-5`"
                        v-model="input.credit"
                        :disabled="
                          input.debit !== '0' || (local_item.status && local_item.status !== BOOK_STATUS.DO_NOT_SYNC_FORTNOX)
                        "
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :id="`row-${index}-input-group-6`"
                      :label="$t('BOOK.DESCRIPTION')"
                      :label-for="`row-${index}-input-6`"
                    >
                      <b-form-input
                        :id="`row-${index}-input-5`"
                        v-model="input.description"
                        :disabled="
                          (local_item.status && local_item.status !== BOOK_STATUS.DO_NOT_SYNC_FORTNOX)
                        "
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="mb-4" />

              </div>
              <b-col
                v-if="!local_item.status || local_item.status === BOOK_STATUS.DO_NOT_SYNC_FORTNOX"
                sm="12"
                class="d-flex align-center justify-content-around my-2"
              >
                <a
                  href="#"
                  class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
                  @click="add_field(input, local_item.rows)"
                  ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('BOOK.ADD_ROW')}}</a
                >
                <a
                  href="#"
                  :style="{ visibility: local_item.rows?.length > 2 ? 'visible' : 'hidden' }"
                  class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
                  @click="remove_field(index, local_item.rows)"
                  ><i class="menu-icon flaticon2-minus" style="font-size: 1.0em;"></i>{{$t('BOOK.REMOVE_ROW')}}</a
                >
              </b-col>
            </b-col>
          </b-row>

          <b-button
            v-if="local_item.status === 'ERROR'"
            type="button"
            @click="try_sync_again"
            variant="primary"
            class="mt-4"
            >{{ $t('BOOK.TRY_SYNC_AGAIN') }}</b-button
          >

          <b-button
            v-if="!local_item.status || local_item.status === BOOK_STATUS.DO_NOT_SYNC_FORTNOX"
            type="submit"
            variant="primary"
            class="mt-4"
            >{{ $t('COMMON.SAVE') }}</b-button
          >
        </b-form>
      </div>
    </b-card>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.card-body {
  padding: 2px;
}

.page-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

const BOOK_STATUS = {
  DO_NOT_SYNC_FORTNOX: 'DO_NOT_SYNC_FORTNOX',
  TO_BE_SYNCED_FORTNOX: 'TO_BE_SYNCED_FORTNOX',
  PENDING_FORNOX: 'PENDING_FORNOX',
  IN_FORTNOX: 'IN_FORTNOX'
};

export default {
  name: 'BookEditorModal',
  components: {

  },
  props: ['item'],
  emits: ['created', 'updated'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'sid']),
    validMessage() {
      if (!this.local_item.message) { return false; }

      if (this.local_item.message.length > 0) { return true; }

      return false;
    }
  },
  mixins: [ toasts ],
  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }
  },
  data() {
    return {
      local_item: {},
      BOOK_STATUS: BOOK_STATUS
    };
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.local_item = { ...val };
      }
    }
  },
  methods: {

    async try_sync_again() {
      const res = await axios.put(`/book/${this.local_item.book_id}`, { status: 'TO_BE_SYNCED_FORTNOX' });

      if (res.status === 200) {
        this.local_item.status = 'TO_BE_SYNCED_FORTNOX';
        this.$emit('updated', this.local_item);
      }
    },

    show() {
      this.$refs['book-editor-modal'].show();
    },

    // todo: explain
    add_field(value, fieldType) {
      fieldType.push({ account: null, debit: '0', credit: '0', ks: '', pn: '' });
    },
    // todo: explain
    remove_field(index, fieldType) {
      fieldType.splice(index, 1);
    },

    submit_book(e) {
      e.preventDefault();

      this.local_item.debits = this.local_item.rows
        .filter(row => row.debit !== '0')
        .map(row => ({
          ...row,
          amount: row.debit
        }));

      this.local_item.credits = this.local_item.rows
        .filter(row => row.credit !== '0')
        .map(row => ({
          ...row,
          amount: row.credit
        }));

      this.save_book();
    },

    save_book() {
      if (this.local_item.book_id === null || this.local_item.book_id === '' || this.local_item.book_id === undefined) {
        axios
          .post('/book', this.local_item)
          .then(res => {
            if (res.status === 201) {
              this.$emit('created', res.data);

              this.local_item.book_id = res.data;

              this.toastr('success', this.$t('COMMON.OK'), this.$t('BOOK.VOUCHER_CREATED'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('BOOK.UNABLE_CREATE_VOUCHER'));
          });
      } else {
        axios
          .put(`/book/${this.local_item.book_id}`, this.local_item)
          .then(res => {
            if (res.status === 200) {

              this.$emit('updated', this.local_item);
              this.toastr('success', this.$t('COMMON.OK'), this.$t('BOOK.VOUCHER_UPDATED'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('BOOK.UNABLE_UPDATE_VOUCHER'));
          });
      }
    },

    reset_form() {
      this.local_item = {
        id: '',
        message: '',
        rows: [
          { account: null, debit: '0', credit: '0', ks: '', pn: '' },
          { account: null, debit: '0', credit: '0', ks: '', pn: '' }
        ]
      };
    },


  }
};
</script>
