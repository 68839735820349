<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">

    <!-- begin: search bar -->
    <div class="topbar-item mr-8">
      <QuickSearchWidget />
    </div>
    <!-- end: search bar -->

    <!--begin: Language bar -->
    <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img class="h-20px w-20px rounded-sm" :src="languageFlag || getLanguageFlag" alt="" />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage v-on:language-changed="onLanguageChanged"></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <b-dropdown v-if="user" variant="link" toggle-class="btn btn-clean btn-user-nav" no-caret right no-flip>
      <template v-slot:button-content>
        <div class="user-info">
          <span class="user-nav-text">{{ user.email || ((user.firstname || '') + ' ' + (user.lastname || '')) }}</span>
          <div class="session-time-left"> {{ session_expires_in || '...' }}</div>
        </div>
      </template>
      <b-dropdown-text tag="div" class="nav-user-dropdown p-8">
        
        <div class=""><b-link @click="onMyProfile">{{$t('ACCOUNT.MY_PROFILE')}}</b-link></div>
        <hr class="w-100" />
        
        <div class=""><b-link @click="onLogOut">{{$t('ACCOUNT.LOGOUT')}}</b-link></div>
      </b-dropdown-text>
    </b-dropdown>

    <b-modal size="lg" ref="notification-popup"  no-close-on-backdrop hide-footer >

    <div class="d-flex flex-column">
      <div class="w-100 h-100 align-items-center text-center flex-wrap justify-content-center">
        <i class="fa fa-exclamation-triangle" style="font-size: 42px; color: #eca350;"></i>
      </div>

      <hr />

      <div>

        <div>
          <div class="h-100 align-items-center text-center flex-wrap justify-content-center">
            <h4 class="header-name mt-8" style="text-align: center; min-width: 512px;">{{ $t('USER.NOT_LOGGED_IN') }}</h4>
            <br />
            <p class="mt-8">{{ $t('USER.YOU_ARE_NOT_LOGGED_IN') }}</p>
            
            <p class="mt-8"><b-button variant="success" @click.prevent="clicked_goto_login()">{{$t('USER.GOTO_LOGIN')}}</b-button></p>
          </div>
        </div>

      </div>

    </div>
    </b-modal>
    
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>


<script>
import KTDropdownLanguage from '@/view/layout/extras/dropdown/DropdownLanguage.vue';
import i18nService from '@/core/services/i18n.service.js';
import store from '@/core/services/store';
import QuickSearchWidget from '@/view/components/QuickSearchWidget.vue';
import { PURGE_AUTH } from '@/core/services/store/auth.module';
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/core/services/store/auth.module';
import { parseJwt } from '@/core/services/jwt.service';
import dayjs from 'dayjs';


function pad(num) {
  if (parseInt(num) < 10) {
    return '0'+num;
  }

  return num;
}

export default {
  name: 'KTTopbar',
  data() {
    return {
      timer: null,
      currentTime: null,
      languageFlag: '',
      languages: i18nService.languages
    };
  },
  props: {
    
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user_refresh_token', 'user']),

    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    
    session_expires_in() {
      const parsed = parseJwt(this.user_refresh_token);
      const expiration = dayjs.unix(parsed.exp);
      
      // Calculate the difference in milliseconds
      const diff = expiration.diff(this.currentTime);

      // Extract days, hours, minutes, and seconds
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (isNaN(days)) {
        return this.$t('COMMON.LOADING');
      }

      if (diff < 0) {
        setTimeout(()=>{

          this.show_popup(diff < 0);
        }, 2000);

        return this.$t('COMMON.LOADING');
      }

      return this.$t('COMMON.SESSION_EXPIRES_IN', { days, hours: pad(hours), minutes: pad(minutes), seconds: pad(seconds) });
    },
  },
  components: {
    KTDropdownLanguage,
    QuickSearchWidget,
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentTime = dayjs();
    }, 200);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.timer);
  },
  methods: {
    
    clicked_goto_login() {
      window.location.reload();
    },

    show_popup(show) {
      if (!show) { return; }
      
      this.$refs['notification-popup'].show();
    },

    onMyProfile() {
      // we dont care about this error message
      this.$router.push({ name: 'ml-profile' }).catch(()=>{});
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onLogOut() {
      console.log('>> User initiated auth purge');
      const company_id = store.getters['sid'];

      this.$store.dispatch(LOGOUT);
      this.$store.commit(PURGE_AUTH);

      console.error('Topbar.vue: onLogOut', company_id);

      this.$router.push('ml-login')
    }
  },
  
};
</script>


<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
  .btn-user-nav {
    padding: 0px 10px;
    // border-radius: 50px;
    .user-nav-text {
      font-size: 1.1rem;
      color: #6c7293;
    }
    .user-nav-image {
      align-self: center;
      max-height: 38px;
      max-width: 38px;
      border-radius: 50%;
    }
  }
  .nav-user-dropdown {
    width: 250px;
  }
}
</style>
