import { render, staticRenderFns } from "./MemlistSegmentedSelectButton.vue?vue&type=template&id=bc8efe36&scoped=true"
import script from "./MemlistSegmentedSelectButton.vue?vue&type=script&lang=js"
export * from "./MemlistSegmentedSelectButton.vue?vue&type=script&lang=js"
import style0 from "./MemlistSegmentedSelectButton.vue?vue&type=style&index=0&id=bc8efe36&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc8efe36",
  null
  
)

export default component.exports