<template>
  <b-row align-h="center">

    <b-col xl="6" lg="8" md="12" sm="12">
      <div class="mt-20 shadow-lg">
        <div
          class="d-flex flex-column align-items-center py-12 bg-linear-gradient-primary text-white"
        >
          <div class="w-25 d-flex flex-column align-items-center">
            <div class="w-75">
              <img class="w-100" alt="credit card" src="/assets/images/creditcard.png" />
            </div>
          </div>
          <h3 class="text-center mt-12 mb-0">{{ $t('PUBLIC.PAYMENT.REVIEW') }}</h3>
        </div>
        <b-card-body class="py-16">
          
          <div class="bg-secondary my-12">
            <div class="d-flex justify-content-between px-6 pt-6 pb-2">
              <h4>{{ $t('PUBLIC.PAYMENT.ITEM') }}</h4>
              <h4>{{ $t('PUBLIC.PAYMENT.PRICE') }}</h4>
            </div>
            <div
              v-for="(product, index) in products"
              :key="index"
              class="d-flex justify-content-between px-6"
            >
              <span
                >{{ product.quantity > 1 ? `${product.quantity}x&nbsp;&nbsp;` : '' }}{{ product.shop_item.name }}</span
              >
              <span>{{ formatCurrency(product.amount) }}</span>
            </div>

            <div v-if="order.campaign">
              <span
                >{{ $t('PUBLIC.PAYMENT.DISCOUNT') }}</span
              >
              <span>{{ Math.round(order.campaign.percent * 100.0) }}%</span>
            </div>

            <div class="mx-6 mt-6" :style="{ borderTop: '2px solid #888' }" />
            <div class="d-flex justify-content-between p-6">
              <span>{{ $t('PUBLIC.PAYMENT.TOTAL') }}</span>
              <span>{{ formatCurrency(order.amount) }}</span>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center mt-24">
            <div class="w-25 d-flex flex-column align-items-center">
              <img
                class="w-100"
                alt="company logo"
                :src="logo"
                onerror="this.onerror=null;this.src='/assets/images/memlist-logo-svart.png'"
              />
            </div>
          </div>
        </b-card-body>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { mapGetters } from 'vuex';

let formatter = new Intl.NumberFormat('sv', {
  style: 'currency',
  currency: 'SEK',
  minimumFractionDigits: 2
});

export default {
  name: 'PaymentComplete',
  data() {
    return {
      logo: '',
      member_id: null
    };
  },
  props: ['member','products','order'],
  computed: {
    ...mapGetters(['isMemAuthenticated'])
  },
  methods: {
    getDate() {
      return dayjs().format('YYYYMMDD');
    },
    formatCurrency(value) {

      if (order.currency === 'SEK') {
        formatter = new Intl.NumberFormat('sv', {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 2
        });
      }
      else {
        formatter = new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: order.currency,
          minimumFractionDigits: 2
        });
      }

      return formatter.format(value);
    }
  },
  mounted() {
    this.company_id = this.order.company_id;

    axios
      .get(`/company/public/${this.company_id}`)
      .then(res => {
        if (res.status === 200 && res.data.logo) {
          this.logo = res.data.logo;
        } else {
          this.logo = '/assets/images/memlist-logo-svart.png';
        }
      })
      .catch(() => {
        this.logo = '/assets/images/memlist-logo-svart.png';
      });

  }
};
</script>

